import {useTranslation} from "react-i18next";
import {
    Box,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useSelector} from "#state/useSelector";
import {FundLogo} from "#pages/FundDetails/FundLogo/FundLogo";
import {InterestOutput} from "#components";
import * as selectors from '#state/selectors'
import {useCurrencyOutput} from "#components/CurrencyOutput/useCurrencyOutput";
import {COLORS} from "#app/colors/colors";
import styles from "#pages/portfolio-depositor/FundPlacements/FundPlacements.module.scss";
import {Fragment} from "react";


export function FundPortfolioRiskTable() {
    const {t} = useTranslation()
    const fundPlacements = useSelector(state => state.fundPlacements.filter(placement => placement.unitQuantity !== 0))
    return (
        <Stack direction={'column'} spacing={2}>
            <h3>
                <i className="ri-microscope-line"/>
                <span>{t('pages-FundOverview.risk')}</span>
            </h3>
        <TableContainer component={Paper}>
            <Table aria-label={"Fund portfolio risk table"}>
                <TableHead>
                    <TableRow>
                        <TableCell /> {/* Empty header cell, body cells will contain fund logo. */}
                        <TableCell>
                            <Typography fontSize={"1.4rem"} color={COLORS["SILVER_GRAY"]} fontWeight={600}>
                                {t('pages-FundPortfolioRisk.fundName')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontSize={"1.4rem"} color={COLORS["SILVER_GRAY"]} fontWeight={600}>
                                {t('pages-FundPortfolioRisk.interestRateSensitivity')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontSize={"1.4rem"} color={COLORS["SILVER_GRAY"]} fontWeight={600}>
                                {t('pages-FundPortfolioRisk.creditSensitivity')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontSize={"1.4rem"} color={COLORS["SILVER_GRAY"]} fontWeight={600}>
                                {t('pages-FundPortfolioRisk.standardDeviation3Y')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontSize={"1.4rem"} color={COLORS["SILVER_GRAY"]} fontWeight={600}>
                                {t('pages-FundPortfolioRisk.marketValue')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontSize={"1.4rem"} color={COLORS["SILVER_GRAY"]} fontWeight={600}>
                                {t('pages-FundPortfolioRisk.ownership')}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fundPlacements.map(placement =>
                        <RiskRow key={placement.id} fundPlacementId={placement.id} />
                    )}
                    <PortfolioRiskRow />
                </TableBody>
            </Table>
        </TableContainer>
        </Stack>
    )
}

type RiskRowProps = {
    fundPlacementId: string
}
function RiskRow({fundPlacementId}: RiskRowProps) {
    const Currency = useCurrencyOutput()
    const row = useSelector(state => selectors.fundPlacementsRiskRowDataSelector(state).find(row => row.fundPlacementId === fundPlacementId))

    if (!row) {
        return null
    }

    return (
        <TableRow>
            <TableCell>
                <Box sx={{'& img': {width: '100%'}}} width={'10rem'} display="flex" justifyContent="flex-end">
                    <FundLogo fundName={row.fullFundName}/>
                </Box>
            </TableCell>
            <TableCell>
                <Typography fontSize={"1.4rem"} fontWeight={500} sx={{maxWidth: '15rem', overflow: 'auto'}}>
                    {row.fullFundName != null
                        ? row.fullFundName
                        : '-'
                    }
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontSize={"1.4rem"}>
                    {row.interestRateSensitivity != null
                        ? InterestOutput.format(row.interestRateSensitivity)
                        : '-'
                    }
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontSize={"1.4rem"}>
                    {row.creditSensitivity != null
                        ? InterestOutput.format(row.creditSensitivity)
                        : '-'
                    }
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontSize={"1.4rem"}>
                    {row.standardDeviation != null
                        ? InterestOutput.format(row.standardDeviation)
                        : '-'
                    }
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontSize={"1.4rem"}>
                    {Currency(row.marketValue, {decimals: 0})}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontSize={"1.4rem"}>
                    {row.ownershipPercentage != null
                        ? InterestOutput.format(row.ownershipPercentage)
                        : '-'
                    }
                </Typography>
            </TableCell>
        </TableRow>
    )
}

function PortfolioRiskRow() {
    const {t} = useTranslation()
    const Currency = useCurrencyOutput()
    const portfolioRisk = useSelector(selectors.fundPortfolioRiskDataSelector)

    if (!portfolioRisk) {
        return null
    }

    return (
        <TableRow sx={{
            '& td': {
                borderTop: `2px solid ${COLORS["SILVER_GRAY"]}`
            }
        }}>
            <TableCell/>
            <TableCell>
                <Typography fontWeight={600}>
                    {t('pages-FundPortfolioRisk.portfolio')}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={600}>
                    {portfolioRisk.interestRateSensitivity != null
                        ? InterestOutput.format(portfolioRisk.interestRateSensitivity)
                        : '-'
                    }
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={600}>
                    {portfolioRisk.creditSensitivity != null
                        ? InterestOutput.format(portfolioRisk.creditSensitivity)
                        : '-'
                    }
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={600}>
                    {portfolioRisk.standardDeviation != null
                        ? InterestOutput.format(portfolioRisk.standardDeviation)
                        : '-'
                    }
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontWeight={600}>
                    {Currency(portfolioRisk.totalValue, {decimals: 0})}
                </Typography>
            </TableCell>
            <TableCell/>
        </TableRow>
    )
}
