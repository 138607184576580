import { PURPLE, SILVER_GRAY } from '#app/colors/colors'
import {InterestOutput, LoadingSpinner, NumberInput} from '#app/components'
import AsyncButton from '#app/components/Button/AsyncButton'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { useCommand } from '#app/services/beta'
import { useSelector } from '#app/state/useSelector'
import useTableSort, {Column} from '#services/useTableSort'
import { Autocomplete, Badge, Box, Button, Checkbox, Chip, Divider, FormControlLabel, IconButton, InputAdornment, InputLabel, Link, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PotentialInterestApi from "#app/pages/Onboarding/InterestCalculator/api/PotentialInterestApi"
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import FxDialogActions from '#app/components/FxDialog/FxDialogActions'
import CopyPasteMessage from './CopyPasteMessage'
import {AccountantCustomerCompanyDto, PartnerUserDto} from '@fixrate/fixrate-query'
import { Clear } from '@mui/icons-material'
import { sanitizeString } from '../CustomerOverview'
import { KeyFigureCard } from '#app/components/KeyFigureCard/KeyFigureCard'
import useAccountantClients from '#app/services/useAccountantClients'

type Row = AccountantCustomerCompanyDto & {
    currentVolume: number,
}

const prospectMessageText = (oldInterest: string, newInterest: string, potentialgain: string, partnerUser: PartnerUserDto) => (
`Hei,

Det står i dag et større beløp på en av dine kontoer i dag med en rente på ${oldInterest}.

Dette er lavere enn det som er tilgjengelig i markedet, hvor en plasseringskonto kan gi deg en rente på ${newInterest}.

Ved å flytte midlene vil du tjene cirka ${potentialgain} mer i renteinntekter i året.

Du beholder fortsatt hovedbankavtalen din og trenger ikke å flytte over andre banktjenester.

Om du ønsker, sender jeg deg gjerne mer informasjon på epost eller over telefon.

Med vennlig hilsen,
${partnerUser.firstName} ${partnerUser.lastName}
`
)

export default function AccountantProspects() {
    const {t} = useTranslation()
    const Currency = useCurrencyOutput()
    const sessionId = useSelector(state => state.session.id)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [calculatorOpen, setCalculatorOpen] = useState(false)
    const [calculatorVolume, setCalculatorVolume] = useState(0)
    const [calculatorInterest, setCalculatorInterest] = useState(0.00)
    const [calculatorPotential, setCalculatorPotential] = useState(0)
    const [marketInterestRate, setMarketInterestRate] = useState(0)
    const [calculatorStep, setCalculatorStep] = useState(1)
    const [selectedProspectGroup, setSelectedProspectGroup] = useState<'all' | 'over-1-mill'>('over-1-mill')
    const [selectedRegions, setSelectedRegions] = useState<string[]>([])
    const [customerSearchQuery, setCustomerSearchQuery] = useState('')
    const { sendPartnerInvite } = useCommand()
    const partner = useSelector(state => state.partner)
    const currentPartnerUser = partner?.users?.find(u => u.id === sessionId)
    const {clients, isLoading} = useAccountantClients()
    const prospects = clients ? clients?.map(customer => ({
        ...customer,
        currentVolume: customer.annualAccounts.sort((a, b) => b.accountingYear - a.accountingYear)[0].balanceCashAndDeposits
    })) : []

    const prospectsOver1Mill = prospects.filter(p => p.currentVolume >= 1e6)
    const selectedProspects = selectedProspectGroup === 'over-1-mill' ? prospectsOver1Mill : prospects
    const clientRegions = [...new Set(selectedProspects
        .map(p => p.municipality || p.postPlace)
        .filter(region => !!region))
    ].sort()
    const filteredProspects = selectedProspects.filter(customer => !customerSearchQuery || sanitizeString(`${customer.name}${customer.nationalIdentity}`).includes(sanitizeString(customerSearchQuery)))
        ?.filter(p => selectedRegions.length === 0 || selectedRegions.includes(p.municipality) || selectedRegions.includes(p.postPlace))
        ?? []

    const {sortedRows, sortDirection, sortKey, setSorting} = useTableSort<Row>(filteredProspects, 'currentVolume', 'desc')

    const columns: Column<Row>[] = [
        {
            id: 'name',
            label: t('pages-customers.customer'),
        },
        {
            id: 'currentVolume',
            label: t('pages-customers.cashInBank'),
        }
    ]

    const Pagination = (
        <TablePagination
            labelRowsPerPage={t('pages-customers.customersPerPage')}
            labelDisplayedRows={({from, to, count}) => t('pages-customers.customersDisplayed', {from, to, count})}
            rowsPerPageOptions={[50, 100, 250, 500]}
            component="div"
            count={filteredProspects.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => {
                setRowsPerPage(+e.target.value)
                setPage(0)
            }}
        />
    )

    const registerCustomer = async (nationalIdentity: string, nationality: string) => {
        const { waitForCommand } = await sendPartnerInvite(partner.id, nationalIdentity, nationality, true)
        const success = await waitForCommand()
        if (success) {
            console.log('Successfully created customer')
        }
    }

    const calculateInterestPotential = async (volume: number, currentInterest: number) => {
        const potentialMonthlyInterest = await PotentialInterestApi.getCalculatedInterest(volume / 1e6);
        const currentInterestEarnings = (volume * currentInterest) / 100;
        setMarketInterestRate((potentialMonthlyInterest * 12) / volume * 100)
        setCalculatorPotential(potentialMonthlyInterest * 12 - currentInterestEarnings)
        setCalculatorStep(2)
    }

    const openInterestCalculator = (prospect: Row) => {
        setCalculatorOpen(true)
        setCalculatorVolume(prospect.currentVolume)
        setCalculatorInterest(0)
        setCalculatorPotential(0)
        setCalculatorStep(1)
    }

    const totalVolume = filteredProspects.reduce((acc, customer) => acc + customer.currentVolume, 0)
    const numberOfregistered = filteredProspects.filter(fp => partner.customers.find(c => c.nationalIdentity === fp.nationalIdentity)).length
    const percentageRegistered = (numberOfregistered / filteredProspects.length) * 100

    if (isLoading) {
        return (
            <LoadingSpinner />
        )
    }

    function changeProspectGroup(group: 'all' | 'over-1-mill') {
        setPage(0)
        setSelectedProspectGroup(group)
    }

    return (
        <>
            <Stack spacing={3}>
                <Stack direction="row" justifyContent={"space-between"}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        selectedProspectGroup === "over-1-mill"
                                    }
                                    onChange={(e, val) =>
                                        changeProspectGroup(
                                            val ? "over-1-mill" : "all"
                                        )
                                    }
                                />
                            }
                            label={t("pages-customers.customersOver1Mill")}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Autocomplete
                            multiple
                            value={selectedRegions}
                            onChange={(e, val) => setSelectedRegions(val)}
                            options={clientRegions ?? []}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        color='primary'
                                        size='small'
                                        label={option}
                                        {...getTagProps({ index })}
                                        key={option}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    label={t("pages-customers.filterByRegion")}
                                    {...params}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            )}
                        />
                        <TextField
                            size="small"
                            placeholder={t("pages-customers.customerSearch")}
                            value={customerSearchQuery}
                            onChange={(event) =>
                                setCustomerSearchQuery(event.target.value)
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        sx={{
                                            opacity:
                                                customerSearchQuery.length === 0
                                                    ? 0
                                                    : 1,
                                            transformOrigin: "center",
                                            transform:
                                                customerSearchQuery.length === 0
                                                    ? "scale(0)"
                                                    : "scale(1)",
                                            transition: "0.2s ease-in-out",
                                        }}
                                    >
                                        <IconButton
                                            size={"small"}
                                            onClick={() =>
                                                setCustomerSearchQuery("")
                                            }
                                        >
                                            <Clear fontSize={"small"} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction={{ lg: "row" }} gap={2} spacing={2}>
                        <KeyFigureCard
                            direction="row"
                            value={Currency(totalVolume, { decimals: 0 })}
                            label={t("pages-customers.totalPotentialVolume")}
                            remixIcon="ri-coin-line"
                        />
                        <KeyFigureCard
                            direction="row"
                            value={filteredProspects.length.toString()}
                            label={t("pages-customers.numberOfCustomers")}
                            remixIcon="ri-building-line"
                        />
                        <KeyFigureCard
                            direction="row"
                            value={InterestOutput.format(
                                percentageRegistered ? percentageRegistered : 0
                            )}
                            label={t(
                                "pages-customers.shareRegisteredOnFixrate"
                            )}
                            remixIcon="ri-checkbox-circle-line"
                        />
                    </Stack>
                </Stack>
                {filteredProspects.length > 0 ? (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                sortDirection={
                                                    sortKey === column.id
                                                        ? sortDirection
                                                        : false
                                                }
                                            >
                                                <TableSortLabel
                                                    active={
                                                        sortKey === column.id
                                                    }
                                                    direction={
                                                        sortKey === column.id
                                                            ? sortDirection ===
                                                              "asc"
                                                                ? "asc"
                                                                : "desc"
                                                            : "asc"
                                                    }
                                                    onClick={() =>
                                                        setSorting(column.id)
                                                    }
                                                >
                                                    {column.label}
                                                    {sortKey === column.id ? (
                                                        <Box
                                                            component="span"
                                                            sx={visuallyHidden}
                                                        >
                                                            {sortDirection ===
                                                            "desc"
                                                                ? "sorted descending"
                                                                : "sorted ascending"}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedRows
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((prospect, index) => (
                                            <TableRow
                                                data-cy="prospectTableRow"
                                                key={prospect.nationalIdentity}
                                            >
                                                <TableCell
                                                    sx={{
                                                        fontWeight: "600",
                                                        width: "40rem",
                                                    }}
                                                >
                                                    {partner?.customers?.find(
                                                        (c) =>
                                                            c.nationalIdentity ===
                                                            prospect.nationalIdentity
                                                    ) ? (
                                                        <Link
                                                            href={`/customer/${
                                                                partner?.customers?.find(
                                                                    (c) =>
                                                                        c.nationalIdentity ===
                                                                        prospect.nationalIdentity
                                                                ).depositorId
                                                            }`}
                                                            color="inherit"
                                                        >
                                                            {prospect.name}
                                                        </Link>
                                                    ) : (
                                                        prospect.name
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {Currency(
                                                        prospect.currentVolume ||
                                                            0
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        onClick={() =>
                                                            openInterestCalculator(
                                                                prospect
                                                            )
                                                        }
                                                    >
                                                        {t(
                                                            "pages-onboarding.calculateInterestHeader"
                                                        )}
                                                    </Button>
                                                </TableCell>
                                                <TableCell>
                                                    {partner?.customers?.find(
                                                        (c) =>
                                                            c.nationalIdentity ===
                                                            prospect.nationalIdentity
                                                    ) ? (
                                                        <Chip
                                                            label={t(
                                                                "pages-customers.registeredOnFixrate"
                                                            )}
                                                            variant="outlined"
                                                            color="success"
                                                            icon={
                                                                <i className="ri-check-line" />
                                                            }
                                                        />
                                                    ) : (
                                                        <AsyncButton
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={() =>
                                                                registerCustomer(
                                                                    prospect.nationalIdentity,
                                                                    prospect.nationality
                                                                )
                                                            }
                                                        >
                                                            {t(
                                                                "pages-customers.registerConfirmationHeading"
                                                            )}
                                                        </AsyncButton>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                            {filteredProspects.length > rowsPerPage &&
                                Pagination}
                        </TableContainer>
                        <Typography variant="body2">
                            {t("pages-customers.aboutClientListData")}
                        </Typography>
                    </>
                ) : (
                    <Typography
                        variant="emptyState"
                        fontSize={"2rem"}
                        color={SILVER_GRAY[500]}
                    >
                        {t("pages-customers.noCustomers")}
                    </Typography>
                )}
            </Stack>
            <FxDialog
                maxWidth="sm"
                fullWidth
                open={calculatorOpen}
                onClose={() => setCalculatorOpen(false)}
            >
                <FxDialogTitle onClose={() => setCalculatorOpen(false)}>
                    {t("pages-customers.potentialGain")}
                </FxDialogTitle>
                <FxDialogContent>
                    {calculatorStep === 1 && (
                        <Stack spacing={2} mt={2}>
                            <Box>
                                <InputLabel>
                                    {t("common.bankDeposits")}
                                </InputLabel>
                                <NumberInput
                                    formatFn={(v) =>
                                        Currency(v, { decimals: 0 })
                                    }
                                    value={calculatorVolume}
                                    onChange={(val) => setCalculatorVolume(val)}
                                />
                            </Box>
                            <Box>
                                <InputLabel>
                                    {t("common.currentInterest")}
                                </InputLabel>
                                <NumberInput
                                    value={calculatorInterest}
                                    onChange={(val) =>
                                        setCalculatorInterest(val)
                                    }
                                    data-cy="potentialVolume"
                                    sx={{ width: "10rem" }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        </Stack>
                    )}
                    {calculatorStep === 2 &&
                        (calculatorPotential > 0 ? (
                            <Stack spacing={2} mt={2}>
                                <Box>
                                    <InputLabel>Rentegevinst</InputLabel>
                                    <Typography variant="interestBig">
                                        {Currency(calculatorPotential, {
                                            decimals: 0,
                                        })}
                                    </Typography>
                                </Box>
                                <Box>
                                    <CopyPasteMessage
                                        linkText=""
                                        messageText={prospectMessageText(
                                            InterestOutput.format(
                                                calculatorInterest
                                            ),
                                            InterestOutput.format(
                                                marketInterestRate
                                            ),
                                            Currency(calculatorPotential, {
                                                decimals: 0,
                                            }),
                                            currentPartnerUser
                                        )}
                                        subject="Forbedring av rentevilkår"
                                    />
                                </Box>
                            </Stack>
                        ) : (
                            <Stack spacing={2} mt={2}>
                                <Typography color={SILVER_GRAY[500]}>
                                    {t("pages-customers.noCurrentInterestGain")}
                                </Typography>
                            </Stack>
                        ))}
                </FxDialogContent>
                <FxDialogActions>
                    {calculatorStep === 2 && (
                        <Button
                            variant="outlined"
                            onClick={() => setCalculatorStep(1)}
                        >
                            {t("pages-customers.calculateAgain")}
                        </Button>
                    )}
                    {calculatorStep === 1 && (
                        <AsyncButton
                            variant="contained"
                            onClick={() =>
                                calculateInterestPotential(
                                    calculatorVolume,
                                    calculatorInterest
                                )
                            }
                        >
                            {t("pages-onboarding.calculateInterestHeader")}
                        </AsyncButton>
                    )}
                </FxDialogActions>
            </FxDialog>
        </>
    );
}
