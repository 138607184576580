export type SupportCategoryNameType = keyof typeof SUPPORT_PAGE_NAME_MAP

export const SUPPORT_PAGE_NAME_MAP = {
    "gettingStarted": {
        NO: "67373b80378f835a1c845fe7",
        SE: "673df7a8d7f8ca986e10c129"
    },
    "generalTerms": {
        NO: "67373d5f378f835a1c8462d6",
        SE: "6787dafa7234fb0fce5ca05d"
    },
    "depositReports": {
        NO: "673c4e335bf702daa8b29d2d",
        SE: "6787dd617234fb0fce5ca6d7"
    },
    "rolesBank": {
        NO: "673c4ee85bf702daa8b29e3c",
        SE: "6787e0b67234fb0fce5cb0d6"
    },
    "rolesDepositor": {
        NO: "673c4f625bf702daa8b29ee9",
        SE: "6787df127234fb0fce5cac0a"
    },
    "roles": {
        NO: "673c4ee15bf702daa8b29e29",
        SE: "6787de4e7234fb0fce5ca9c5"
    },
    "rolesEmail": {
        NO: "673c51435bf702daa8b2a062",
        SE: "6787f7387234fb0fce5cea9d"
    },
    "authorizations": {
        NO: "673c50505bf702daa8b29fe2",
        SE: "6787f69a7234fb0fce5ce8c5"
    },
    "customerDeclaration": {
        NO: "673c52d95bf702daa8b2a272",
        SE: "6788b92c7234fb0fce5ed197"
    },
    "originOfFunds": {
        NO: "673c6c5b5bf702daa8b2a758",
        SE: "6788bdcf7234fb0fce5edfda"
    },
    "settlementAccounts": {
        NO: "67373cbb378f835a1c8461e0",
        SE: ""
    },
    "depositorAds": {
        NO: "67373b1b378f835a1c845f75",
        SE: "67582956de453cde2b53687f"
    },
    "pep": {
        NO: "673c6c815bf702daa8b2a791",
        SE: "6788b9be7234fb0fce5ed463"
    },
    "portfolio": {
        NO: "673c4d555bf702daa8b29c78",
        SE: ""
    },
    "nationalIdentityNumber": {
        NO: "673c6e995bf702daa8b2a963",
        SE: "6788df577234fb0fce5f51cc"
    },
    "interestRateChange": {
        NO: "673ca2ca5bf702daa8b2c665",
        SE: "67585622de453cde2b53f051"
    },
    "interestRateConvention": {
        NO: "67373f8a378f835a1c846730",
        SE: "67585825de453cde2b53f61a"
    },
    "terminationFloatingInterest": {
        NO: "67373e76378f835a1c8464c2",
        SE: "6758418dde453cde2b53b288"
    },
    "terminationFixedInterest": {
        NO: "67373e41378f835a1c846463",
        SE: "675840b9de453cde2b53af37"
    },
    "termsBankAcceptingDeposits": {
        NO: "67373f57378f835a1c8466cc",
        SE: "675857cdde453cde2b53f507"
    },
    "fundTermsAndConditions": {
        NO: "67373d89378f835a1c846343",
        SE: ""
    },
    "depositTermsOverview": {
        NO: "67373b3d378f835a1c845fa3",
        SE: "67583f21de453cde2b53a926"
    },
    "ownershipKeyPeople": {
        NO: "673cad195bf702daa8b2ca14",
        SE: "6788caab7234fb0fce5f0bbf"
    },
    "depositorAccountOpener": {
        NO: "67373d29378f835a1c84625f",
        SE: ""
    },
}
