import {
    DELETE,
    GET,
    POST,
    POST_NO_RESPONSE,
    POST_NO_RESPONSE_RAW,
    GET_WITHOUT_ERROR_MSG, REST_HANDLERS, POST_RAW,
} from '../network/rest-connection'
import ServerTimeStamp from "#services/ServerTimeStamp"
import {OrganisationType} from '@fixrate/fixrate-security'
import { Dispatch } from 'redux'
import { RegulatoryRegion } from '@fixrate/fixrate-query'

const queryString = (args) =>
    Object.keys(args).reduce((acc, key) => acc + (args[key]?((acc===''?'?':'&') + key + '=' + args[key]) : ''), '')

const handleCustomRange = (range) => {
    if (range) {
        if (range.includes('startDate') && range.includes('endDate')) {
            return range
        } else {
            return '?range=' + range
        }
    }
    return ''
}

// TODO: The only reason we do this instead of only dispatch is typing errors related to notifyPageEvent
type RestEndPointDispatch = Dispatch | ((action: any) => void)


const restEndpoint = (dispatch: RestEndPointDispatch, signal?:AbortSignal) => ({

    getSession: () => GET(dispatch,
        '/api/session',
        {
            handleHeaders: (headers) => {
                ServerTimeStamp.init(Date.parse(headers.get('date')))
            }
        }
    ),

    cloneSession: (organisationType: OrganisationType, organisationId: string) => POST_RAW(dispatch,
        '/api/session/clone',
        JSON.stringify({
            organisationType,
            organisationId
        })
    ),

    unlockSession: (pin) => POST_NO_RESPONSE_RAW(dispatch,
        '/api/session/unlock', pin,
    ),

    logout: () => POST_NO_RESPONSE(dispatch,
        '/api/session/logout', false,
    ),

    hardLogout: () => POST_NO_RESPONSE(dispatch,
        '/api/session/logout', true,
    ),

    organisation: (organisationType: OrganisationType, organisationId: string | null) => POST_NO_RESPONSE_RAW(dispatch,
        '/api/session/organisation',
        JSON.stringify({
            organisationType,
            organisationId
        }),
    ),

    focus: (depositorId) => POST_NO_RESPONSE_RAW(dispatch,
        '/api/session/focus',
        depositorId,
    ),

    registerLanding: (url, referrer) => POST_NO_RESPONSE(dispatch, '/api/landing/', {url:url, referrer:referrer}),

    registerUser: (user) => POST(dispatch, '/api/registration', user),

    getBankName: (bankIdentifier) => GET(dispatch, `/api/bankmapping?bankIdentifier=${bankIdentifier}`),

    getDepositorRegistrationStatus: (nationalIdentity) => GET(dispatch, `/api/depositor-registration/${nationalIdentity}`),

    dismissHintsModal: (hideInFuture) => POST_NO_RESPONSE_RAW(
        dispatch,
        '/api/session/dismissHintsModal',
        JSON.stringify(hideInFuture),
    ),

    potentialInterest: (potentialVolume, historicInterestRate) => POST(dispatch, '/api/pubstat/potential-interest', {
        potentialVolume,
        historicInterestRate,
    }),
    getPotentialInterest: (potentialVolume) => GET(dispatch, '/api/pubstat/potential-interest?potentialVolume=' + potentialVolume),

    getSupportArticleByUrl: (url) => GET(dispatch, '/api/support/article?url=' + encodeURIComponent(url)),

    getSupportArticleById: (id) => GET(dispatch, '/api/support/article/' + id),

    getSupportArticleByHref: (supportArticleHref) => GET(dispatch, '/api/support/v2' + supportArticleHref, {
        handleStatusCodeErrors: REST_HANDLERS.FAIL_ON_STATUS_CODE_ERRORS,
        handleErrors: REST_HANDLERS.NO_ERROR_MESSAGES,
    }),

    changeSupportArticleIndex: (id, change) => POST_NO_RESPONSE(dispatch, '/api/support/article/' + id + '/index',
        change,
    ),

    saveSupportArticle: (article) => POST(dispatch, '/api/support/article',
        article,
    ),

    deleteSupportArticle: (id) => DELETE(dispatch, '/api/support/article/' + id),

    getBankPresentation: (bankId) => GET(dispatch, '/api/bank/' + bankId),

    getLastOrders: () => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/orders/last'),

    getLastFullySubscribedAds: () => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/ads/last-fully-booked'),

    getLastOrdersByCategory: () => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/orders/last-by-category'),

    getAllOrders: () => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/orders/all'),

    getDepth: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/depth/all' + (range ? '?range=' + range : '')),
    getDepthByProduct: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/depth/product' + (range ? '?range=' + range : '')),
    getDepthByBankSegment: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/depth/banksegment' + (range ? '?range=' + range : '')),

    getDepthByInterestForNiborProducts: (range?) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/depth/interest-nibor-product' + (range ? '?range=' + range : '')),
    getDepthByInterestForFixedProducts: (range?) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/depth/interest-fixed-product' + (range ? '?range=' + range : '')),
    getDepthForBank: (bankId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/depth/bank/' + bankId + (range ? '?range=' + range : '')),
    getMeanDepthByBankSegment: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/depth/banksegment-mean' + (range ? '?range=' + range : '')),

    getTurnover: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/turnover/all' + (range ? '?range=' + range : '')),
    getTurnoverByProduct: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/turnover/product' + (range ? '?range=' + range : '')),
    getTurnoverForBank: (bankId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/turnover/bank/' + bankId + (range ? '?range=' + range : '')),
    getTurnoverByProductForBank: (bankId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/turnover/bank-product/' + bankId + (range ? '?range=' + range : '')),

    getVolumePerSegment: (range, interestRangeSize) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/volume/nibor-products-interest' + queryString({
        range,
        interestRangeSize,
    })),

    getBusinessVolume: () => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/deposits/business?range=NOW'),
    getBusinessVolumeForBank: (bankId) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/deposits/bank-business/' + bankId + '?range=NOW'),
    getDepositVolumeByBankSegment: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/deposits/banksegment' + (range ? '?range=' + range : '')),
    getMeanDepositVolumeByBankSegment: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/deposits/banksegment-mean' + (range ? '?range=' + range : '')),
    getDepositorPortfolioByBank: (depositorId) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/deposits/depositor-portfolio-by-bank/' + depositorId + '?range=NOW'),
    getDepositorPortfolioByBankType: (depositorId) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/deposits/depositor-portfolio-by-bank-type/' + depositorId + '?range=NOW'),
    getDepositorPortfolioByBankCounty: (depositorId) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/deposits/depositor-portfolio-by-bank-county/' + depositorId + '?range=NOW'),
    getCurrentVolumeByBankForDepositor: (depositorId, product, bankSize, sort) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/deposits/bank-volume-for-depositor/' + depositorId + queryString({product, bankSize, sort})),

    getDepositDuration: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/duration/duration' + (range ? '?range=' + range : '')),

    getDepositInterestByBankSegment: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/banksegment' + (range ? '?range=' + range : '')),
    getDepositInterestByBankSegmentWithSsb: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/banksegment-with-ssb' + (range ? '?range=' + range : '')),
    getDepositInterestWithSsbAndMajorBanks: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/major-banks-with-ssb' + (range ? '?' + range : '')),
    getDepositInterestByProduct: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/product' + (range ? '?range=' + range : '')),
    getDepositInterestForBank: (bankId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/bank/' + bankId + (range ? '?range=' + range : '')),
    getDepositInterestByProductForBank: (bankId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/bank-product/' + bankId + (range ? '?range=' + range : '')),
    getDepositInterestForDepositor: (depositorId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/depositor/' + depositorId + (range ? '?range=' + range : '')),
    getDepositInterestByProductForDepositor: (depositorId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/depositor-product/' + depositorId + (range ? '?range=' + range : '')),

    getDepositInterestForDepositorWithSsb: (depositorId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/depositor-ssb/' + depositorId + handleCustomRange(range)),

    getEarningsForDepositor: (depositorId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/earnings/depositor/' + depositorId + handleCustomRange(range)),

    getDepositMarginByBankSegment: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/margin-banksegment' + (range ? '?range=' + range : '')),
    getDepositMarginByProduct: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/margin-product' + (range ? '?range=' + range : '')),
    getDepositMarginForBank: (bankId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/margin-bank/' + bankId + (range ? '?range=' + range : '')),
    getDepositMarginByProductForBank: (bankId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/margin-bank-product/' + bankId + (range ? '?range=' + range : '')),
    getDepositMarginForDepositor: (depositorId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/margin-depositor/' + depositorId + (range ? '?range=' + range : '')),
    getDepositMarginByProductForDepositor: (depositorId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/interest/margin-depositor-product/' + depositorId + (range ? '?range=' + range : '')),

    getAdInterestByProduct: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/ads/interest-product' + (range ? '?range=' + range : '')),
    getAdInterestByBankSegment: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/ads/interest-banksegment' + (range ? '?range=' + range : '')),
    getAdInterestForBank: (bankId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/ads/interest-bank/' + bankId + (range ? '?range=' + range : '')),

    getAdMarginByProduct: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/ads/margin-product' + (range ? '?range=' + range : '')),
    getAdMarginByBankSegment: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/ads/margin-banksegment' + (range ? '?range=' + range : '')),
    getAdMarginForBank: (bankId, range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/ads/margin-bank/' + bankId + (range ? '?range=' + range : '')),

    getNibor: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/nibor/with-folio' + (range ? '?' + range : '')),
    getNiborAll: (range) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/stagg/nibor/all' + (range ? '?range=' + range : '')),

    getBenchmarkInterest: (countryCode: RegulatoryRegion, range?: string) => GET(dispatch, '/api/stagg/benchmark/with-reference/' + countryCode + (range ? '?' + range : '')),

    getInvite: (inviteId: string) => GET_WITHOUT_ERROR_MSG(dispatch,
        '/api/registration/invite/' + inviteId,
        signal
    ),

    getInvoicesByBank: (bankId) => GET(dispatch,
        '/document/invoice/' + bankId,
    ),

    getFixtures: () => GET(dispatch, '/api/fixtures'),

    getCurrentFixture: () => GET(dispatch, '/api/fixtures?current=true'),

    getSsoAuthorizationEndpoint: (idpId) => GET(dispatch, '/api/sso/start?idpId=' + idpId),

    notifyPageEvent: (data) => POST_NO_RESPONSE(dispatch,
        '/api/notify/page', data, {handleStatusCodeErrors: REST_HANDLERS.IGNORE_STATUS_CODES, handleErrors: REST_HANDLERS.NO_ERROR_MESSAGES}
    ),
})

export default restEndpoint
