import AsyncButton from "#app/components/Button/AsyncButton";
import DateInput from "#app/components/DateInput";
import FxDialog from "#app/components/FxDialog/FxDialog";
import FxDialogActions from "#app/components/FxDialog/FxDialogActions";
import FxDialogContent from "#app/components/FxDialog/FxDialogContent";
import FxDialogTitle from "#app/components/FxDialog/FxDialogTitle";
import { useCommand } from "#app/services/beta";
import { useSelector } from "#app/state/useSelector";
import { Box, Button, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function AddEmailBirthdateDialog(
        {open, setOpen, customerId, personId} :
        {open: boolean, setOpen: (open: boolean) => void, customerId: string, personId: string}
    ) {
    const { t } = useTranslation()
    const { updatePerson } = useCommand()
    const customers = useSelector(state => state?.partner?.customers)
    const [email, setEmail] = useState(customers?.find(c => c?.depositorId === customerId)?.people.find(p => p.personId === personId)?.email || "")
    const initialBirthDate = customers?.find(c => c?.depositorId === customerId)?.people.find(p => p.personId === personId)?.birthDate
    const [birthDate, setBirthDate] = useState(initialBirthDate ? new Date(initialBirthDate) : null)
    const [loading, setLoading] = useState(false)
    const formElement = useRef<HTMLFormElement>(null)

    const updateSelectedPerson = async () => {
        const person = customers.find(c => c?.depositorId === customerId)?.people.find(p => p.personId === personId)
        if (!formElement.current.checkValidity()) {
            formElement.current.reportValidity()
            return
        }
        setLoading(true)
        const { waitForCommand } = await updatePerson(
            customerId,
            person.personId,
            person.firstName?.trim(),
            person.lastName?.trim(),
            birthDate,
            email?.trim(),
            person.address?.trim(),
            person.taxResidencies,
            person.americanCitizenship,
            person.pep,
            person.pepPosition,
            person.pepInvestingPrivateFunds,
            person.controlShare,
            person.keyPersonRole
        )
        const success = await waitForCommand()
        if (success) {
            setOpen(false)
        }
        setLoading(false)
    }

    const submitForm = (e: React.FormEvent) => {
        e.preventDefault()
        updateSelectedPerson()
    }

    return (
        <FxDialog open={open} onClose={() => setOpen(false)}>
            <FxDialogTitle onClose={() => setOpen(false)}>
                <Typography variant={"h3"}>{t('layers-sendProposal.addInformation')}</Typography>
            </FxDialogTitle>
            <FxDialogContent>
                <Stack py={2} spacing={1} alignItems="flex-start">
                    <Box>
                        <form ref={formElement} onSubmit={(e) => submitForm(e)}>
                            <Stack spacing={2}>
                                <Box>
                                    <InputLabel>{t('layers-sendProposal.addEmailDescription')}</InputLabel>
                                    <TextField data-cy="input-receiver-email" required value={email} onChange={e => setEmail(e.target.value)} sx={{ width: "40rem" }} placeholder="Epost" type="email" />
                                </Box>
                                <Box>
                                    <InputLabel>
                                        {t('pages-organizations.sendInviteBirthDateLabel')}
                                    </InputLabel>
                                    <DateInput
                                        id="birthDateDatePicker"
                                        required={true}
                                        value={birthDate}
                                        onChange={(val) => setBirthDate(val)}
                                    />
                                </Box>
                            </Stack>
                        </form>
                    </Box>
                </Stack>
            </FxDialogContent>
            <FxDialogActions>
                <Button onClick={() => setOpen(false)}>{t('common.closeWindow')}</Button>
                <AsyncButton loading={loading} data-cy="save-receiver-email" variant="contained" onClick={() => updateSelectedPerson()}>
                    {t('common.save')}
                </AsyncButton>
            </FxDialogActions>
        </FxDialog>
    )
}
