import { PURPLE } from '#app/colors/colors'
import { useBottomNavigation } from '#app/services/useBottomNavigation'
import { usePlacementProfileAccess } from '#app/services/usePlacementProfileAccess'
import { useAuthorization, useIsIntegrationsAdmin } from "#services/authorization"
import useOrganizationWarnings from '#services/useOrganizationWarnings'
import { DepositorDto } from '@fixrate/fixrate-query'
import { Badge, BottomNavigation, BottomNavigationAction, Box, Card, CardActionArea, CardContent, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { DownArrow, OkIcon, SubMenuIcon, WarningIcon } from './SubMenuIcons'

interface MenuEntryProps {
    icons: {
        normal: JSX.Element
        active: JSX.Element
    }
    id: string
    shortTitle?: string
    title: string
    to: string,
    warning?: boolean
    displayOkIcon?: boolean
}

function MenuEntry({icons, id, shortTitle, title, to, warning, displayOkIcon=true}: MenuEntryProps) {
    const active = useLocation().pathname.includes(to)
    const navigate = useNavigate()
    return (
        <Stack>
            <Card
                sx={{
                    width: "22rem", 
                    transform: active ? "scale(1.1)" : "scale(1)",
                    opacity: active ? 1 : 0.8,
                    transition: "transform 0.2s",
                    border: "0.4rem solid"
                }}
                id={id}
            >
                <CardActionArea onClick={() => navigate(to)}>
                    <CardContent>
                        <Stack alignItems="center" spacing={1}>
                            <Stack width={"6rem"}>{active? icons.active : icons.normal}</Stack>
                            <Typography fontWeight={600}>
                                {shortTitle ? shortTitle : title}
                            </Typography>
                            {warning
                                ? <WarningIcon />
                                : displayOkIcon ? <OkIcon/> : null
                            }
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Stack position="absolute" bottom={0} width="22rem" alignItems="center">
                <Box height="1.4rem" width="20%" sx={{"& svg path": { fill: active ? "#f2f2f2" : "transparent" }}}>
                    <DownArrow />
                </Box>
            </Stack>
        </Stack>
    )
}

const IconBadge = ({children, warning}: {children: JSX.Element, warning?: boolean}) => {
    return (
        <Badge color="error" variant="dot" invisible={!warning}>
            {children}
        </Badge>
    )
}

export default function SubMenu({depositor} : {depositor: DepositorDto}) {
    const {t} = useTranslation()
    const fundAccess = depositor?.fundData?.enabled
    const hasPlacementProfileAccess = usePlacementProfileAccess()
    const depositorWarnings = useOrganizationWarnings(depositor)
    const hasMultipleAgreements = depositor?.partnerRelations?.length > 1 || fundAccess
    const navigate = useNavigate()
    const location = useLocation()
    const [selectedNavigation, setSelectedNavigation] = useState(location.pathname.replace(/\/users\/.*$/, '/users/'))
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    useBottomNavigation()

    const showIntegrations = useIsIntegrationsAdmin()

    return isMobile ? (
        <BottomNavigation
            value={selectedNavigation}
            onChange={(event, newValue: string) => {
                const val = newValue.replace(/\/users\/.*$/, '/users/')
                setSelectedNavigation(val)
                navigate(newValue)
            }}
        >
            <BottomNavigationAction
                value={`/organizations/${depositor.id}/users/`}
                label={t('pages-organizations.menuItemUsers')}
                icon={
                    <IconBadge warning={depositorWarnings?.users.length > 0}>
                        <i className={selectedNavigation === `/organizations/${depositor.id}/users/` ? 'ri-group-fill' : 'ri-group-line'}/>
                    </IconBadge>
                }
            />
            <BottomNavigationAction
                value={`/organizations/${depositor.id}/customerdecl`}
                label={t('pages-organizations.menuItemCustomerDeclaration')}
                icon={
                    <IconBadge warning={depositorWarnings?.customerDeclaration.length > 0}>
                        <i className={selectedNavigation === `/organizations/${depositor.id}/customerdecl` ? 'ri-survey-fill' : 'ri-survey-line'} />
                    </IconBadge>
                }
            />
            <BottomNavigationAction
                value={`/organizations/${depositor.id}/accounts`}
                label={t('pages-organizations.menuItemSettlementAccount')}
                icon={
                    <IconBadge warning={depositorWarnings?.accounts.length > 0}>
                        <i className={selectedNavigation === `/organizations/${depositor.id}/accounts` ? 'ri-hand-coin-fill' : 'ri-hand-coin-line'} />
                    </IconBadge>
                }
            />
            <BottomNavigationAction
                value={`/organizations/${depositor.id}/terms`}
                label={t('pages-organizations.menuItemAgreementShort')}
                icon={
                    <IconBadge warning={depositorWarnings?.terms.length > 0}>
                        <i className={selectedNavigation === `/organizations/${depositor.id}/terms` ? 'ri-file-list-3-fill' : 'ri-file-list-3-line'} />
                    </IconBadge>
                }
            />
        </BottomNavigation>
    ) : (
        <Stack
            position={"relative"}
            direction="row"
            spacing={5}
            pl={4.5}
            py={3}
            sx={{backgroundColor: PURPLE[900], overflowX: "auto", overflowY: "hidden"}}
            data-cy="organisationSubMenu">
            <MenuEntry
                icons={{
                    normal: <img src={SubMenuIcon.users.normal} alt="" />,
                    active: <img src={SubMenuIcon.users.active} alt="" />
                }}
                id="usersSubMenuItem"
                title={t('pages-organizations.menuItemUsers')}
                to={`/organizations/${depositor.id}/users/`}
                warning={depositorWarnings?.users.length > 0 || depositorWarnings?.organisationalRoles.length > 0}
            />
            <MenuEntry
                icons={{
                    normal: <img src={SubMenuIcon.customerDeclaration.normal} alt="" />,
                    active: <img src={SubMenuIcon.customerDeclaration.active} alt="" />
                }}
                id="customerDeclarationSubMenuItem"
                title={t('pages-organizations.menuItemCustomerDeclaration')}
                to={`/organizations/${depositor.id}/customerdecl`}
                warning={depositorWarnings?.customerDeclaration.length > 0}
            />
            <MenuEntry
                icons={{
                    normal: <img src={SubMenuIcon.settlementAccount.normal} alt="" />,
                    active: <img src={SubMenuIcon.settlementAccount.active} alt="" />
                }}
                id="settlementAccountSubMenuItem"
                title={t('pages-organizations.menuItemSettlementAccount')}
                to={`/organizations/${depositor.id}/accounts`}
                warning={depositorWarnings?.accounts.length > 0}
            />
            <MenuEntry
                icons={{
                    normal: <img src={SubMenuIcon.termsAndAgreements.normal} alt="" />,
                    active: <img src={SubMenuIcon.termsAndAgreements.active} alt="" />
                }}
                id="fixrateAgreementSubMenuItem"
                shortTitle={t('pages-organizations.menuItemAgreementShort')}
                title={hasMultipleAgreements ? t('pages-organizations.menuItemAgreements') : t('pages-organizations.menuItemAgreementWithFixrate')}
                to={`/organizations/${depositor.id}/terms`}
                warning={depositorWarnings?.terms.length > 0}
            />
            {showIntegrations && (
                <MenuEntry
                    icons={{
                        normal: <img src={SubMenuIcon.integrations.normal} alt="" />,
                        active: <img src={SubMenuIcon.integrations.active} alt="" />
                    }}
                    id="integrationsSubMenuItem"
                    title={t('pages-organizations.menuItemIntegrations')}
                    to={`/organizations/${depositor.id}/integrations`}
                    warning={depositorWarnings?.integrations.length > 0}
                    displayOkIcon={false}
                />
            )}
            {hasPlacementProfileAccess && (
                <MenuEntry
                    icons={{
                        normal: <i className="ri-list-settings-line" style={{fontSize: "5rem"}} />,
                        active: <i className="ri-list-settings-fill" style={{fontSize: "5rem"}} />,
                    }}
                    id="integrationsSubMenuItem"
                    title={t('pages-organizations.mandate')}
                    to={`/organizations/${depositor.id}/placement-profile/`}
                    displayOkIcon={false}
                />
            )}
        </Stack>
    )
}
