import styles from './DepositorOfferTable.module.css'
import {DateOutput, InterestOutput, Table, TableCell, TableHeader, TableHeaderCell, TableRow} from '#components'
import {useSelector} from '#state/useSelector'
import {DepositorOfferDto} from '@fixrate/fixrate-query'
import {Box} from '@mui/material'
import {useTranslation} from 'react-i18next'


export default function DepositorOfferTable({depositorOffers, onSelect, emptyTableText}: {
    depositorOffers: DepositorOfferDto[],
    onSelect: (depositorOfferId: string) => void,
    emptyTableText: string
}) {
    const {t} = useTranslation()
    const products = useSelector(state => state.products)
    const depositors = useSelector(state => state.depositors)

    const depositorOfferTableRows = depositorOffers
        .sort((a, b) => {
            const dateA = a.publishedAt ? new Date(a.publishedAt).getTime() : 0;
            const dateB = b.publishedAt ? new Date(b.publishedAt).getTime() : 0;
            return dateB - dateA;
        })
        .map((offer, index) => {
                const acceptedVolume = offer.banks.reduce((acc, cur) => {
                    return acc + cur.acceptedVolume
                }, 0) || 0
                let deadline = <span/>
                if (!offer.publishedAt) {
                    deadline = <span className={styles.notPublished}>{t('pages-offer.deadlineNotPublished')}</span>
                } else if (offer.deadline) {
                    deadline = <span>{DateOutput.formatDateTime(offer.deadline)}</span>
                }
                const depositor = depositors.find(d => d.id === offer.depositorId)
                return (
                    <TableRow key={index} onClick={() => onSelect(offer.depositorOfferId)}>
                        <TableCell align={'right'}>{products[offer.productId]?.name || '...'}</TableCell>
                        <TableCell align={'right'}>{InterestOutput.format(offer.interestRate) || '...'}</TableCell>
                        <TableCell className={styles.depositorName}>{depositor?.name || ''}</TableCell>
                        <TableCell>{deadline}</TableCell>
                        <TableCell align={'right'}>{offer.totalVolume || '...'}</TableCell>
                        <TableCell align={'right'}>{offer.publishedAt ? acceptedVolume : ''}</TableCell>
                    </TableRow>
                )
            }
        )

    if (!depositorOffers || depositorOffers.length === 0) {
        return <div className={styles.emptyTable}>{emptyTableText}</div>
    }

    return (
        <Box width={'100%'} overflow="auto">
            <Table>
                <TableHeader>
                    <TableHeaderCell>{t('pages-offer.product')}</TableHeaderCell>
                    <TableHeaderCell align={'right'}>{t('pages-offer.interestRate')}</TableHeaderCell>
                    <TableHeaderCell>{t('pages-offer.organization')}</TableHeaderCell>
                    <TableHeaderCell>{t('pages-offer.deadline')}</TableHeaderCell>
                    <TableHeaderCell align={'right'}>{t('pages-offer.volume')}</TableHeaderCell>
                    <TableHeaderCell align={'right'}>{t('pages-offer.ordered')}</TableHeaderCell>
                </TableHeader>
                {depositorOfferTableRows}
            </Table>
        </Box>
    )
}
