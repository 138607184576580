
import { InterestOutput } from '#app/components'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { StaggData } from '#app/state/stagg'
import { Box, SxProps } from '@mui/material'
import { Chart, ChartOptions } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { nb } from 'date-fns/locale'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { formatStaggData } from '../formatStaggData'
interface LineChartProps {
    staggData: StaggData,
    yMin?: number,
    sx?: SxProps,
    exludeNames?: string[]
    colors?: string[]
    valueType?: 'interest' | 'currency',
    quarterlyNumbers?: boolean
    gradient?: boolean
    formatFn?: (value: number | string) => string
    reference?: React.RefObject<HTMLElement>
}

const LineChart = ({staggData, yMin=0, exludeNames, sx, colors, valueType, quarterlyNumbers, gradient, formatFn, reference}: LineChartProps) => {
    const Currency = useCurrencyOutput()
    const chartRef = useRef<Chart | null>(null)
    const { t } = useTranslation()

    const labelReplacer = (label: string) => {
        if (label === "SSB") {
            return t('pages-analytics.meanBusinessAccount')
        }

        if (label === "Fixrate") {
            return t('pages-analytics.meanBusinessFixrate')
        }

        return label;
    }


    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        if (!canvas) return;

        const context = canvas.getContext("2d");
        const chartData = formatStaggData({ input: staggData, exludeNames: exludeNames, gradient: gradient ?? true, ctx: context, colors: colors })
        const numberWithAnnotation = (value: number | string) => {
            if (formatFn) {
                return formatFn(value)
            }
            const val = typeof value === "number" ? value : parseFloat(value)
            if (valueType === "currency") {
                return Currency(val, { decimals: 0 })
            }
            return InterestOutput.formatNoDecimal(val)
        }

        if (context && !chartRef.current) {
            chartRef.current?.destroy()
            chartRef.current = new Chart(context, {
                type: "line",
                data: {
                    datasets: chartData.datasets.map(dataset => ({
                        ...dataset,
                        label: labelReplacer(dataset.label)
                    })),
                    labels: chartData.labels,
                },
                options: {
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true,
                                padding: 20
                            },
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    return `${context.dataset.label}: ${numberWithAnnotation(context.parsed.y)}`
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                tooltipFormat: quarterlyNumbers ? "qqq yyyy" : 'dd.MM.yyyy',
                                unit: quarterlyNumbers ? 'quarter' : 'month',
                                displayFormats: quarterlyNumbers ? {} : {
                                    day: 'dd. MMM',
                                },
                            },
                            ticks: {
                                autoSkipPadding: 30,
                                font: {
                                    family: "'Montserrat'",
                                    weight: '600',
                                },
                                maxTicksLimit: 8,
                            },
                            adapters: {
                                date: {
                                    locale: nb
                                }
                            },
                            grid: {
                                color: "rgba(0,0,0,0.07)"
                            },
                        },
                        y: {
                            ticks: {
                                font: {
                                    family: "'Montserrat'",
                                    weight: '600'
                                },
                                callback: function(value) {
                                    return numberWithAnnotation(value)
                                }
                            },
                            beginAtZero: true,
                            min: yMin,
                            grid: {
                                color: "rgba(0,0,0,0.1)"
                            },
                        }
                    },
                    onResize: (chart, size) => {
                        if (size.height < 300) {
                            chart.options.maintainAspectRatio = false
                        }
                    },
                },
            })
        }
    }

    return (
        <Box sx={sx} height={"50rem"} ref={reference}>
            <Box ref={canvasCallback} component="canvas" maxHeight={"100%"} />
        </Box>
    )
}

export { LineChart }
