import * as React from 'react'
import {useEffect, useState} from 'react'
import {SxProps, TextField} from '@mui/material'


type Props = {
    id?: string
    label?: string | null
    value: number | null
    onChange: (value: number | null) => void
    onBlur?: (e: React.FocusEvent<HTMLElement>) => void
    size?: 'small' | 'medium'
    formatFn?: (value: number | null) => string
    placeholder?: string
    helperText?: string
    sx?: SxProps
    title?: string
    InputProps?: {
        startAdornment?: JSX.Element,
        endAdornment?: JSX.Element
    }
    error?: boolean
    noDecimals?: boolean
    enforceThousandSeparator?: boolean
    'data-cy'?: string
    className?: string
    disabled?: boolean
}

/**
 * A number input field that allows for easy editing of formatted numbers.
 *
 * The component always regards "," and "." as decimal separators. It does not respect the locale of the user.
 *
 * The component use space as thousand-separator. This can be disabled by setting enforceThousandSeparator to false.
 *
 */
export default function NumberInput({
                                        id,
                                        label,
                                        value,
                                        title,
                                        InputProps,
                                        onChange,
                                        onBlur,
                                        size,
                                        formatFn,
                                        placeholder,
                                        sx,
                                        error,
                                        noDecimals,
                                        helperText,
                                        enforceThousandSeparator,
                                        'data-cy': dataCy,
                                        className,
                                        disabled
                                    }: Props) {

    const [inputValue, setInputValue] = useState(formatStringValue(value?.toString() ?? '', enforceThousandSeparator, formatFn, noDecimals))

    useEffect(() => {
        setInputValue(formatStringValue(value?.toString() ?? '', enforceThousandSeparator, formatFn, noDecimals))
    }, [value, enforceThousandSeparator, formatFn, noDecimals])

    return (
        <TextField
            id={id}
            size={size}
            label={label ?? ''}
            disabled={disabled}
            inputMode={'tel'}
            sx={{...sx}}
            value={inputValue}
            inputProps={{'data-cy': dataCy}}
            className={className}
            error={error}
            InputProps={InputProps}
            helperText={helperText}
            title={title}
            onBlur={onBlur}
            placeholder={placeholder}
            onChange={e => {
                setInputValue(formatStringValue(e.target.value, enforceThousandSeparator, formatFn, noDecimals))
                const outputValue = parseStringValue(e.target.value, !!noDecimals)
                onChange(outputValue)
            }}
        />
    )
}

/**
 * Creates a canonical string representation of a number
 * Allows only numbers and punctuation and dashes
 */
function canonicalStringFormat(stringValue: string) {
    return stringValue.replace(/[^0-9.,-]/g, '').replace(/\s/g, '').replace(',', '.')
}

function parseStringValue(stringValue: string, noDecimals: boolean): number | null {
    const _canonicalStringFormat = canonicalStringFormat(stringValue)

    const numberValue = !isNaN(parseFloat(_canonicalStringFormat)) ? parseFloat(_canonicalStringFormat) : null

    if (numberValue === null) {
        return null
    }
    return noDecimals ? Math.floor(numberValue) : numberValue
}

function formatStringValue(value: string | null, enforceThousandSeparator: boolean | undefined, formatFn: (value: number) => string, noDecimals: boolean): string {
    if (value === '' || value === null) {
        return ''
    }
    if (formatFn) {
        return formatFn(parseStringValue(value, noDecimals))
    }
    if (enforceThousandSeparator) {
        const thousandFormatted = canonicalStringFormat(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        return thousandFormatted.replace('.', ',')
    }
    return value.replace('.', ',')
}

