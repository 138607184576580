import { motion } from 'motion/react'


interface AnimatedProps {
    i?: number
    children?: React.ReactNode
    className?: string
    tag?: string // li, div, tr
    key?: string | number
    'data-fx'?: string
    'data-cy'?: string
    'data-creditduration'?: string
    'data-omf'?: boolean
    'data-subordinatedloans'?: boolean
}

const spring = {
    type: 'spring',
    damping: 100,
    stiffness: 200,
}

const variants = {
    initial: {
        scale: 0.01,
        opacity: 0,
    },
    current: (i) => ({
        scale: 1,
        opacity: 1,
        transition: {
            duration: 0.35,
            delay: isNaN(i) ? 0 : i * 0.15,
        },
    }),
    exit: {
        opacity: 0,
        scale: 0,
        transition: {
            duration: 0.2,
        },
    },
}

const Animated = function({ i = 0, children, tag = 'div', ...props }: AnimatedProps) {
    const Tag = motion[tag]
    return (
        <Tag
            {...props}
            data-animated="true"
            layout={spring}
            variants={variants}
            initial="initial"
            animate="current"
            exit="exit"
            custom={i}
        >
            {children}
        </Tag>
    )
}

export default Animated