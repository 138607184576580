import { PURPLE, SPRING_GREEN } from '#app/colors/colors'
import { LoadingSpinner, PageHeader } from '#app/components'
import { BarChart } from '#app/components/Charts/BarChart/BarChart'
import { LineChart } from '#app/components/Charts/LineChart/LineChart'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { KeyFigureCard } from '#app/components/KeyFigureCard/KeyFigureCard'
import { DownloadPDFButton, PDFReportPages } from '#app/components/PDFGenerator/PDFGenerator'
import { CustomerKeyFigure } from '#app/pages/Customers/CustomerOverview/components/CustomerKeyFigure'
import {
    getDepositInterestForDepositorWithSsb,
    getDepositInterestWithSsbAndMajorBanks,
    getEarningsForDepositor
} from '#app/services/thunks/statistics'
import PageLayout from '#components/PageLayout/PageLayout'
import { isLoggedIn } from '#state/selectors'
import { StaggData } from '#state/stagg'
import { useSelector } from '#state/useSelector'
import { Box, IconButton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import { eachYearOfInterval, format, isAfter, isSameYear, isThisYear, sub } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import CoinIllustration from "./purple-coins.svg"

export interface EarningsData {
    totalEarnings: number,
    earnings: number
}

type RangePeriod = string

type LoadingState = {
    earnings?: boolean,
    portfolioGraph?: boolean,
    earningsError?: boolean,
    portfolioGraphError?: boolean
}

const ValueReport = ({depositorId, depositorName, registrationDate, darkMode, backLink} : {depositorId: string, depositorName: string, registrationDate?: string, darkMode?: boolean, backLink?: string}) => {
    const {t} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const loggedIn = useSelector(isLoggedIn)
    const dispatch = useDispatch<(arg0: unknown) => Promise<unknown>>()
    const Currency = useCurrencyOutput()
    const majorBankRef = useRef<HTMLDivElement>(null)
    const SSBChartRef = useRef<HTMLDivElement>(null)

    const textColor = darkMode ? PURPLE[25] : undefined

    const startDate = registrationDate ? new Date(registrationDate) : sub(new Date(), {years: 1})
    const endDate = sub(new Date(), {years: 1})

    const rangeValues = (isSameYear(startDate, endDate) || !isAfter(endDate, startDate)) ? [startDate] : eachYearOfInterval({
        start: startDate,
        end: endDate
    })

    const [ssbData, setSsbData] = useState<StaggData>([])
    const [earnings, setEarnings] = useState(0)
    const [earningsInterval, setEarningsInterval] = useState<RangePeriod>(searchParams.has("year") ? searchParams.get("year") : rangeValues[0].getFullYear().toString())
    const [majorBanksData, setMajorBanksData] = useState<StaggData>()
    const [loadingState, setLoadingState] = useState<LoadingState>({})
    const loading = loadingState.earnings || loadingState.portfolioGraph
    const error = loadingState.earningsError || loadingState.portfolioGraphError

    const isCurrentYear = isThisYear(new Date(earningsInterval))
    const endRangeString = isCurrentYear ? format(new Date(), "yyyy-MM-02") : earningsInterval + "-12-02"

    const getPortfolioGraphData = useCallback((rangeVal: RangePeriod) => {
        let range: RangePeriod | string
        if (rangeVal === 'ALL') {
            range = "?startDate=2018-01-01&endDate=" + new Date().toISOString().split("T")[0]
        } else {
            range = rangeVal
        }
        setLoadingState(current => ({...current, portfolioGraph: true, portfolioGraphError: false}))
        dispatch(getDepositInterestForDepositorWithSsb(depositorId, range))
            .then((chartData: StaggData) => {
                chartData[0].series = chartData[0].series.filter(item => item.tags.name !== 'Fixrate')

                setSsbData(chartData)
                setLoadingState(current => ({...current, portfolioGraph: false}))
            })
            .catch((err) => {
                setLoadingState(current => ({...current, portfolioGraph: false, portfolioGraphError: true}))
                console.error(err)
            })
    }, [depositorId, dispatch])

    const getEarningsData = useCallback((range: RangePeriod) => {
        setLoadingState(current => ({...current, earnings: true, earningsError: false}))
        dispatch(getEarningsForDepositor(depositorId, range))
            .then((data: EarningsData) => {
                setEarnings(data.earnings)
                setLoadingState(current => ({...current, earnings: false}))
            })
            .catch(err => {
                setLoadingState(current => ({...current, earnings: false, earningsError: true}))
                console.error(err)
            })
    }, [depositorId, dispatch])

    const getMajorBanksData = useCallback((range: RangePeriod) => {
        setLoadingState(current => ({...current, earnings: true, earningsError: false}))
        if (range === 'ALL') {
            range = new Date().getFullYear().toString()
        }
        dispatch(getDepositInterestWithSsbAndMajorBanks("startDate=" + earningsInterval + "-12-01" + "&endDate=" + earningsInterval + "-12-02"))
            .then((data: StaggData) => {
                setMajorBanksData(data)
                setLoadingState(current => ({...current, earnings: false}))
            })
            .catch(err => {
                setLoadingState(current => ({...current, earnings: false, earningsError: true}))
                console.error(err)
            })
    }, [dispatch, earningsInterval])

    useEffect(() => {
        if (!searchParams.has("year")) {
            setSearchParams({year: earningsInterval})
        }
    }, [searchParams, earningsInterval, setSearchParams])

    // Gets the initial data
    useEffect(() => {
        if (loggedIn) {
            const selectedRange = "?startDate=" + earningsInterval + "-01-01&endDate=" + earningsInterval + "-12-31"
            getPortfolioGraphData(selectedRange)
            getEarningsData(selectedRange)
            getMajorBanksData(earningsInterval)
        }
    }, [getEarningsData, getPortfolioGraphData, getMajorBanksData, loggedIn, earningsInterval])

    const handleChangeEarningsInterval = (range: RangePeriod) => {
        setEarningsInterval(range)
        setSearchParams({year: range})
    }

    const addDepositorInterestToMajorBanksData = (majorBanksData: StaggData) => {
        const interestDataPoint = ssbData[0]?.series.find(s => s.name === "depositorInterest").values.find(v => v[0] === endRangeString)

        if (interestDataPoint && !majorBanksData[0].series.find(s => s.name === depositorName)) {
            const depositorInterest = ssbData[0]?.series.find(s => s.name === "depositorInterest").values.find(v => v[0] === endRangeString)[1]
            majorBanksData[0].series.push({
                name: depositorName,
                columns: ["quarter", "interest"],
                tags: {name: depositorName},
                values: [[endRangeString, depositorInterest]]
            })
        }
        majorBanksData[0].series.sort((a, b) => {
            // Get the latest interest value for each series
            const aValue = a.values[a.values.length - 1]?.[1] || 0;
            const bValue = b.values[b.values.length - 1]?.[1] || 0;
            return bValue - aValue; // Sort in descending order
        })

        return majorBanksData
    }

    const reportPages: PDFReportPages = [
        {
            content: [
                { type: "title", text: t('pages-customers.addedValueReport') },
                { type: "subTitle", text: t('pages-analytics.addedValueEstimated') },
                { type: "text", text: t('pages-analytics.addedValueDescription').replace("*", "") },
                { type: "bigText", text: Currency(earnings, {decimals: 0}) },
                { type: "subTitle", text: t('pages-analytics.interestRate')},
                { type: "text", text: t('pages-analytics.addedValueDescriptionPDF', {companyName: depositorName})},
                { type: "chart", ref: SSBChartRef },
            ]
        },
        {
            content: [
                { type: "subTitle", text: t("pages-analytics.interestComparison")},
                { type: "text", text: t("pages-analytics.interestComparisonDescription", {date: format(endDate, "yyyy-12-01")})},
                { type: "chart", ref: majorBankRef },
            ]
        }
    ]

    return (
        <>
            <PageHeader icon="ri-bar-chart-line" title={t('pages-customers.addedValueReport') + " – " + depositorName} backToLink={backLink ?? undefined}/>
            <PageLayout sx={{backgroundColor: darkMode ? PURPLE[900] : "white", color: textColor}}>
                <Stack spacing={5} maxWidth={1400}>
                    <Stack direction="row" spacing={4} flexWrap="wrap" alignItems="center" justifyContent="space-between">
                        { rangeValues.length > 1 && (
                            <Tabs
                                textColor="inherit"
                                indicatorColor="darkMode"
                                value={earningsInterval}
                                onChange={(e, value) => handleChangeEarningsInterval(value)}
                            >
                                {rangeValues.reverse().map(date => (
                                    <Tab value={date.getFullYear().toString()} key={date.getFullYear()} label={date.getFullYear().toString()}/>
                                ))}
                            </Tabs>
                        )}
                        <DownloadPDFButton
                            pages={reportPages}
                            fileName={"merverdirapport-" + depositorName.replaceAll(" ", "-") + "-" + earningsInterval}
                        />
                    </Stack>
                    <Box>
                        <KeyFigureCard
                            label={t('pages-analytics.addedValueEstimated')}
                            direction="row"
                            disclaimer={t('pages-analytics.addedValueDescription')}
                            value={Currency(earnings, {decimals: 0})}
                            sx={{display: "inline-flex"}}
                            remixIcon="ri-hand-coin-fill" />
                    </Box>
                    {loggedIn && (
                        <div>
                            {loading && (
                                <LoadingSpinner/>
                            )}
                            {error && (
                                <p>
                                    <i className="ri-error-warning-line"/>
                                    <span>{t('pages-analytics.errorMessage')}</span>
                                </p>
                            )}
                            {!loading && !error && (
                                <Stack spacing={2}>
                                    <Stack spacing={1}>
                                        <Typography variant="h2" mb={0} color={textColor}>{t('pages-analytics.interestRate')}</Typography>
                                        <Typography component={"p"} variant="caption">
                                            <Trans t={t} i18nKey={'pages-customers.interestComparisonDetails'}>
                                                Grafen viser din rente i perioden sammenlignet med <a style={{color: textColor}} href="https://www.ssb.no/statbank/table/11018/" rel="noreferrer" target="_blank">gjennomsnittsrenten på innskudd for bedrifter.</a> Merverdien for perioden hvor det ikke finnes SSB-data beregnes ut i fra siste målepunkt.
                                            </Trans>
                                        </Typography>
                                    </Stack>
                                    { ssbData.length > 0 &&
                                        <LineChart reference={SSBChartRef} sx={{height: "60rem"}} staggData={ssbData}/>
                                    }
                                </Stack>
                            )}
                        </div>
                    )}
                    { (majorBanksData && ssbData) && (
                        <Stack spacing={3}>
                            <Stack spacing={1}>
                                <Typography variant="h2" mb={0} color={textColor}>{t('pages-analytics.interestComparison')}</Typography>
                                <Typography variant="caption" component="p">{t('pages-analytics.interestComparisonDescription', {date: format(endDate, "yyyy-12-01")})}</Typography>
                            </Stack>
                            <BarChart
                                darkMode={darkMode ? true : false}
                                reference={majorBankRef}
                                maxLabelLength={20}
                                highlightItem={depositorName}
                                staggData={addDepositorInterestToMajorBanksData(majorBanksData)}
                                exludeNames={["ssbInterest", "fixrateInterest"]} />
                        </Stack>
                    )}
                </Stack>
            </PageLayout>
        </>
    )
}

export default ValueReport

