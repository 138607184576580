import xorBy from 'lodash/xorBy'
import groupBy from 'lodash/groupBy'
import differenceInCalendarQuarters from 'date-fns/differenceInCalendarQuarters'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfQuarter from 'date-fns/startOfQuarter'
import subQuarters from 'date-fns/subQuarters'
import config from '../../config'

export const dateFormatString = 'yyyyqqq'
export const labelFormatString = 'qqq yyyy'

const dataTypes = [
    'depositCoverage',
    'coreCapitalCoverage',
    'totalAssets',
    'totalAssetsIncludingMortgageCredit',
]

type BankInfo = {
    quarter: string,
    totalAssets?: number,
    totalAssetsIncludingMortgageCredit?: number,
    depositCoverage?: string,
    coreCapitalCoverage?: string,
    quarterlyReportId?: number | null,
    yearlyReportId?: number | null,
}

export const formatData = (bankInfoList, filters = ['quarter']): BankInfo[] => {
    let amountOfQuartersToFill = 8
    const lastQuarter = subQuarters(startOfQuarter(new Date()), 1)

    // Finds quarter
    const bankInfoListWithValues = bankInfoList.filter(info => !filters.every(filter => !info[filter]))

    if (bankInfoListWithValues.length > 0) {
        const firstQuarterRegistered = parse(bankInfoListWithValues[0].quarter, dateFormatString, new Date())
        const differenceInQuarters = differenceInCalendarQuarters(lastQuarter, firstQuarterRegistered)
        amountOfQuartersToFill = differenceInQuarters >= amountOfQuartersToFill ? differenceInQuarters : amountOfQuartersToFill
    }

    const fillerQuarters = []
    for (let i = 0; i <= amountOfQuartersToFill; i++) {
        fillerQuarters.unshift({quarter: format(subQuarters(lastQuarter, i), dateFormatString)})
    }

    const emptyQuarters = xorBy(fillerQuarters, bankInfoListWithValues, 'quarter')

    return bankInfoListWithValues.concat(emptyQuarters).sort((v1, v2) => v1.quarter.localeCompare(v2.quarter))
}

export const getBanksInSameSegment = (bank, banks) => {
    if (!config().enableBenchmark) return []
    const qualifiedBanks = banks.filter(bank => !bank.bankInfoList.every(info => !info.totalAssetsIncludingMortgageCredit))

    if (bank.bankInfoList.filter(info => !!info.totalAssetsIncludingMortgageCredit).length === 0) return []

    const latestTotalAssetsIncludingMortgageCredit = bank.bankInfoList[bank.bankInfoList.length - 1].totalAssetsIncludingMortgageCredit

    if (latestTotalAssetsIncludingMortgageCredit >= 15000) {
        return qualifiedBanks.filter(bank => bank.bankInfoList[bank.bankInfoList.length - 1].totalAssetsIncludingMortgageCredit >= 15000)
    } else if (latestTotalAssetsIncludingMortgageCredit >= 5000) {
        return qualifiedBanks.filter(bank => {
            const totalAssetsIncludingMortgageCredit = bank.bankInfoList[bank.bankInfoList.length - 1].totalAssetsIncludingMortgageCredit
            return 15000 > totalAssetsIncludingMortgageCredit && totalAssetsIncludingMortgageCredit >= 5000
        })
    } else {
        return qualifiedBanks.filter(bank => bank.bankInfoList[bank.bankInfoList.length - 1].totalAssetsIncludingMortgageCredit < 5000)
    }
}

export const formatBenchmark = (banks, data) => {
    if (!config().enableBenchmark) return []
    const banksDataList = banks
        .flatMap(bank => bank.bankInfoList)
        .filter(info => !!info.quarter)

    const infoPerQuarter = groupBy(banksDataList, 'quarter')

    return data.map(benchmark => {
        const infoListForQuarter = infoPerQuarter[benchmark.quarter]

        const fields = {...dataTypes.map(dataType => ({[dataType]: undefined}))}

        if (infoListForQuarter) {
            dataTypes.forEach(dataType => {
                const dataListForQuarter = infoListForQuarter.filter(info => !!info[dataType])
                if (dataListForQuarter.length > 1) {
                    fields[dataType] = (dataListForQuarter.map(info => info[dataType]).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / dataListForQuarter.length).toFixed(2)
                }
            })
        }

        return ({quarter: benchmark.quarter, ...fields})
    })
}