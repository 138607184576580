import {PageHeader, PageLayout} from '#app/components';
import {useSelector} from "#state/useSelector";
import {Stack, ToggleButton, ToggleButtonGroup} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {PortfolioTabber} from "#components/PortfolioTabber/PortfolioTabber";

export default function BankDepositReports() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const portfolios = useSelector(state => state.depositor?.portfolios)
    const currencies = portfolios?.map(p => p.currency) || []

    return (
        <>
            <PageHeader icon="ri-file-chart-2-line" title={t('pagetitles.reports')}/>
            <PageLayout>
                {currencies.length > 1 && (
                    <Stack direction="row" spacing={2} mb={3} alignItems="flex-end" justifyContent="space-between">
                        <PortfolioTabber/>
                    </Stack>
                )}
                <ToggleButtonGroup sx={{mb: 3, backgroundColor: 'white'}} value={location.pathname} size={'small'} color={'primary'}>
                    <ToggleButton sx={{px: 2}} value={'/reports/deposits/interest-balance'} onClick={(_, url) => navigate(url)}>
                        {t('pages-portfolio-depositor.reportsInterestAndBalanceHeader')}
                    </ToggleButton>
                    <ToggleButton sx={{px: 2}} value={'/reports/deposits/collected-statement'} onClick={(_, url) => navigate(url)}>
                        {t('pages-portfolio-depositor.reportsCollectedStatementHeader')}
                    </ToggleButton>
                    <ToggleButton sx={{px: 2}} value={'/reports/deposits/annual-statements'} onClick={(_, url) => navigate(url)}>
                        {t('pages-portfolio-depositor.annualStatementsHeader')}
                    </ToggleButton>
                </ToggleButtonGroup>
                <Outlet/>
            </PageLayout>
        </>
    )
}
