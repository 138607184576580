import { Fragment, useState } from 'react';
import config from '#app/config'
import {useFieldState, useStableValidator } from '@fixrate/fieldstate'
import {Button} from '@mui/material'
import {useCommand} from '#command'
import {DateOutput, PageHeader, Paper} from '#components'
import {useSelector} from '#state/useSelector'
import {Table, TableCell, TableHeader, TableHeaderCell, TableRow} from '#components/Table'
import Modal from '#components/Modal'
import styles from './Reports.module.scss'
import { useTranslation } from 'react-i18next'

const months = [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember',
]

const API_BASE_URL = config().apiUrl

export default function Reports() {

    const { t } = useTranslation()
    const {createInvoiceReport, deleteInvoiceReport} = useCommand()
    const invoiceReports = useSelector(state => state.invoiceReports.slice()
        .sort((a, b) => {
            const number = b.period.localeCompare(a.period, undefined, { numeric: true })
            if (number === 0) {
                return (b.created?.getTime() ?? 0) - (a.created?.getTime() ?? 0)
            } else {
                return number
            }
        })
    )

    const yearValidator = useStableValidator<string>('NOT_EMPTY_ON_EDIT', t('pages-reports.yearMissing'))
    const year = useFieldState('', yearValidator)

    const monthValidator = useStableValidator<string>('NOT_EMPTY_ON_EDIT', t('pages-reports.monthMissing'))
    const month = useFieldState('', monthValidator)

    const [showDeleteModal, setShowDeleteModal] = useState('')

    async function submit() {
        if (year.validate() && month.validate()) {
            const {waitForCommand} = await createInvoiceReport(year.value, month.value)
            await waitForCommand()
        }
    }

    async function deleteInvoice(invoiceReportId) {
        const {waitForCommand} = await deleteInvoiceReport(invoiceReportId)
        const success = await waitForCommand()
        if (success) {
            setShowDeleteModal('')
        }
    }

    const invoiceReportRows = invoiceReports
        .map(invoiceReport => (
            <TableRow key={invoiceReport.id}>
                <TableCell>{DateOutput.formatDateTime(invoiceReport.created)}</TableCell>
                <TableCell>{invoiceReport.period}</TableCell>
                <TableCell>{DateOutput.formatDateTime(invoiceReport.calculated)}</TableCell>
                <TableCell><a href={`${API_BASE_URL}/document/report/INVOICE_POWEROFFICE_EXPORT?invoiceReportId=${invoiceReport.id}`}>Last ned</a></TableCell>
                <TableCell><Button variant={'outlined'} size={'small'} onClick={() => setShowDeleteModal(invoiceReport.id)}>{t('common.delete')}</Button></TableCell>
            </TableRow>
        ))

    const modal = showDeleteModal && (
        <Modal onCancel={() => setShowDeleteModal('')}
               width="40rem"
            header={t('pages-reports.deleteInvoice')}
               onSubmit={() => deleteInvoice(showDeleteModal)}>
            <p className={styles.body}>
                {t('pages-reports.deleteInvoiceConfirm')}
            </p>
        </Modal>
    )

    return (
        <Fragment>
            <PageHeader title={t('pages-reports.invoicePageHeader')} />
            <div className="layout__main-content">
                <Paper title={t('pages-reports.invoiceHeader')}>
                    <dl>
                        <dt>{t('pages-reports.yearLabel')}</dt>
                        <dd>
                            <input name="year"
                                   type="text"
                                   pattern="\d{4}"
                                placeholder={t('pages-reports.yearPlaceholder')}
                                   value={year.value}
                                   onBlur={year.onBlur}
                                   onChange={(e) => year.setValue(e.target.value)}
                            />
                            <p className="field-error-message">{year.errorMessage}</p>
                        </dd>
                    </dl>

                    <dl>
                        <dt>{t('pages-reports.monthLabel')}</dt>
                        <dd>
                            <select name="month"
                                    value={month.value}
                                    onBlur={month.onBlur}
                                    onChange={(e) => month.setValue(e.target.value)}
                            >
                                <option value="">{t('common.selectOption')}</option>
                                {months.map((month, index) => (
                                    <option key={month} value={`${index + 1}`}>{month}</option>
                                ))}
                            </select>
                            <p className="field-error-message">{month.errorMessage}</p>
                        </dd>
                    </dl>

                    <Button onClick={submit}>
                        {t('pages-reports.createReport')}
                    </Button>

                </Paper>

                <Paper title={t('pages-reports.reportsHeader')}>
                    <p>
                        {t('pages-reports.reportsInfo')}
                    </p>
                    <Table>
                        <TableHeader>
                            <TableHeaderCell>{t('pages-reports.tableHeaderCreated')}</TableHeaderCell>
                            <TableHeaderCell>{t('pages-reports.tableHeaderPeriod')}</TableHeaderCell>
                            <TableHeaderCell>{t('pages-reports.tableHeaderProduced')}</TableHeaderCell>
                            <TableHeaderCell>{t('pages-reports.tableHeaderReport')}</TableHeaderCell>
                        </TableHeader>
                        {invoiceReportRows}
                    </Table>
                </Paper>
            </div>
            {modal}
        </Fragment>
    );
}
