import { useCommand } from '#command'
import { LoadingSpinner, PageHeader, PageLayout, Paper } from '#components'
import FundTransactionsImportTable from '#pages/FundTransactions/FundTransactionsImportTable'
import { useSelector } from '#state/useSelector'
import {
    FundDto, FundPlacementTransactionType,
    FundPortfolioTransactionDto,
    FundPortfolioTransactionTypeDto,
    FundShareClassDto
} from '@fixrate/fixrate-query'
import {Button} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

export type ImportableTransaction = FundPortfolioTransactionDto & {
    index: number
    selected: boolean
    originalType: SelectableTransactionType
    mappedType: SelectableTransactionType
}

export type SelectableTransactionType = FundPlacementTransactionType | "UNKNOWN"

type Params = {
    depositorId?: string
    portfolioId?: string
}

type QueryState = 'NOT_STARTED' | 'LOADING' | 'LOADED' | 'ERROR'

export default function FundTransactionsImportCustomer() {
    const {t} = useTranslation()
    const {depositorId, portfolioId} = useParams<Params>()
    const navigate = useNavigate()
    const {importFundTransactions} = useCommand();

    const [importableTransactions, setImportableTransactions] = useState<ImportableTransaction[]>([])
    const [queryState, setQueryState] = useState<QueryState>('NOT_STARTED')
    const customer = useSelector(state => state.fundCustomers.find(c => c.depositorId === depositorId))
    const funds = useSelector(state => state.funds)

    if (!customer) {
        return null
    }

    function mapTransactionType(type: FundPortfolioTransactionTypeDto): SelectableTransactionType {
        if (type === 'BUY') {
            return 'BUY'
        }
        if (type === 'SELL') {
            return 'SELL'
        }
        if (type === 'DIVIDEND') {
            return 'DIVIDEND'
        }
        if (type === 'ADD') {
            return 'UNKNOWN'
        }
    }

    const fetchTransactions = async () => {
        setQueryState('LOADING')
        try {
            const response = await fetch(`/api/fund/fund-portfolio-transactions/${depositorId}/${portfolioId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
            }).then(res => {
                if (!res.ok) {
                    setQueryState('ERROR')
                }
                return res
            })

            const faTransactions = (await response.json() as FundPortfolioTransactionDto[])
            const transactionsForImport: ImportableTransaction[] = faTransactions.map((t, index) => ({
                ...t,
                index: index,
                selected: false,
                originalType: mapTransactionType(t.type),
                mappedType: mapTransactionType(t.type)
            }))
            setImportableTransactions(transactionsForImport)
            setQueryState('LOADED')
        } catch (e) {
            console.error(e)
            setQueryState('ERROR')
        }
    }

    const fundShareClassesByIsin = funds.flatMap(f => f.fundShareClasses).reduce((acc, fsc) => {
        acc[fsc.isin] = fsc
        return acc
    }, {} as {[isinCode: string]: FundShareClassDto})
    const fundsByIsin = funds.flatMap(f => f.fundShareClasses).reduce((acc, fsc) => {
        acc[fsc.isin] = funds.find(f => f.id === fsc.fundId)
        return acc
    }, {} as {[isinCode: string]: FundDto})

    const uniqueIsinCodes = Array.from(new Set<string>(importableTransactions.map(t => t.isinCode)))

    const handleImport = async () => {
        if (selectedTransactions.length > 0 && depositorId && portfolioId && canImportSelected()) {
            const {waitForCommand} = await importFundTransactions(depositorId, portfolioId, selectedTransactions
                .map(t => ({
                    fundShareClassId: fundShareClassesByIsin[t.isinCode].id,
                    transactionDate: t.transactionDate,
                    settlementDate: t.settlementDate,
                    unitPrice: t.unitPrice,
                    unitQuantity: t.unitQuantity,
                    amount: t.amount,
                    roundingError: t.roundingError,
                    type: t.mappedType as FundPlacementTransactionType,
                })))
            const success = await waitForCommand()
            if (success) {
                navigate(`/customer/${customer.depositorId}`)
            }

            navigate('/fund-transactions')
        }
    }

    function canImportSelected() {
        return selectedTransactions.every(t => t.mappedType !== 'UNKNOWN')
    }

    const canImport = importableTransactions.some(t => t.selected)
    const selectedTransactions = importableTransactions.filter(t => t.selected)

    return (
        <>
            <PageHeader title={t('pages-fundTransactionsImport.header')} backToLink={'/fund-transactions/import'}/>
            <PageLayout>
                <Paper sx={{width: "100%"}} title={`${customer?.name} - ${t('pages-fundTransactionsImport.cid')}:${customer?.portfolios
                    .find(p => p.id === portfolioId)?.fundData.cid}`}>
                <Button variant={'contained'} onClick={fetchTransactions}>
                    {t('pages-fundTransactionsImport.fetchFromFA')}
                </Button>

                {queryState === 'ERROR' && <p>{t('pages-fundTransactionsImport.failedToFetchFromFA')}</p>}

                { queryState === 'LOADING' && <LoadingSpinner />}

                {queryState === 'LOADED' && (
                    <>
                        {importableTransactions.length === 0 && (
                            <p>{t('pages-fundTransactionsImport.noTransactionsFound')}</p>
                        )}

                        {uniqueIsinCodes.map(isinCode => {
                            function updateTransactions(updatedTransactions: ImportableTransaction[]) {
                                const nextTransactions = importableTransactions.map(t => {
                                    if (t.isinCode === isinCode) {
                                        return updatedTransactions.find(ut => ut.index === t.index) || t
                                    }
                                    return t
                                })
                                setImportableTransactions(nextTransactions)
                            }
                            const shareClass = fundShareClassesByIsin[isinCode]
                            const fund = fundsByIsin[isinCode]
                            const decimalPrecision = fund?.decimalPrecision ?? 4
                            const transactionsForShareClass = importableTransactions.filter(t => t.isinCode === isinCode)
                            return transactionsForShareClass && (
                                <FundTransactionsImportTable key={isinCode}
                                                             transactions={transactionsForShareClass}
                                                             updateTransactions={updateTransactions}
                                                             isinCode={isinCode}
                                                             shareClass={shareClass}
                                                             decimalPrecision={decimalPrecision}
                                />
                            )
                        })}

                        <p>{t('pages-fundTransactionsImport.numberOfSelectedTransactions', {count: selectedTransactions.length})}</p>
                        <Button variant="contained" color="primary" onClick={handleImport} disabled={!canImport}>
                            {t('pages-fundTransactionsImport.importButtonLabel')}
                        </Button>
                    </>
                )}
                </Paper>
            </PageLayout>
        </>
    )
}
