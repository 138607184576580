import { PURPLE, SUNSET_ORANGE } from '#app/colors/colors'
import { LineChart } from '#app/components/Charts/LineChart/LineChart'
import ProposalCards from '#app/pages/PartnerProposals/components/ProposalCards'
import { switchOrganisation } from '#app/services/thunks/session'
import { getDepositInterestForDepositorWithSsb } from '#app/services/thunks/statistics'
import { StaggData } from '#app/state/stagg'
import { LoadingSpinner, PageHeader, PageLayout, Paper } from '#components'
import CustomerDetailAdvisors from '#pages/Customers/CustomerDetail/CustomerDetailAdvisors'
import CustomerDetailEndPartnership from '#pages/Customers/CustomerDetail/CustomerDetailEndPartnership'
import CustomerDetailInfo from '#pages/Customers/CustomerDetail/CustomerDetailInfo'
import CustomerDetailInvite from '#pages/Customers/CustomerDetail/CustomerDetailInvite'
import CustomerDetailInviteResend from '#pages/Customers/CustomerDetail/CustomerDetailInviteResend'
import CustomerDetailPartnership from '#pages/Customers/CustomerDetail/CustomerDetailPartnership'
import CustomerDetailRemoveAdvisor from '#pages/Customers/CustomerDetail/CustomerDetailRemoveAdvisor'
import CustomerDetailSelectAdvisor from '#pages/Customers/CustomerDetail/CustomerDetailSelectAdvisor'
import CustomerDetailSetPrimaryAdvisor from '#pages/Customers/CustomerDetail/CustomerDetailSetPrimaryAdvisor'
import useOrganizationWarnings from "#services/useOrganizationWarnings"
import { useSelector } from '#state/useSelector'
import { DepositorWarning, DepositorWarningType } from '@fixrate/fixrate-query'
import { Alert, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import { eachYearOfInterval, format, isSameYear, sub } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { isFinishedWithOnboarding } from '../CustomerOverview/CustomerOverview'
import { CustomerDetailRegistrationStatus } from './CustomerDetailRegistrationStatus/CustomerDetailRegistrationStatus'
import StatusCard from './StatusCard'

type Params = {
    depositorId?: string
    modal: 'add' | 'set-primary' | 'remove' | 'resend-invite' | 'end-partnership'
}

type WarningMapType = {
    [key in DepositorWarningType]: string | null;
}

export default function CustomerDetail() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams<Params>()
    const associations = useSelector(state => state.session.associations)

    const DepositorWarningMessage = (warning: DepositorWarningType, count: number) : string | null => {
        const warningMap: WarningMapType = {
            'MISSING_SECURITY_ROLES': t('pages-customers.warningMissingSecurityRoles'),
            'MISSING_ORGANISATIONAL_ROLES': t('pages-customers.warningMissingOrganisationalRoles'),
            'MISSING_OWNERS_DECLARATION': t('pages-customers.warningMissingOwnersDeclaration'),
            'CUSTOMER_DECLARATION_PERSON_GALLERY_CHANGED': t('pages-customers.warningCustomerDeclarationPersonGalleryChanged'),
            'MISSING_CUSTOMER_DECLARATION': t('pages-customers.warningMissingCustomerDeclaration'),
            'MISSING_SETTLEMENT_ACCOUNT': t('pages-customers.warningMissingSettlementAccount'),
            'TERMS_NOT_ACCEPTED': t('pages-customers.warningTermsNotAccepted'),
            'NEED_INFO_FOR_CUSTOMER_DECLARATION': t('pages-customers.warningNeedInfoForCustomerDeclaration'),
            'AUTHORIZATION_DOCUMENT_NOT_SIGNED': t('pages-customers.warningAuthorizationDocumentNotSigned'),
            'AUTHORIZATION_DOCUMENT_MISSING': t('pages-customers.warningAuthorizationDocumentMissing'),
            'PERSON_NEEDS_INVITE': t('pages-customers.warningPersonNeedsInvite'),
            'PERSON_NEEDS_INFO_FOR_INVITE': t('pages-customers.warningPersonNeedsInfoForInvite'),
            'AUTHORIZATION_SIGNER_NEEDS_INFO_FOR_INVITE': t('pages-customers.warningAuthorizationSignerNeedsInfoForInvite'),
            'INVITE_HAS_EXPIRED': t('pages-customers.warningInviteHasExpired'),
            'INVITE_IS_NOT_SENT': t('pages-customers.warningInviteIsNotSent'),
            'MISSING_AUTHORIZATION_DOCUMENT': t('pages-customers.warningMissingAuthorizationDocument'),
            'MISSING_IDENTIFICATION_DOCUMENT': t('pages-customers.warningMissingIdentificationDocument'),
            'AUTHORIZATION_SIGNER_NEEDS_INVITE': t('pages-customers.warningAuthorizationSignerNeedsInvite'),
            'IMPORTED_PEOPLE_CHANGES_NOT_ACKNOWLEDGED': null,
            'MISSING_AUTHORIZATION_SIGNERS': null,
            'MISSING_BENEFICIAL_OWNERS': null,
        }
        if (!warningMap[warning]) {
            return null
        }

        return count > 1 ? warningMap[warning] + " (" + count + ")" : warningMap[warning]
    }

    const partner = useSelector(state => state.partner)
    const customer = partner?.customers.find(c => c.depositorId === params.depositorId)
    const warnings = useOrganizationWarnings(customer)

    const { data: SSBdata, isLoading: loadingSSB, isError: errorSSB } = useQuery({
        queryFn: async () => await dispatch(getDepositInterestForDepositorWithSsb(customer?.depositorId, "ALL")),
        queryKey: ['depositInterestSSB', customer?.depositorId],
        placeholderData: null,
        onSuccess: (data: StaggData) => {
            return data
        },
        onError: (error) => {
            console.error(error)
        }
    })

    if (!customer) {
        return null
    }

    const invites = customer.userInvites.map(userInvite => <CustomerDetailInvite key={userInvite.id} userInvite={userInvite} depositorId={customer.depositorId}/>)
    const finishedWithOnboarding = isFinishedWithOnboarding(customer)
    const isAccountant = partner.partnerCategory === "ACCOUNTANT"
    const canAccessCustomerAccount = associations?.find(a => a.organisation?.id === customer.depositorId) ? true : false

    function onSelectRole(navigateAfterSwitch: () => void) {
        dispatch(switchOrganisation('DEPOSITOR', customer.depositorId, navigateAfterSwitch))
    }

    const getWarningMessages = (warnings: DepositorWarning[]) => {
        return [...new Set(warnings.map(warning => DepositorWarningMessage(warning.type, warnings.filter(w => w.type === warning.type).length))
            .filter(message => message !== null))]
    }

    const termsWarnings = getWarningMessages(warnings.terms)
    const settlementAccountWarnings = getWarningMessages(warnings.accounts)
    const customerDeclarationWarnings = getWarningMessages(warnings.customerDeclaration)
    const organisationalRolesWarnings = getWarningMessages(warnings.organisationalRoles)
    const usersWarnings = getWarningMessages(warnings.users)
    const pendingPartnerProposals = customer?.partnerRelations?.find(pr => pr.partnerId === partner.id)?.partnerProposals?.filter(pp => pp.status === 'PENDING') || []
    const hasPendingPartnerProposals = pendingPartnerProposals.length > 0

    const getReportYearArray = () => {
        if (customer.fullyRegisteredAt) {
            if (new Date(customer.fullyRegisteredAt) > sub(new Date(format(new Date(), "yyyy-12-30")), {years: 1})) {
                return []
            }
            if (isSameYear(sub(new Date(), {years: 1}), new Date(customer.fullyRegisteredAt))) {
                return [new Date(customer.fullyRegisteredAt).getFullYear()]
            }

            return eachYearOfInterval({
                start: new Date(customer.fullyRegisteredAt),
                end: isSameYear(sub(new Date(), {years: 1}), new Date(customer.fullyRegisteredAt)) ? new Date() : sub(new Date(), {years: 1})
            }).map(yearDate => yearDate.getFullYear())
        }
        return []
    }

    return (
        <>
            <PageHeader icon="ri-building-line" title={customer.name} backToLink={'/customer'}/>
            <PageLayout>
                <Stack spacing={3} maxWidth={'120rem'} px={{xs: 1, md: 0}}>
                    <CustomerDetailInfo/>
                    { (canAccessCustomerAccount || partner.partnerCategory === "SUPPORT") && (
                        <>
                            { ((finishedWithOnboarding && !hasPendingPartnerProposals) || !isAccountant) && (
                                <Grid container spacing={2} justifyContent={'space-between'} direction={'row'} flexWrap={'wrap'}>
                                    <Grid item xs={12} md={6}>
                                        <StatusCard
                                            title={t('pages-customers.contractWithFixrate')}
                                            description={t('pages-customers.agreementDescription')}
                                            icon={'handshakeFill'}
                                            buttonText={t('pages-customers.goToAgreements')}
                                            onClick={() => onSelectRole(() => navigate(`/organizations/${customer.depositorId}/terms`))}
                                            warnings={termsWarnings}
                                            disabled={!canAccessCustomerAccount}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <StatusCard
                                            title={t('pages-customers.settlementAccount')}
                                            description={t('pages-customers.settlementAccountDescription')}
                                            icon={'bankFill'}
                                            buttonText={t('pages-customers.goToSettlementAccount')}
                                            onClick={() => onSelectRole(() => navigate(`/organizations/${customer.depositorId}/accounts`))}
                                            warnings={settlementAccountWarnings}
                                            disabled={!canAccessCustomerAccount}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <StatusCard
                                            title={t('pages-customers.usersAndRoles')}
                                            description={t('pages-customers.usersAndRolesDescription')}
                                            icon={'roleGroupFill'}
                                            buttonText={t('pages-customers.goToUsersAndRoles')}
                                            onClick={() => onSelectRole(() => navigate(`/organizations/${customer.depositorId}/users/all`))}
                                            warnings={[...usersWarnings, ...organisationalRolesWarnings]}
                                            disabled={!canAccessCustomerAccount}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <StatusCard
                                            title={t('pages-customers.customerDeclaration')}
                                            description={t('pages-customers.customerDeclarationDescription')}
                                            icon={'secureDocumentFill'}
                                            buttonText={t('pages-customers.goToCustomerDeclaration')}
                                            onClick={() => onSelectRole(() => navigate(`/organizations/${customer.depositorId}/customerdecl`))}
                                            warnings={customerDeclarationWarnings.filter((item,
                                                index) => customerDeclarationWarnings.indexOf(item) === index)}
                                            disabled={!canAccessCustomerAccount}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            { (!finishedWithOnboarding && isAccountant) && (
                                <CustomerDetailRegistrationStatus customer={customer}/>
                            )}
                        </>
                    )}
                    { (hasPendingPartnerProposals) && (
                        <Stack my={1} spacing={2}>
                            <Typography variant="h3">
                                <i className="ri-mail-send-line"/>
                                <span>{t('pages-customers.sentProposals')}</span>
                            </Typography>
                            <ProposalCards proposals={pendingPartnerProposals}/>
                        </Stack>
                    )}
                    { canAccessCustomerAccount && finishedWithOnboarding && (
                        <Paper sx={{width: "100%", mb: 0}} title="Renteutvikling">
                            { loadingSSB && <LoadingSpinner /> }
                            { (SSBdata && !loadingSSB) && (
                                <Stack>
                                    <LineChart staggData={SSBdata} exludeNames={["fixrateInterest"]} colors={[SUNSET_ORANGE[500],PURPLE[500]]} />
                                    <Typography component={"p"} variant="caption" mt={2}>
                                        {t('pages-analytics.graphExplanation')}
                                    </Typography>
                                </Stack>
                            )}
                            { errorSSB && <Alert severity="error">{t('pages-customers.errorLoadingSSBData')}</Alert> }
                        </Paper>
                    )}
                    { canAccessCustomerAccount && customer.fullyRegisteredAt && getReportYearArray().length > 0 && (
                        <Paper sx={{width: "100%", mb: 0}} title={t('pages-customers.addedValueReport')}>
                            <List sx={{maxWidth: "50rem", my: 3, p: 0, border: "0.1rem solid", borderColor: PURPLE[50], borderRadius: "0.8rem"}}>
                                { getReportYearArray().map(year => (
                                    <ListItemButton
                                        key={year}
                                        color="primary"
                                        sx={{py: 1.5, pr: 1}}
                                        onClick={() => navigate(`/customer/${customer.depositorId}/value-report?year=${year}`)}>
                                        <ListItemIcon color="primary" sx={{backgroundColor: PURPLE[50]}}>
                                            <i className="ri-pie-chart-2-fill purple"/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            {year}
                                        </ListItemText>
                                        <ListItemIcon sx={{backgroundColor: "transparent"}}>
                                            <i className="ri-arrow-right-line purple"/>
                                        </ListItemIcon>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Paper>
                    )}

                    <CustomerDetailAdvisors/>
                    {(partner.partnerCategory === 'ACCOUNTANT' || partner.partnerCategory === 'ASSET_MANAGER') &&  <CustomerDetailPartnership/>}
                    {invites}
                </Stack>
            </PageLayout>
            {{
                'add': <CustomerDetailSelectAdvisor/>,
                'set-primary': <CustomerDetailSetPrimaryAdvisor/>,
                'remove': <CustomerDetailRemoveAdvisor/>,
                'resend-invite': <CustomerDetailInviteResend/>,
                'end-partnership': <CustomerDetailEndPartnership/>,
            }[params.modal]}
        </>
    )
}
