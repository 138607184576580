import { fundDetailsLink } from '#app/services/fundDetailsLink'
import { Animated, CurrencyOutput, InterestOutput } from '#components'
import { InterestBadge } from '#components/InterestBadge/InterestBadge'
import { FundLogo } from "#pages/FundDetails/FundLogo/FundLogo"
import FundTableHeaderCell from '#pages/FundMarketplace/FundTable/FundTableHeaderCell'
import useCurrentDepositor from "#services/useCurrentDepositor"
import useShoppingCart from '#services/useShoppingCart'
import useTableSort, { Column } from '#services/useTableSort'
import { DepositorDto, FundDto } from '@fixrate/fixrate-query'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from './FundTable.module.scss'
import ShareClassSelect from './TableComponents/ShareClassSelect'
import TableShoppingCartButton from './TableComponents/TableShoppingCartButton'
import useFundShareClassSelected from "#services/useFundShareClassSelected";

type Props = {
    funds: FundDto[]
    selectedShareClasses: { [fundId: string]: string }
}

type Row = FundDto & {
    logo: string
    shareClass: number
    returnRate1Y: number
    standardDeviation: number
    effectiveInterestRate: number
}

type RowProps = {
    row: Row
    depositor: DepositorDto
}

export default function RiskTable({funds, selectedShareClasses}: Props) {
    const {t} = useTranslation()
    const currentDepositor = useCurrentDepositor()

    const rows = funds.map((fund) => {
        const selectedFundShareClass = fund.fundShareClasses.find(fundClass => fundClass.id === selectedShareClasses[fund.id]) || fund.fundShareClasses[0]
        return {
            ...fund,
            logo: '',
            shareClass: selectedFundShareClass?.minimumInvestment,
            returnRate1Y: selectedFundShareClass?.annualizedReturnRates?.PERIOD_1Y,
            standardDeviation: selectedFundShareClass?.standardDeviation,
            effectiveInterestRate: selectedFundShareClass?.effectiveInterestRate,
        }})

    const {sortedRows, sortDirection, sortKey, setSorting} = useTableSort<Row>(rows, 'risk', 'asc', 'returnRate1Y', 'desc')

    const columns: Column<Row>[] = [
        {id: 'logo', label: '', sortable: false },
        {id: 'name', label: t('pages-fund-details.name'), sortable: true},
        {id: 'shareClass', label: t('pages-fund-details.shareClassAndAmount'), sortable: true},
        {id: 'risk', label: t('pages-fund-details.risk'), sortable: true},
        {id: 'returnRate1Y', label: t('pages-fund-details.1y'), sortable: true},
        {id: 'interestRateSensitivity', label: t('pages-fund-details.interestRateSensitivity'), align: 'right', sortable: true},
        {id: 'creditSensitivity', label: t('pages-fund-details.creditSensitivity'), align: 'right', sortable: true},
        {id: 'effectiveInterestRate', label: t('pages-fund-details.effectiveInterestRate'), align: 'right', sortable: true},
        {id: 'standardDeviation', label: t('pages-fund-details.standardDeviation3Y'), align: 'right', sortable: true},
    ]

    return (
        <>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {columns.map(column => (
                            <FundTableHeaderCell<Row> key={column.id} column={column} sortKey={sortKey} sortDirection={sortDirection} onClick={(c) => setSorting(c.id)}/>
                        ))}
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    {sortedRows.map(row => <TableRow key={row.id} row={row} depositor={currentDepositor} />)}
                </tbody>
            </table>
        </>
    )
}

function TableRow({row, depositor}: RowProps) {
    const [fundClassId] = useFundShareClassSelected(row.id, row.fundShareClasses[0].id)
    const shoppingCart = useShoppingCart('FUNDS')
    const isInShoppingCart = !!shoppingCart.getItemById(row.id)

    const selectedFund = row.fundShareClasses.find(fund => fund.id === fundClassId)

    return (
        <Animated tag={'tr'} className={isInShoppingCart ? styles.active : null}>
            <td className={classNames(styles.logo, isInShoppingCart ? styles.highlight : null)}>
                <FundLogo fundName={selectedFund?.fullName} squared={true}/>
            </td>
            <td className={classNames(styles.name)}>
                <Link className={styles.fundLink} to={fundDetailsLink(row)}>{row.name}</Link>
            </td>
            <td>
                {row && <ShareClassSelect fund={row} depositor={depositor}/>}
            </td>
            <td>
                <div className={classNames(styles.risk)}>
                    <div className={styles.indicator}>
                        {[...new Array(7)].map((_, i) => <span key={i} className={i < row.risk ? styles.active : undefined}/>)}
                    </div>
                </div>
            </td>
            <td>
                <InterestBadge sx={{fontSize: 12}} interest={selectedFund?.returnRates?.PERIOD_1Y}/>
            </td>
            <td align={'center'}>
                <p className={styles.value}>{row.interestRateSensitivity == null ? '-' : CurrencyOutput.formatNoCode(row.interestRateSensitivity)}</p>
            </td>
            <td align={'center'}>
                <p className={styles.value}>{row.creditSensitivity == null ? '-' : CurrencyOutput.formatNoCode(row.creditSensitivity)}</p>
            </td>
            <td align={'right'}>
                <p className={styles.value}>{selectedFund.effectiveInterestRate == null ? '-' : InterestOutput.format(selectedFund.effectiveInterestRate)}</p>
            </td>
            <td align={'right'}>
                <p className={styles.value}>{selectedFund.standardDeviation == null ? '-' : InterestOutput.format(selectedFund.standardDeviation)}</p>
            </td>
            <TableShoppingCartButton fund={row}/>
        </Animated>
    )
}

