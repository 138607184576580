import { COLORS, SILVER_GRAY } from '#app/colors/colors'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { usePortfolioValues } from '#app/services/usePortfolioValues'
import LiquidityChart from '#pages/portfolio-depositor/PortfolioOverview/LiquidityChart'
import SplitChart from '#pages/portfolio-depositor/PortfolioOverview/SplitChart'
import {Alert, Box, Stack, Typography} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {useSelector} from "#state/useSelector";
import {fundPlacementsWithMissingDividendsFromLastYearSelector} from "#state/selectors";

export default function LiquidityOverview() {
    const {t} = useTranslation()
    const Currency = useCurrencyOutput()
    const portfolioValues = usePortfolioValues()
    const fundPlacementsWithMissingDividends = useSelector(fundPlacementsWithMissingDividendsFromLastYearSelector)

    return (
        <Stack spacing={3}>
            {(portfolioValues?.funds !== 0 && fundPlacementsWithMissingDividends.length > 0) && (
                <Alert severity="info" elevation={1} sx={{maxWidth: '80rem'}}>
                    {t('pages.portfolio.depositor.fund.newYearDividendWarning')}
                </Alert>
            )}
            <Stack direction={{xs: 'column', md: 'row'}} alignItems={{xs: 'flex-start', md: 'center'}} spacing={{xs: 2, md: 4}}>
                {(portfolioValues?.deposits !== 0 && portfolioValues?.funds !== 0) && (
                    <Box sx={{minWidth: {xs: '12rem', md: '18rem'}, maxWidth: {xs: '12rem', md: '18rem'}}}>
                        <SplitChart depositsValue={portfolioValues?.deposits} fundsValue={portfolioValues?.funds}/>
                    </Box>
                )}
                <Stack spacing={2}>
                    <Stack>
                        <Typography variant={'body1'} sx={{fontSize: '2.6rem', fontWeight: 600}}>{t('pages-portfolio-depositor.portfolioValue')}</Typography>
                        <Typography variant={'body1'} sx={{fontSize: '1.4rem', fontWeight: 500, color: SILVER_GRAY[300]}}>{t('pages-portfolio-depositor.updateFrequency')}</Typography>
                        <Typography
                            variant={'body1'}
                            sx={{
                                fontSize: {
                                    xs: '4rem',
                                    md: '5.0rem'
                                },
                                fontWeight: 700
                            }}>{Currency(portfolioValues?.portfolio, { decimals: 0 })}</Typography>
                    </Stack>
                    <Stack direction={{xs:'column', md: 'row'}} spacing={{xs: 2, md: 4}}>
                        <Stack>
                            <Typography variant={'body1'} sx={{fontSize: '2rem', fontWeight: 600, display: 'flex', alignItems: 'center'}}>
                                <Box component={'span'} sx={{display: 'inline-block', width: '0.8em', aspectRatio: '1/1', backgroundColor: COLORS.DEPOSIT, mr: 1}}/>
                                <Box component={'span'}>{t('pages-portfolio-depositor.deposits')}</Box>
                            </Typography>
                            <Typography variant={'body1'} sx={{fontSize: "2.6rem", fontWeight: 600}}>{Currency(portfolioValues?.deposits, { decimals: 0 })}</Typography>
                        </Stack>
                        <Stack>
                            <Typography variant={'body1'} sx={{fontSize: '2rem', fontWeight: 600, display: 'flex', alignItems: 'center'}}>
                                <Box component={'span'} sx={{display: 'inline-block', width: '0.8em', aspectRatio: '1/1', backgroundColor: COLORS.FUND, mr: 1}}/>
                                <Box component={'span'}>{t('pages-portfolio-depositor.funds')}</Box>
                            </Typography>
                            <Typography variant={'body1'} sx={{fontSize: "2.6rem", fontWeight: 600}}>{Currency(portfolioValues?.funds, { decimals: 0 })}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <LiquidityChart/>
        </Stack>
    )
}
