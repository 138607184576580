import { PURPLE, SUCCESS_GREEN } from '#app/colors/colors'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogActions from '#app/components/FxDialog/FxDialogActions'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import { PersonValidator } from '#app/services/PersonValidator'
import { useSelector } from '#app/state/useSelector'
import { DateOutput } from '#components'
import HelpIcon from '#components/HelpIcon/HelpIcon'
import Modal from '#components/Modal'
import { useAuthorization } from '#services/authorization'
import useCurrentDepositor from '#services/useCurrentDepositor'
import * as selectors from '#state/selectors'
import { Alert, Box, Divider, Stack, Tab, Tabs, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import config from '../../../config'
import AdCard from '../AdCard/AdCard'
import StartOrder from '../StartOrder/StartOrder'
import './AdCardDetails.scss'
import AdDetails from './AdDetails'
import BankDetails from './BankDetails'

type Props = {
    adId: string,
    isDepositorAndHasOrganisation: boolean,
    backUrl: string,
    open: boolean
}

const getBankLogoUrl = (bankId) => `${API_BASE_URL}/api/bank/${bankId}/image/logo`
const API_BASE_URL = config().apiUrl
const bankGuaranteeFundLinks = {
    'NO': 'https://www.bankenessikringsfond.no/',
    'SE': 'https://www.riksgalden.se/sv/var-verksamhet/insattningsgarantin-och-investerarskyddet/sa-fungerar-insattningsgarantin/'
}

export default function AdCardDetails({open, adId, isDepositorAndHasOrganisation, backUrl = '/marketplace'}: Props) {
    const {t} = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const ad = useSelector(state => state.ads.find(ad => ad.id === adId))
    const bank = useSelector(state => state.banks[ad?.bankId])
    const product = useSelector(state => state.products[ad?.productId])
    const session = useSelector(state => state.session)
    const auth = useAuthorization()
    const hasOrderPermission = auth.depositor.hasOrderRole || auth.depositor.hasPartnerAccountantRole
    const hasMadeDeposits = useSelector(state => state.deposits.length > 0)
    const depositor = useCurrentDepositor()
    const hasMadeOrders = useSelector(state => state.orders.length > 0)
    const documents = useSelector(state => state.documents)
    const identificationDocument = useSelector(selectors.identificationDocument)
    const depositorSettlementAccounts = useSelector(selectors.depositorSettlementAccounts)
    const singleUnmanagedNewDepositor = !!depositor && !hasMadeDeposits && depositor?.depositorSupportCategory !== 'MANAGED' && !hasMadeOrders
    const [imageError, setImageError] = useState(false)
    const [tabInfo, setTabInfo] = useState<'offer' | 'bank'>('offer')
    const bankGuaranteeFundLink = bankGuaranteeFundLinks[bank?.bankGuaranteeFund]
    const bankGuaranteeFundLabel: string | undefined = {
        'NO': t('pages-marketplace.bankGuaranteeNationalityFundNO'),
        'SE': t('pages-marketplace.bankGuaranteeNationalityFundSE')
    }[bank?.bankGuaranteeFund]
    const customerDeclaration = depositor?.customerDeclarationId && documents[depositor?.customerDeclarationId]
    const allPersonsAreReady = PersonValidator.isDepositorReadyForCustomerDeclaration(depositor)
    const warningIdentityDocument = !identificationDocument?.signedByAll
    const warningTerms = !depositor?.termsAccepted
    const warningCustomerDecl = !customerDeclaration || (customerDeclaration && !customerDeclaration?.signedByAny) || depositor?.customerDeclarationExpired || !depositor?.customerDeclarationId || !allPersonsAreReady
    const warningAccounts = depositorSettlementAccounts[depositor?.id]?.length === 0
    const hasWarnings = warningIdentityDocument || warningTerms || warningCustomerDecl || warningAccounts
    const customerRegistered = (hasWarnings && singleUnmanagedNewDepositor) ? false : true

    const [showOrderForm, setShowOrderForm] = useState(false)

    if (!ad) {
        return null
    }

    const ordered = !!ad.openOrders && ad.openOrders.length > 0
    const isFixed = product.type === 'FIXED'

    const startOrderButton = hasOrderPermission && (
        <Button
            data-cy="newOrderButton"
            variant="contained"
            onClick={() => setShowOrderForm(true)}
            disabled={!isDepositorAndHasOrganisation || !customerRegistered}
        >
            {t('pages-marketplace.startOrder')}
        </Button>
    )

    const registerButton = session.authenticated && (!isDepositorAndHasOrganisation || !customerRegistered) && (
        <Button variant="contained" data-cy="finish-registration-button" href="/organizations">
            {t('pages-marketplace.finishRegistration')}
        </Button>
    )

    const loginButton = !session.authenticated && (
        <Button variant="contained" href={`${location.pathname}?login`}>
            Logg inn
        </Button>
    )

    if (isDepositorAndHasOrganisation && showOrderForm) {
        return (
            <Modal
                cancel={window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'))}
                className={'ad-card-details__new-order'}
            >
                <StartOrder
                    id={ad.id} cancelOrder={() => setShowOrderForm(false)}
                    onCompleteUrl={window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'))}
                />
            </Modal>
        )
    } else {
        return (
            !location.search.includes('?login') && (
                <FxDialog transitionDuration={350} open={open} onClose={() => navigate(backUrl)}>
                    <FxDialogTitle sx={{ py: imageError ? null : 0.9 }} onClose={() => navigate(backUrl)}>
                        { !imageError ?
                        <img style={{maxWidth: '15rem'}} src={getBankLogoUrl(bank.id)} alt="logo" onError={(event) => setImageError(true)} /> :
                        <Typography variant={'h3'}>{bank.name}</Typography>
                    }
                    </FxDialogTitle>
                    <Stack sx={{ backgroundColor: PURPLE[25] }} p={{ xs: 1, md: 4 }} alignItems={'center'} justifyContent={'center'}>
                        <Box sx={{ transform: { xs: 'scale(0.8)', md: 'scale(1)' } }}>
                            <AdCard ad={ad} preview={true} product={product} onAdClick={null} />
                        </Box>
                    </Stack>
                    {isFixed && (
                        <Alert severity='info' sx={{ width: '60rem', margin: '0 auto', pl: 2.6 }}>
                            {t('pages-marketplace.paymentDate')}: {DateOutput.formatVerboseMonth(ad.termination)}
                        </Alert>
                    )}
                    <Divider />
                    {ad.interestRateConvention !== 'ACTUAL_ACTUAL' && (
                        <Alert severity='info' sx={{ fontSize: '1.2rem' }}>
                            {t('pages-marketplace.interestRateConventionDescription1') +
                                t('common.interestRateConvention.' + ad.interestRateConvention) +
                                '. ' +
                                t('pages-marketplace.interestRateConventionDescription2')}
                            {ad.interestRateConvention === 'ACTUAL_360' && t('pages-marketplace.interestRateConventionDescription3')}
                            <HelpIcon supportCommonName='interestRateConvention' inline={true} />
                        </Alert>
                    )}
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs sx={{ ml: 3, mr: 3 }} value={tabInfo} onChange={(e: React.SyntheticEvent, value: 'offer' | 'bank') => setTabInfo(value)}>
                            <Tab value='offer' label={t('pages-marketplace.aboutOffer')} />
                            <Tab value='bank' label={t('pages-marketplace.bankKeyNumbers')} />
                        </Tabs>
                    </Box>
                    <FxDialogContent>
                        {tabInfo === 'offer' && <AdDetails ad={ad} />}
                        {tabInfo === 'bank' && <BankDetails isDepositorAndHasOrganisation={isDepositorAndHasOrganisation} bank={bank} />}
                        <Stack sx={{paddingLeft: '1rem', borderRadius: '0.4rem', background: PURPLE[25]}} spacing={1} direction={'row'} alignItems={'center'} py={1}>
                            <i className='ri-shield-check-fill' style={{ fontSize: '2rem', color: SUCCESS_GREEN[500] }} />
                                <Typography fontWeight={'500'} fontSize={'1.4rem'}>
                                    {bankGuaranteeFundLabel === 'Svensk' ? t('pages-marketplace.bankGuaranteeInformationSE') : t('pages-marketplace.bankGuaranteeInformationNO')}
                                    <a href={bankGuaranteeFundLink} target="_blank" rel="noreferrer">
                                        {bankGuaranteeFundLabel === 'Svensk' ? t('pages-marketplace.bankGuaranteeInformationNameSE') : t('pages-marketplace.bankGuaranteeInformationNameNO')}
                                    </a>
                                </Typography>
                        </Stack>
                    </FxDialogContent>
                    {ordered && (
                        <Alert severity='warning' sx={{ px: 3 }}>
                            {t('pages-marketplace.orderedWarning')}
                        </Alert>
                    )}
                    <FxDialogActions>
                        {startOrderButton}
                        {registerButton}
                        {loginButton}
                        {session?.association?.organisationType === 'PARTNER' && (
                            <Button
                                variant='contained'
                                data-cy='proposeAdButton'
                                onClick={() => {
                                    navigate(location.pathname + "/send-proposal")
                                }}
                            >
                                {t('layers-sendProposal.suggestForCustomer')}
                            </Button>
                        )}
                    </FxDialogActions>
                </FxDialog>
            )
        )
    }
}
