import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { getIconText } from '#app/components/PageHeader/PageHeader'
import { switchOrganisation } from '#app/services/thunks/session'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import LabeledInfo from '#components/LabeledInfo/LabeledInfo'
import { formatOrganizationNumber } from '#services/formatnumber'
import { useSelector } from '#state/useSelector'
import { OrganisationType } from '@fixrate/fixrate-query'
import { Alert, Avatar, Box, Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './CustomerInfo.module.scss'
import { InterestBadge } from "#components/InterestBadge/InterestBadge";
import { isFinishedWithOnboarding } from '../CustomerOverview/CustomerOverview'
import { KeyFigureCard } from '#app/components/KeyFigureCard/KeyFigureCard'
import { CurrencyOutput, InterestOutput } from '#app/components'
import { SILVER_GRAY, SPRING_GREEN, SUNSET_ORANGE } from '#app/colors/colors'
import PhoneNumber from '#app/components/PhoneNumber'
import { parsePhoneNumber } from 'awesome-phonenumber'
import { useQuery } from 'react-query'
import { EarningsData } from '#app/pages/Analytics/dashboard/AnalyticsDashboard'
import { getEarningsForDepositor } from '#app/services/thunks/statistics'
import restEndpoint from '#services/rest/rest-endpoint'


type Params = {
    depositorId?: string
}

export default function CustomerDetailInfo() {
    const {t} = useTranslation()
    const params = useParams<Params>()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const Currency = useCurrencyOutput()
    const organisationCountry = useCurrentCountryCode()
    const partner = useSelector(state => state.partner)
    const session = useSelector(state => state.session)
    const customer = useSelector((state => state.partner?.customers.find(c => c.depositorId === params.depositorId)))
    const canAccessCustomerAccount = session.associations.find(a => a.organisation?.id === params.depositorId)
    const isFixrateSupport = session.associations.find(a => a.organisationType === 'FIXRATE')

    const finishedWithOnboarding = isFinishedWithOnboarding(customer)
    const hasPendingPartnerProposals = customer?.partnerRelations?.find(pr => pr.partnerId === partner.id)?.partnerProposals?.some(pp => pp.status === 'PENDING') || false

    const { data: earnings, isLoading: loadingEarnings, isError: errorEarnings } = useQuery({
        queryFn: async () => await dispatch(getEarningsForDepositor(customer?.depositorId, "ALL")),
        queryKey: ['depositorEarnings', customer?.depositorId],
        placeholderData: null,
        onSuccess: (data: EarningsData) => {
            return data
        },
        onError: (error) => {
            console.error(error)
        }
    })

    function selectRole(organisationType: OrganisationType, orgId: string) {
        dispatch(switchOrganisation(organisationType, orgId, () => navigate('/organizations')))
    }

    function openInClone(organisationType: OrganisationType, orgId: string) {
        restEndpoint(dispatch).cloneSession(organisationType, orgId).then((token) => {
            const url = 'https://' + window.location.host + '/?token=' + token.token;
            navigator.clipboard.writeText(url).then(() => {
                console.log('URL copied to clipboard');
            }).catch(err => {
                console.error('Failed to copy URL: ', err);
            });
        })
    }

    const renderPhoneNumber = (phoneNumber: string) => {
        return parsePhoneNumber(phoneNumber)?.number?.international || parsePhoneNumber(phoneNumber)?.number?.input
    }

    if (!customer) {
        return null
    }

    return (
        <Paper sx={{px: { xs: 2, md: 4 }, py: { xs: 3, md: 5 }}}>
            {customer.accepted == null && (
                <Alert icon={<i className="ri-mail-line"/>} severity="info" sx={{mb: 2}}>
                    {t('pages-customers.hasNotYetAccepted')}
                </Alert>
            )}
            {customer.accepted === false && (
                <Alert icon={<i className="ri-close-line"/>} severity="error" sx={{mb: 2}}>
                    {t('pages-customers.declinedRequest')}
                </Alert>
            )}
            <Stack spacing={3}>
                <Stack direction={{xs: 'column-reverse', md: 'row'}} spacing={2} justifyContent="space-between" alignItems="flex-start">
                    <Stack spacing={2} direction={'row'} alignItems="flex-start">
                        <Avatar sx={{width: '8rem', height: '8rem', fontSize: '2.6rem'}}>{getIconText(customer.name)}</Avatar>
                        <Stack direction={'column'} alignItems={'flex-start'}>
                            <h3 className={styles.customerName}>{customer.name}</h3>
                            <p className={styles.orgNumber}>
                                <i className="ri-building-line"/>
                                <span>{formatOrganizationNumber(customer.nationalIdentity, organisationCountry)}</span>
                            </p>
                        </Stack>
                    </Stack>
                    <Stack direction={{xs: 'row-reverse', md: 'column'}} spacing={2} alignItems="flex-end">
                        {customer.accepted && (
                            <Button data-cy="logInAsCustomerButton" disabled={!canAccessCustomerAccount} endIcon={<i className="ri-arrow-right-line"/>} variant="contained" size="small" onClick={() => selectRole('DEPOSITOR', customer.depositorId)}>
                                {t('pages-customers.logInAsCustomer')}
                            </Button>
                        )}
                        {customer.accepted && isFixrateSupport && (
                            <Button disabled={!canAccessCustomerAccount} endIcon={<i className="ri-arrow-right-line"/>} variant="contained" size="small" onClick={() => openInClone('DEPOSITOR', customer.depositorId)}>
                                {'Logg inn i annet vindu'}
                            </Button>
                        )}
                        { finishedWithOnboarding && (
                            (customer.warnings.length > 0 && !hasPendingPartnerProposals) ? (
                                <Alert
                                    severity={'error'}
                                    sx={{'& .MuiAlert-icon': {mr: "0.5rem", fontSize: "1.8rem"}, py: 0, px: 1}}>{t('pages-customers.numberOfWarnings', {count: customer.warnings.length})}</Alert>
                            ) : (
                                <Alert
                                    severity={'success'}
                                    sx={{'& .MuiAlert-icon': {mr: "0.5rem", fontSize: "1.8rem"}, py: 0, px: 1}}>{t('pages-customers.allGood')}</Alert>
                            )
                        )}
                    </Stack>
                </Stack>
                {customer.accepted && customer.portfolios.map(portfolio => {
                    const balance = portfolio.calculatedTotalBalance || portfolio.calculatedBalance
                    const interestRate = portfolio.calculatedTotalInterestRate || portfolio.calculatedInterestRate
                    const fundValue = portfolio.fundPlacements.map(placement => placement.currentValue).reduce((acc, value) => acc + value, 0)
                    return (
                    <Stack key={portfolio.portfolioId} direction={'column'}>
                        {customer.portfolios.length > 1 && <Typography variant={'h6'} pb={0}>{portfolio.currency}</Typography>}
                        <Grid container spacing={2}>
                            <Grid item lg={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <KeyFigureCard
                                            value={balance ? Currency(balance || 0, {decimals: 0, withCurrency: customer.portfolios.length === 1}) : t('pages-customers.noDeposits')}
                                            label={t('pages-customers.calculatedBalance')}
                                            remixIcon={'ri-bank-fill'} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <KeyFigureCard
                                            value={interestRate ? InterestOutput.format(interestRate || 0) : t('pages-customers.noDeposits')}
                                            label={t('pages-customers.averageInterestRate')}
                                            remixIcon={'ri-percent-fill'} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <KeyFigureCard
                                            value={Currency(fundValue, {decimals: 0, withCurrency: customer.portfolios.length === 1})}
                                            label={t('pages-customers.fund') + ' (' + t('pages-customers.numberOfPlacements') + " " + portfolio.fundPlacements.filter(fp => fp.unitQuantity > 0 ).length + ')'}
                                            remixIcon={'ri-bar-chart-fill'} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <KeyFigureCard
                                            value={earnings ? Currency(earnings?.earnings, {decimals: 0}) : "-"}
                                            disclaimer={t('pages-analytics.addedValueDescription')}
                                            label={t('pages-analytics.addedValueEstimated')}
                                            remixIcon={'ri-hand-coin-fill'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <KeyFigureCard
                                    value={customer.contactName || '-'}
                                    label={t('pages-customers.customerDetailInfoContactPerson')}
                                    remixIcon={'ri-user-fill'}
                                    sx={{height: "100%"}}
                                    color={SUNSET_ORANGE} >
                                    <Stack mt={2} spacing={2}>
                                        <Stack spacing={0.2}>
                                            <Stack direction={"row"} alignItems="center" spacing={0.6}>
                                                <i className="ri-mail-line"/>
                                                <Typography fontSize={"1.4rem"}>{t('pages-customers.customerDetailInfoEmailAddress')}</Typography>
                                            </Stack>
                                            <Typography fontWeight={600} fontSize="1.8rem">{customer.contactEmail || '-'}</Typography>
                                        </Stack>
                                        <Stack spacing={0.2}>
                                            <Stack direction={"row"} alignItems="center" spacing={0.6}>
                                                <i className="ri-phone-line"/>
                                                <Typography fontSize={"1.4rem"}>{t('pages-customers.customerDetailInfoPhone')}</Typography>
                                            </Stack>
                                            <Typography fontWeight={600} fontSize="1.8rem">{renderPhoneNumber(customer.contactPhone)}</Typography>
                                        </Stack>
                                    </Stack>
                                </KeyFigureCard>
                            </Grid>
                        </Grid>
                    </Stack>
                )})}
            </Stack>
        </Paper>
    )
}
