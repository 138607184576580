import { useEffect, useState } from 'react'
import styles from './DateInput.module.scss'
import classNames from 'classnames'
import {DateOutput} from '#components'
import {isValidDate, parseNorwegianDateFormat} from '#services/dateandtime'
import {useTranslation} from 'react-i18next'
import {PatternFormat} from 'react-number-format'
import { TextField } from '@mui/material'

type Props = {
    value?: Date | null
    onChange?: (value: Date | null) => void
    onBlur?: (e: React.FocusEvent<HTMLElement>) => void
    id?: string
    className?: string
    required?: boolean
    ['data-cy']?: string
    hideErrorMessage?: boolean
}

export default function DateInput({value, onChange, onBlur, id, className, hideErrorMessage, ...props}: Props) {
    const {t} = useTranslation()


    const [stringValue, setStringValue] = useState(value ? DateOutput.formatDate(value) : '')

    const [isEditing, setIsEditing] = useState(false)
    const valueAsString = value ? DateOutput.formatDate(value) : ''

    const dateOrNull = stringValue ? parseNorwegianDateFormat(stringValue) : null
    const isValid = dateOrNull === null || isValidDate(dateOrNull)

    return (
        <>
            <PatternFormat
                id={id}
                className={classNames(styles.input, className)}
                mask={'_'}
                format={'##.##.####'}
                type={'tel'}
                placeholder={'dd.MM.yyyy'}
                value={isEditing ? stringValue : valueAsString}
                required={props.required}
                customInput={TextField}
                onValueChange={values => {
                    const {formattedValue} = values
                    setStringValue(formattedValue)
                    setIsEditing(true)
                    const date = parseNorwegianDateFormat(formattedValue)
                    onChange?.(isValidDate(date) ? date : null)
                }}

                onBlur={e => {
                    setIsEditing(false)
                    onBlur?.(e)
                }}
                {...props}
            />
            {!hideErrorMessage && !isValid && !isEditing && (
                <p className="field-error-message">
                    {t('components-DateInput.invalidDate')}
                </p>
            )}
        </>
    )
}
