import { PURPLE } from "#app/colors/colors";
import { Button, ButtonProps } from "@mui/material";
import { Document, Font, Image, Page, pdf, StyleSheet, Text } from "@react-pdf/renderer";
import { saveAs } from 'file-saver';
import html2canvas from "html2canvas";
import { MutableRefObject, RefObject } from "react";
import { useTranslation } from "react-i18next";
type ReportImage = {
    type: 'image',
    src: string,
}

type ReportChart = {
    type: 'chart',
    ref: MutableRefObject<HTMLElement>,
}

type ReportText = {
    type: 'text' | 'subTitle' | 'title' | 'bigText',
    text: string,
}

type ReportType = ReportImage | ReportText | ReportChart
export type PDFReportPages= {
    content: ReportType[]
}[]

Font.register({ family: "Montserrat-Regular", src: "/fonts/Montserrat-Regular.ttf" });
Font.register({ family: "Montserrat-Medium", src: "/fonts/Montserrat-Medium.ttf" });
Font.register({ family: "Montserrat-Bold", src: "/fonts/Montserrat-Bold.ttf" });

const styles = StyleSheet.create({
    body: {
        paddingTop: 70,
        paddingBottom: 40,
        paddingHorizontal: 50,
        fontFamily: "Montserrat-Regular",
        color: PURPLE[900]
    },
    title: {
        fontSize: 24,
        marginBottom: 20,
        fontFamily: "Montserrat-Bold",
    },
    h2: {
        fontSize: 14,
        marginBottom: 6,
        marginTop: 10,
        fontFamily: "Montserrat-Bold",
    },
    text: {
        marginBottom: 6,
        fontSize: 10,
    },
    bigText: {
        fontSize: 26,
        fontFamily: "Montserrat-Bold",
        marginBottom: 6,
    },
    image: {
        marginTop: 5,
        marginBottom: 0
    },
});

const generateImageFromRef = async (ref: RefObject<HTMLElement>): Promise<string | null> => {
    if (ref.current === null) {
        return null;
    }

    try {
        const canvas = await html2canvas(ref.current);
        return canvas.toDataURL();
    } catch (error) {
        console.error("Error generating chart image:", error);
        return null;
    }
};

const downloadImageFromRef = (ref: RefObject<HTMLElement>, fileName: string) => {
    if (ref.current === null) {
        return null
    }
    const containerWidth = ref.current.clientWidth > 1200 ? 1200 : ref.current.clientWidth;
    html2canvas(
        ref.current,
        {
            width: containerWidth
        }
    ).then(canvas => {
        saveAs(canvas.toDataURL(), fileName)
    })
}


const DownloadPDFButton = ({pages, fileName, buttonProps} : {pages: PDFReportPages, fileName: string, buttonProps?: ButtonProps}) => {
    const {t} = useTranslation()
    const downloadPdf = async () => {
        const blob = await pdf(<PDFGenerator pages={pages} />).toBlob();
        saveAs(blob, fileName);
    }
  
    return (
        <Button
            onClick={downloadPdf}
            startIcon={<i className="ri-file-pdf-2-line" />}
            size="small"
            variant="outlined"
            {...buttonProps}>
            {t('common.downloadPdf')}
        </Button>
    )
}

const Logo = () => {
    return (
        <Image src="/images/fixrate-logo.png" style={{width: 80, marginLeft: "auto", position: "absolute", right: 50, top: 30}} />
    )
}

const PDFGenerator = ({pages} :  { pages: PDFReportPages }) => (
    <Document>
        { pages.map((p, index) => (
            <Page key={index} size={"A4"} style={styles.body}>
                <Logo />
                { p.content.map((element, index) => {
                    switch (element.type) {
                        case 'text':
                            return <Text key={index} style={styles.text}>{element.text}</Text>
                        case 'title':
                            return <Text key={index} style={styles.title}>{element.text}</Text>
                        case 'subTitle':
                            return <Text key={index} style={styles.h2}>{element.text}</Text>
                        case 'bigText':
                            return <Text key={index} style={styles.bigText}>{element.text}</Text>
                        case 'image':
                            return <Image key={index} style={styles.image} src={element.src} />
                        case 'chart':
                            return <Image key={index} style={styles.image} src={generateImageFromRef(element.ref)} />
                    }
                })}
            </Page>
        ))}
    </Document>
)

export { PDFGenerator, DownloadPDFButton, generateImageFromRef, downloadImageFromRef };

