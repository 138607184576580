import { useCommand } from '#command'
import { Button, ButtonRow, LoadingSpinner } from '#components'
import { useTranslation } from 'react-i18next'
import Modal from '#components/Modal'
import ToggleSwitch from '#components/ToggleSwitch'
import { messageTypeTranslationKeyMap, securityRoleTMap, taskTypeTranslationKeyMap } from '#services/enumTranslationKeyMapping'
import { hasViewDepositorRole } from '#state/selectors'
import { useSelector } from '#state/useSelector'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { TFunction } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './EmailSettings.module.scss'

interface EmailCategoryProps {
    title: string
    description: string
    notificationType: string
    settings: {[key: string]: boolean}
    onChange: (notificationType: string, value: boolean) => void
    inverse?: boolean
}

interface BankNotification {
    securityRole: string;
    taskTypes: string[];
    messageTypes: string[];
}

type BankNotificationsData = BankNotification[];

function Notifications({ t, role, names, bankNotificationsData }: Readonly<{ t: TFunction, role: string, names: string[], bankNotificationsData: BankNotificationsData }>) {
    const [dropDownInformation, setDropDownInformation] = useState(false)
    const [showAll, setShowAll] = useState(false)
    const roleName = t(securityRoleTMap[role])
    const nameElements = names.map((name, index) => <span key={index} className={styles.orgName}>{name}</span>)
    const limitOrgs = 5

    let roleList
    switch (role) {
        case 'BANK_CUSTOMER_SERVICE': {
            if (!bankNotificationsData) {
                return <LoadingSpinner />
            }
            const bankNotifications = bankNotificationsData?.find(data => data.securityRole === 'BANK_CUSTOMER_SERVICE')

            if (bankNotifications) {
                const taskTypesList = bankNotifications.taskTypes.map((taskType) => (
                    <li key={taskType}>{t(taskTypeTranslationKeyMap[taskType])}</li>
                ))

                const messageTypesList = bankNotifications.messageTypes.map((messageType) => (
                    <li key={messageType}>{t(messageTypeTranslationKeyMap[messageType])}</li>
                ))

                roleList = (
                    <ul>
                        {taskTypesList}
                        {messageTypesList}
                    </ul>
                )
            } else {
                roleList = <p></p>
            }
            break
        }
        case 'BANK_AD_MANAGER':
            {
                if (!bankNotificationsData) {
                    return <LoadingSpinner />
                }
                const bankNotifications = bankNotificationsData?.find(data => data.securityRole === 'BANK_AD_MANAGER')

                if (bankNotifications) {
                    const taskTypesList = bankNotifications.taskTypes.map((taskType) => (
                        <li key={taskType}>{t(taskTypeTranslationKeyMap[taskType])}</li>
                    ))

                    const messageTypesList = bankNotifications.messageTypes.map((messageType) => (
                        <li key={messageType}>{t(messageTypeTranslationKeyMap[messageType])}</li>
                    ))

                    roleList = (
                        <ul>
                            {taskTypesList}
                            {messageTypesList}
                        </ul>
                    )
                } else {
                    roleList = <p></p>
                }
                break
            }
        case 'BANK_ADMIN':
            {
                if (!bankNotificationsData) {
                    return <LoadingSpinner />
                }
                const bankNotifications = bankNotificationsData?.find(data => data.securityRole === 'BANK_ADMIN')

                if (bankNotifications) {
                    const taskTypesList = bankNotifications.taskTypes.map((taskType) => (
                        <li key={taskType}>{t(taskTypeTranslationKeyMap[taskType])}</li>
                    ))

                    const messageTypesList = bankNotifications.messageTypes.map((messageType) => (
                        <li key={messageType}>{t(messageTypeTranslationKeyMap[messageType])}</li>
                    ))

                    roleList = (
                        <ul>
                            {taskTypesList}
                            {messageTypesList}
                        </ul>
                    )
                } else {
                    roleList = <p></p>
                }
                break
            }
        case 'BANK_COORDINATOR':
            {
                if (!bankNotificationsData) {
                    return <LoadingSpinner />
                }
                const bankNotifications = bankNotificationsData?.find(data => data.securityRole === 'BANK_COORDINATOR')

                if (bankNotifications) {
                    const taskTypesList = bankNotifications.taskTypes.map((taskType) => (
                        <li key={taskType}>{t(taskTypeTranslationKeyMap[taskType])}</li>
                    ))

                    const messageTypesList = bankNotifications.messageTypes.map((messageType) => (
                        <li key={messageType}>{t(messageTypeTranslationKeyMap[messageType])}</li>
                    ))

                    roleList = (
                        <ul>
                            {taskTypesList}
                            {messageTypesList}
                        </ul>
                    )
                } else {
                    roleList = <p></p>
                }
                break
            }
        case 'DEPOSITOR_ORDER':
            roleList = (
                <ul>
                    <li>{t('pages-inbox.notificationBecause10')}</li>
                </ul>
            )
            break
        case 'DEPOSITOR_ACCOUNT_HOLDER':
            roleList = (
                <ul>
                    <li>{t('pages-inbox.notificationBecause15')}</li>
                </ul>
            )
            break
        case 'DEPOSITOR_PAYMENT':
            roleList = (
                <ul>
                    <li>{t('pages-inbox.notificationBecause19')}</li>
                </ul>
            )
            break
        case 'DEPOSITOR_ADMIN':
            roleList = (
                <ul>
                    <li>{t('pages-inbox.notificationBecause18')}</li>
                </ul>
            )
            break
        case 'DEPOSITOR_VIEW':
            roleList = (
                <ul>
                    <li>{t('pages-inbox.notificationBecause16')}</li>
                    <li>{t('pages-inbox.notificationBecause17')}</li>
                </ul>
            )
            break

    }
    if (!roleList) {
        return null
    } else {
        return (
            <div className={classNames(styles.notificationList, dropDownInformation ? styles.open : '')}>
                <div className={styles.accordionHeader} onClick={() => setDropDownInformation(!dropDownInformation)}>
                    <h4>{t('pages-inbox.notificationListHeader')}<b>{roleName}</b></h4>
                    <i className={dropDownInformation ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'}/>
                </div>
                {dropDownInformation && (
                    <>
                        <div className={styles.organisationInfo}>
                            <h5>{t('pages-inbox.notificationRoleInfo', {role: roleName})}</h5>
                            <div className={styles.organisations}>
                                {showAll ? nameElements : nameElements.slice(0, limitOrgs)}
                            </div>
                            {nameElements.length > limitOrgs && (
                                <Button variant={'secondary'} onClick={() => setShowAll(!showAll)} className={styles.showAllBtn}>
                                    {showAll ? t('pages-inbox.showLess') : t('pages-inbox.showAll')}
                                </Button>
                            )}                        </div>
                        <div className={styles.roleList}>
                            <h5>{t('pages-inbox.notifications')}</h5>
                            {roleList}
                        </div>
                    </>
                )}
            </div>
        );
    }
}

function UserNotifications({ t }: { t: TFunction }) {
    const hasViewRole = useSelector(hasViewDepositorRole)
    const dropDownInformationDefaultSetting = !hasViewRole
    const [dropDownInformation, setDropDownInformation] = useState(dropDownInformationDefaultSetting)
    return (
        <div className={classNames(styles.notificationList, dropDownInformation ? styles.open : '')}>
            <div className={styles.accordionHeader} onClick={() => setDropDownInformation(!dropDownInformation)}>
                <h4>{t('pages-inbox.notificationUserHeader')}</h4>
                <i className={dropDownInformation ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'}/>
            </div>
            {dropDownInformation && (
                <>
                    <div className={styles.roleList}>
                        <h5>{t('pages-inbox.notifications')}</h5>
                        <ul>
                            <li>{t('pages-inbox.notificationBecause23')}</li>
                            <li>{t('pages-inbox.notificationBecause24')}</li>
                            <li>{t('pages-inbox.notificationBecause25')}</li>
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
}

function EmailCategory({title, description, notificationType, settings, onChange, inverse}: EmailCategoryProps) {
    const value = inverse ? !settings[notificationType] : settings[notificationType]
    return (
        <div className={styles.emailCategory}>
            <div className={styles.emailCategoryLabel}>
                <div className={styles.emailCategoryTitle}>{title}</div>
                <div className={styles.emailCategoryDescription}>{description}</div>
            </div>
            <ToggleSwitch checked={value} onChange={() => onChange(notificationType, !value)}/>
        </div>
    )
}


export default function EmailSettings() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const settings = useSelector(state => state.notificationSettings?.settings)
    const newNotificationSettings = useSelector(state => state.notificationSettings && !state.notificationSettings.legacySettings)
    const {setNotificationSetting, setUserLegacyNotifications} = useCommand()
    const associations = useSelector(state => state.session.associations)
    const hasViewRole = useSelector(hasViewDepositorRole)

    const [bankNotificationsData, setBankNotificationsData] = useState(null)

    const getBankNotificationsOverview = async () => {
        if (bankNotificationsData) {
            return;
        }
        try {
            const response = await fetch(`/api/bank/notifications/securityRoleMappings`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const bankNotificationsData = await response.json();
            setBankNotificationsData(bankNotificationsData);

        } catch (error) {
            console.error('Error fetching bank overview:', error);
        }

    };

    getBankNotificationsOverview();


    const roles = useMemo(() => {
        return associations
            .filter(association => association.organisation)
            .reduce((acc, next) => {
                const roles = next.roles
                const name = next.organisation.name
                roles.forEach(role => {
                    if (acc[role]) {
                        acc[role].push(name)
                    } else {
                        acc[role] = [name]
                    }
                })
                return acc
            }, {})
    }, [associations])

    async function onChange(notificationType, value) {
        const {waitForCommand} = await setNotificationSetting(notificationType, 'DEPOSITOR', value)
        await waitForCommand()
    }

    async function onUserLegacyNotificationsChange(value) {
        const {waitForCommand} = await setUserLegacyNotifications(value)
        await waitForCommand()
    }

    const notificationList = Object.keys(roles).map(role => <Notifications key={role} t={t} role={role} names={roles[role]} bankNotificationsData={bankNotificationsData} />)

    if (newNotificationSettings) {
        return (
            <>
                <p className="secondary-description">{t('pages-inbox.notificationMsg1')}</p>
                {notificationList}
                <UserNotifications t={t}/>
                {hasViewRole &&
                <div className={styles.limitedNotifications}>
                    <h4>{t('pages-inbox.notificationTitle2')}</h4>
                    <p>{t('pages-inbox.notificationMsg2')}</p>
                    <EmailCategory
                        title={t('pages-inbox.notificationLimitLabel')}
                        description={t('pages-inbox.notificationLimitDesc')}
                        notificationType={'ONLY_CRITICAL_NOTIFICATIONS'}
                        settings={settings}
                        onChange={onChange}
                    />
                </div>
                }
            </>
        )

    } else {
        return (
            <Modal>
                <h3>{t('pages-profile.emailNotificationModalHeader')}</h3>
                <p>
                    {t('pages-profile.emailNotificationModalMsg1')}
                </p>
                <p>
                    {t('pages-profile.emailNotificationModalMsg2')}
                </p>
                <p>
                    {t('pages-profile.emailNotificationModalMsg3')}
                </p>
                <p>
                    {t('pages-profile.emailNotificationModalMsg4')}
                </p>
                <ButtonRow>
                    <Button variant={'secondary'} onClick={() => navigate("/profile#email-notifications")}>{t('common.cancel')}</Button>
                    <Button onClick={() => onUserLegacyNotificationsChange(false)}>{t('pages-profile.emailNotificationModalButton')}</Button>
                </ButtonRow>
            </Modal>
        )
    }
}
