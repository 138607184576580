import NewOfferModal from '#app/pages/BankAds/NewOfferModal'
import { useCommand } from '#command'
import { InterestOutput, PageLayout } from '#components'
import ModalConfirmation from '#components/ModalConfirmation/ModalConfirmation'
import PageHeader from '#components/PageHeader/PageHeader'
import Paper from '#components/Paper'
import RestrictedBankAccessInfo from '#components/RestrictedBankAccessInfo'
import { DeactivatedAdRow } from '#pages/BankAds/DeactivatedAdRow'
import { NewAdModal } from '#pages/BankAds/NewAdModal'
import { useAuthorization } from '#services/authorization'
import { sortDatesDescending } from '#services/dateandtime'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { Button } from '@mui/material'
import isAfter from 'date-fns/isAfter'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ActiveAd } from './ActiveAd'
import styles from './BankAds.module.scss'

type Params = {
    id?: string
    type?: 'notice' | 'fixed'
}

export default function BankAds() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams<Params>()
    const auth = useAuthorization()

    const {setAdActiveStatus} = useCommand()

    const adsLoaded = useSelector(state => state.loaded.ads)
    const ads = useSelector(state => selectors.myBankAds(state))
    const bank = useSelector(state => selectors.bankInSession(state))
    const benchmarkInterestRate = useSelector(state => state.interestRateBenchmarks.defaultBenchmarkInterestRate)
    const interestRateBenchmarkName = t('common.interestRateBenchmark.' + useSelector(state => state.interestRateBenchmarks.defaultInterestRateBenchmark))

    if (!bank || !bank.termsAccepted) {
        return (
            <>
                <PageHeader title={t('pages-BankAds.pageHeader')}/>
                <PageLayout>
                    <Paper>
                        <RestrictedBankAccessInfo/>
                        <div className={styles.linkToBankPage}>
                            <Link to={'/my-bank'}>{t('pages-BankAds.goToBankPage')}</Link>
                        </div>
                    </Paper>
                </PageLayout>
            </>
        )
    }

    async function deactivateAd(adId) {
        const {waitForCommand} = await setAdActiveStatus(adId, false)
        const success = await waitForCommand()
        if (success) {
            dispatch(showConfirmationModal({
                title: t('pages-BankAds.deactivationConfirmedTitle'),
                text: t('pages-BankAds.deactivationConfirmedText'),
                buttonText: t('common.continue'),
            }))
        }
    }

    const deactivateModal = params.id && (
        <ModalConfirmation
            header={t('pages-BankAds.deactivateHeader')}
            text={t('pages-BankAds.deactivateConfirmation')}
            onSubmit={() => deactivateAd(params.id)} backUrl={'/ads'}
        />
    )

    const activeAds = ads.filter(ad => !ad.validity || isAfter(new Date(ad.validity), new Date())).sort((a1, a2) => sortDatesDescending(a1.published, a2.published))
    const inactiveAds = ads.filter(ad => ad.validity && isAfter(new Date(), new Date(ad.validity))).sort((a1, a2) => sortDatesDescending(a1.published, a2.published))

    return (
        <>
            <PageHeader title={t('pages-BankAds.pageHeader')}/>
            <PageLayout className={styles.bankAds}>
                {deactivateModal}
                {location?.pathname === '/ads/new-ad' && <NewAdModal/>}
                <NewOfferModal
                    open={params.type === 'notice'}
                    onClose={() => navigate('/ads')}
                    productIdDefault={"5"}
                    urlContext="/ads"
                />
                <NewOfferModal
                    open={params.type === 'fixed'}
                    onClose={() => navigate('/ads')}
                    productIdDefault="3"
                    urlContext="/ads"
                />
                <div className={styles.topRow}>
                    {auth.bank.hasAdManagerRole && (
                        <div className={styles.topBlock}>
                            <Button variant={'contained'} id={'newAdButton'} onClick={() => navigate('/ads/new-ad')}>{t('pages-BankAds.newAd')}</Button>
                        </div>
                    )}
                    <div className={styles.topBlock}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span>{t('pages-BankAds.activeAdCount')}</span>
                            <strong style={{fontSize: 24}}>{activeAds.length}</strong>
                        </div>
                    </div>
                    <div className={styles.topBlock}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span>{t('pages-BankAds.requestedMillions')}</span>
                            <strong style={{fontSize: 24}}>{activeAds.reduce((a, c) => a + c.remaining, 0)}</strong>
                        </div>
                    </div>
                    {benchmarkInterestRate && (
                        <div className={styles.topBlock}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <span>{interestRateBenchmarkName}</span>
                                <strong style={{fontSize: 24}}>{InterestOutput.format(benchmarkInterestRate)}</strong>
                            </div>
                        </div>
                    )}
                </div>
                <h2>
                    <span className={styles.circle}></span>{t('pages-BankAds.activeAds')}
                </h2>
                {adsLoaded && activeAds.length === 0 && <p>{t('pages-BankAds.noActiveAds')}</p>}
                <div className={styles.adsWrapper}>
                    {activeAds.map(ad => <ActiveAd key={ad.id} ad={ad}/>)}
                </div>
                <h2>{t('pages-BankAds.history')}</h2>
                {adsLoaded && inactiveAds.length === 0 && <p>{t('pages-BankAds.noPreviousAds')}</p>}
                <div className={styles.headers}>
                    <span className={styles.volumeField}>{t('pages-BankAds.volumeHeader')}</span>
                    <span className={styles.productField}>{t('pages-BankAds.productHeader')}</span>
                    <span className={styles.interestTermsField}>{t('pages-BankAds.conditionsHeader')}</span>
                    <span className={styles.depositLimitsField}>{t('pages-BankAds.depositLimitsHeader')}</span>
                    <span className={styles.publishedField}>{t('pages-BankAds.publishedHeader')}</span>
                    <span className={styles.deactivateField}>{t('pages-BankAds.deactivationHeader')}</span>
                </div>
                <div className={styles.table}>{inactiveAds.map(ad => <DeactivatedAdRow key={ad.id} ad={ad}/>)}</div>
            </PageLayout>
        </>
    )
}
