import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput';
import { useCommand } from '#command';
import { CurrencyOutput, NumberInput } from '#components';
import DatePicker from '#components/DatePicker';
import {FundBuyOrderState, FundShareClassPriceData, FundTradeOrderStatusDto} from '@fixrate/fixrate-query';
import { showConfirmationModal } from '#state/reducers/confirmationModal';
import { useSelector } from '#state/useSelector';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    TextField
} from '@mui/material';
import { BigNumber } from "bignumber.js";
import format from 'date-fns/format';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DetailRow from './components/DetailRow';
import { FundBuyOrderRow } from './FundBuyOrderTable';
import {useFieldState} from '@fixrate/fieldstate'

// export interface TradeOrderStatus {
//     cancelled: boolean
//     payed: boolean
//     fulfilled: boolean
//     booked: boolean
//     settlementDate: Date
//     transactionDate: Date
//     amount: number
//     unitQuantity: number
//     unitPrice: number
//     roundingError: number
//     isinCode: string
// }

type StateTexts = {
    label: string
    description: string
}
const fundBuyOrderStatesMap: { [K in FundBuyOrderState]: StateTexts } = {
    AWAITING_SIGNATURE: {
        label: 'Ordre opprettet',
        description: 'Avventer signatur fra kunde'
    },
    AWAITING_PAYMENT: {
        label: 'Ordre signert',
        description: 'Avventer innbetaling fra kunde'
    },
    AWAITING_PAYMENT_CONFIRMATION: {
        label: 'Kunden har meldt innbetaling',
        description: 'Avventer bekreftelse på mottatt innbetaling fra fondsformidler'
    },
    IN_PROGRESS: {
        label: 'Innbetaling bekreftet av fondsformidler',
        description: 'Avventer at ordren utføres i markedet'
    },
    EXECUTING_IN_MARKET: {
        label: 'Ordren er i markedet',
        description: 'Avventer at ordren fullføres'
    },
    COMPLETED: {
        label: 'Fullført',
        description: ''
    },
    CANCELLED: {
        label: 'Avbrutt',
        description: ''
    },
    CANCELLED_BY_DEPOSITOR: {
        label: 'Avbrutt av innskyter',
        description: ''
    },
}

export default function UpdateStatusDialog({
    fundBuyOrderRow,
    onClose,
}: { fundBuyOrderRow: FundBuyOrderRow | null, onClose: () => void }) {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const {cancelFundBuyOrder, registerFundBuyOrderPayment, registerFundBuyOrderExecutingInMarket, completeFundBuyOrder} = useCommand()

    const [selectedState, setSelectedState] = useState<FundBuyOrderState | ''>(fundBuyOrderRow?.state ?? '')

    const fund = useSelector(state => state.funds.find(fund => fund.id === fundBuyOrderRow?.fundId))
    const fundShareClass = useSelector(state => state.funds.flatMap(f => f.fundShareClasses).find(fsc => fsc.id === fundBuyOrderRow?.fundShareClassId))
    const decimalPrecision = fund?.decimalPrecision ?? 4

    const [fundNav, setFundNav] = useState<BigNumber>(null)
    const [fundAccrual, setFundAccrual] = useState<BigNumber>(null)

    const unitQuantityField = useFieldState<number | null>(fundBuyOrderRow?.unitQuantity ?? null, ({isSet}) => !isSet ? "Antall enheter må fylles ut" : "")
    const unitPriceField = useFieldState<number | null>(fundBuyOrderRow?.unitPrice ?? null, ({value, isEditing}) => {
        if (!isEditing && value !== null && fundNav !== null) {

            if (!new BigNumber(value).eq(fundNav))
                return 'Fondets NAV er ikke likt oppgitt enhetspris'
        }
        return null
    })
    const accrualField = useFieldState<number | null>(null, ({isSet}) => {
        if (fund?.isDividendFund && !isSet) {
            return 'Rentekurs må fylles ut'
        }
        return ''
    })

    const roundingErrorField = useFieldState<number | null>(fundBuyOrderRow?.roundingError ?? null, ({isSet}) => !isSet ? "Avrunding må fylles ut" : "")
    const amountField = useFieldState<number | null>(fundBuyOrderRow?.amount ?? null, ({value, isSet, isEditing}) => {
        if (!isEditing && isSet) {
            const amount = new BigNumber(value)
            const calculatedAmount = calculateValue().dp(2, BigNumber.ROUND_HALF_UP).plus(new BigNumber(roundingErrorField.value))

            if (!calculatedAmount.eq(amount)) {
                return `Beløp er ikke likt enhetspris * antall enheter + avrunding = ${calculatedAmount})`
            }
        }

        if (!isSet) {
            return 'Beløp må fylles ut'
        }
        return ''
    })

    const [settlementDate, setSettlementDate] = useState<Date | null>(fundBuyOrderRow?.settlementDate ? new Date(fundBuyOrderRow.settlementDate) : null)
    const [transactionDate, setTransactionDate] = useState<Date | null>(fundBuyOrderRow?.transactionDate ? new Date(fundBuyOrderRow.transactionDate) : null)

    const [submitting, setSubmitting] = useState<boolean>(false)
    const [statusSubmitting, setStatusSubmitting] = useState<boolean>(false)

    function calculateValue(): BigNumber {
        const unitQuantity = new BigNumber(unitQuantityField.value ?? 0)
        const unitPrice = new BigNumber(unitPriceField.value ?? 0)
        return unitQuantity.multipliedBy(unitPrice)
    }

    // Gets stable instances of the set-functions
    // The *.fn objects are not stable, since the validators depends on mutable data
    const setUnitQuantityField = unitQuantityField.fn.setValue
    const setUnitPriceField = unitPriceField.fn.setValue
    const setAccrualField = accrualField.fn.setValue
    const setRoundingErrorField = roundingErrorField.fn.setValue
    const setAmountField = amountField.fn.setValue

    // fetch Nav from /api/fund/nav/{fundId}?date={date} on change of transactionDate
    useEffect(() => {
        async function fetchNav() {
            if (fundShareClass?.isin && fund && transactionDate) {
                const formattedTransactionDate = format(transactionDate, 'yyyy-MM-dd')
                const navResponse = await fetch(`/api/marketdata/v2/nav/${fundShareClass.isin}?date=${formattedTransactionDate}`, {
                    credentials: 'include',
                })
                if (navResponse.ok) {
                    const navs = await navResponse.json() as FundShareClassPriceData[]
                    const nav = navs[0]
                    if (nav.unitEntry.unitDate === formattedTransactionDate) {
                        setFundNav(new BigNumber(nav.unitEntry.unitPrice))
                    } else {
                        setFundNav(null)
                    }
                    if (nav.interestEntry.interestDate === formattedTransactionDate) {
                        setFundAccrual(new BigNumber(nav.interestEntry.interestPrice))
                        setAccrualField(nav.interestEntry.interestPrice)
                    } else {
                        setFundAccrual(null)
                        setAccrualField(null)
                    }
                } else {
                    setFundNav(null)
                    setFundAccrual(null)
                    setAccrualField(null)
                }
            }
        }
        fetchNav()
    }, [setAccrualField, fund, fundShareClass?.isin, transactionDate])

    useEffect(() => {
        if (fundBuyOrderRow) {
            setSelectedState(fundBuyOrderRow.state ?? '')
            setUnitQuantityField(fundBuyOrderRow.unitQuantity ?? null)
            setUnitPriceField(fundBuyOrderRow.unitPrice ?? null)
            setAmountField(fundBuyOrderRow.amount ?? null)
            setRoundingErrorField(fundBuyOrderRow.roundingError ?? null)
            setSettlementDate(fundBuyOrderRow.settlementDate ? new Date(fundBuyOrderRow.settlementDate) : null)
            setTransactionDate(fundBuyOrderRow.transactionDate ? new Date(fundBuyOrderRow.transactionDate) : null)
        }
    }, [fundBuyOrderRow, setAmountField, setRoundingErrorField, setUnitPriceField, setUnitQuantityField])


    const handleUpdateStatusFromFA = async () => {
        setStatusSubmitting(true)
        try {
            const statusResponse = await fetch(`/api/fund/fund-buy-order-status/${fundBuyOrderRow?.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
            })

            const status = await statusResponse.json() as FundTradeOrderStatusDto

            if (status.cancelled) {
                setSelectedState('CANCELLED')
            } else if (status.executingInMarket && !isDisabled('EXECUTING_IN_MARKET')) {
                setSelectedState('EXECUTING_IN_MARKET')
            } else if (status.fulfilled) {
                setSelectedState('COMPLETED')
                amountField.setValue(status.amount)
                unitPriceField.setValue(status.unitPrice)
                unitQuantityField.setValue(status.unitQuantity)
                roundingErrorField.setValue(status.roundingError)
                setSettlementDate(new Date(status.settlementDate))
                setTransactionDate(new Date(status.transactionDate))
            }
        } finally {
            setStatusSubmitting(false)
        }
    }

    function currentSelectableStates(): FundBuyOrderState[] {
        const currentState = fundBuyOrderRow?.state
        if (!fundBuyOrderRow?.registeredInFa) {
            return [currentState, 'CANCELLED']
        }
        switch (currentState) {
            case 'AWAITING_SIGNATURE':
                return ['AWAITING_SIGNATURE', 'CANCELLED']
            case 'AWAITING_PAYMENT':
                return ['AWAITING_PAYMENT', 'AWAITING_PAYMENT_CONFIRMATION', 'IN_PROGRESS', 'CANCELLED']
            case 'AWAITING_PAYMENT_CONFIRMATION':
                return ['AWAITING_PAYMENT_CONFIRMATION', 'IN_PROGRESS', 'CANCELLED']
            case 'IN_PROGRESS':
                return ['IN_PROGRESS', 'EXECUTING_IN_MARKET', 'COMPLETED', 'CANCELLED']
            case 'EXECUTING_IN_MARKET':
                return ['EXECUTING_IN_MARKET', 'IN_PROGRESS', 'COMPLETED', 'CANCELLED']
            case 'COMPLETED':
                return ['COMPLETED']
            case 'CANCELLED':
                return ['CANCELLED']
            case 'CANCELLED_BY_DEPOSITOR':
                return ['CANCELLED_BY_DEPOSITOR']
            default:
                return []
        }
    }
    function isDisabled(state: FundBuyOrderState): boolean {
        return !currentSelectableStates().includes(state)
    }

    async function handleSubmit() {
        if (!fundBuyOrderRow) return
        if (isDisabled(selectedState as FundBuyOrderState)) return

        setSubmitting(true)

        switch (selectedState) {
            case 'IN_PROGRESS': {
                const {waitForCommand} = await registerFundBuyOrderPayment(fundBuyOrderRow.id)
                const success = await waitForCommand()
                if (success) {
                    dispatch(showConfirmationModal({
                        title: 'Status oppdatert!',
                        text: 'Innbetalingen ble bekreftet. Status ble satt som: "Innbetaling bekreftet av fondsformidler".',
                        buttonText: t('common.continue'),
                    }))
                    onClose()
                }
                break
            }
            case 'EXECUTING_IN_MARKET': {
                const {waitForCommand} = await registerFundBuyOrderExecutingInMarket(fundBuyOrderRow.id)
                const success = await waitForCommand()
                if (success) {
                    dispatch(showConfirmationModal({
                        title: 'Status oppdatert!',
                        text: 'Status ble satt som: "Ordren er i markedet"',
                        buttonText: t('common.continue'),
                    }))
                    onClose()
                }
                break
            }
            case 'CANCELLED': {
                const confirmed = window.confirm('Er du sikker på at du vil avbryte denne ordren?')
                if (!confirmed) break

                const {waitForCommand} = await cancelFundBuyOrder(fundBuyOrderRow.id)
                const success = await waitForCommand()
                if (success) {
                    dispatch(showConfirmationModal({
                        title: 'Ordren er avbrutt!',
                        text: 'Ordren er avbrutt!',
                        buttonText: t('common.continue'),
                    }))
                    onClose()
                }
                break
            }
            case 'COMPLETED': {
                unitPriceField.validate();
                unitQuantityField.validate();
                roundingErrorField.validate();
                amountField.validate();
                if (transactionDate === null || settlementDate === null || !unitQuantityField.valid || !unitPriceField.valid || !amountField.valid || !roundingErrorField.valid) {
                    setSubmitting(false)
                    return
                }

                const {waitForCommand} = await completeFundBuyOrder(
                    fundBuyOrderRow.id,
                    unitQuantityField.value,
                    unitPriceField.value,
                    amountField.value,
                    roundingErrorField.value,
                    format(transactionDate, 'yyyy-MM-dd'),
                    format(settlementDate, 'yyyy-MM-dd'))
                const success = await waitForCommand()
                if (success) {
                    dispatch(showConfirmationModal({
                        title: 'Ordre fullført!',
                        text: 'Ordre fullført!',
                        buttonText: t('common.continue'),
                    }))
                    onClose()
                }
                break
            }
        }

        setSubmitting(false)
    }

    return (
        <Dialog open={fundBuyOrderRow !== null} onClose={onClose} fullWidth maxWidth={'sm'}>
            <DialogTitle>Sett status</DialogTitle>
            {fundBuyOrderRow && (
                <DialogContent>
                    <DialogContentText sx={{mb: 1}}>Status skal i utgangspunktet oppdateres automatisk fra FA, men dersom
                        dette ikke har skjedd kan du overstyre her.</DialogContentText>
                    <DialogContentText sx={{mb: 2}}>Det er FA som er fasit, men det er denne statusen som kunden vil se i
                        Fixrate.</DialogContentText>
                    <DetailRow title={'Fond'}>{fundBuyOrderRow.fundName}</DetailRow>
                    <DetailRow title={'Innskyter'}>{fundBuyOrderRow.depositorName}</DetailRow>
                    <DetailRow title={'Sum'}>
                        {CurrencyOutputObject(fundBuyOrderRow.amount, { currency: fundShareClass?.currency })}
                    </DetailRow>
                    <DetailRow title={'Andeler'}>
                        {CurrencyOutput.formatNoCode(fundBuyOrderRow.unitQuantity, decimalPrecision)}
                    </DetailRow>
                    <DetailRow title={'External ID'}>{fundBuyOrderRow.id}</DetailRow>
                    <FormControl sx={{mt: 2}}>
                        <FormLabel>Ordrestatus</FormLabel>
                        <RadioGroup data-cy="statusSelectRadio" value={selectedState}>
                            {Object.entries(fundBuyOrderStatesMap).map(([state, texts]) => (
                                <div key={state}>
                                    <FormControl margin={'normal'} disabled={isDisabled(state as FundBuyOrderState)}>
                                        <FormControlLabel
                                            value={state}
                                            control={<Radio/>}
                                            slotProps={state === fundBuyOrderRow.state && {typography: {fontWeight: 700}}}
                                            label={`${texts.label}${state === fundBuyOrderRow.state ? ' (nåværende)' : ''}`}
                                            onChange={() => setSelectedState(state as FundBuyOrderState)}
                                        />
                                        <FormHelperText>{texts.description}</FormHelperText>
                                    </FormControl>
                                    {state === 'COMPLETED' && selectedState === 'COMPLETED' && (
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, mb: 2}}>
                                            <DatePicker
                                                id="transactionDateInput"
                                                selected={transactionDate}
                                                onChange={date => setTransactionDate(date)}
                                                inputProps={{
                                                    label: 'Transaksjonsdato',
                                                    helperText: !transactionDate && 'Transaksjonsdato må fylles ut',
                                                    error: !transactionDate,
                                                }}
                                            />
                                           <DatePicker
                                               id="settlementDateInput"
                                               selected={settlementDate}
                                               onChange={date => setSettlementDate(date)}
                                               inputProps={{
                                                    label: 'Bokføringsdato',
                                                    helperText: !settlementDate && 'Bokføringsdato må fylles ut',
                                                    error: !settlementDate,
                                               }}
                                           />


                                            <NumberInput
                                                label={'Antall kjøpte andeler'}
                                                value={unitQuantityField.value}
                                                onChange={unitQuantityField.setValue}
                                                formatFn={v => CurrencyOutputObject(v, {withCurrency: false, minimumDecimals: 2, maximumDecimals: 10})}
                                                data-cy={'quantityInput'}
                                                error={!unitQuantityField.valid}
                                                onBlur={unitQuantityField.onBlur}
                                                helperText={unitQuantityField.errorMessage}
                                            />

                                           <NumberInput
                                               label={'Andelspris'}
                                               value={unitPriceField.value}
                                               onChange={unitPriceField.setValue}
                                               formatFn={v => CurrencyOutputObject(v, {currency: fundShareClass?.currency, minimumDecimals: 2, maximumDecimals: 10})}
                                               error={!unitPriceField.valid}
                                               onBlur={unitPriceField.onBlur}
                                               helperText={(fundNav && transactionDate && 'Fondet har ' + fundNav.toString() + ', den ' + format(transactionDate, 'dd.MM.yyyy')) || (transactionDate && 'Fondet har ikke NAV for ' + format(transactionDate, 'dd.MM.yyyy') + '. Oppgitt NAV vil bli brukt.')}
                                               data-cy={'priceInput'}
                                           />

                                           {fund.dividendFund && <NumberInput
                                               disabled={true}
                                               label={'Fondets rentekurs pr andel'}
                                               placeholder={'test'}
                                               value={accrualField.value}
                                               onChange={accrualField.setValue}
                                               formatFn={v => CurrencyOutputObject(v, {currency: fundShareClass?.currency, minimumDecimals: 2, maximumDecimals: 10})}
                                               onBlur={accrualField.onBlur}
                                               helperText={(fundAccrual && transactionDate && 'Fondet har ' + fundAccrual.toString() + ', den ' + format(transactionDate, 'dd.MM.yyyy')) || (transactionDate && 'Fondet har ikke rentekurs for ' + format(transactionDate, 'dd.MM.yyyy') + '. Oppgitt rentekurs vil bli brukt.')}
                                               data-cy={'accrualInput'}
                                           />}
                                           <p>Handelsbeløp {CurrencyOutputObject(calculateValue().dp(2, BigNumber.ROUND_HALF_UP).toNumber(), { currency: fundShareClass?.currency })}</p>

                                           <NumberInput
                                               label={'Avrundingskorrigering'}
                                               value={roundingErrorField.value}
                                               onChange={roundingErrorField.setValue}
                                               formatFn={v => CurrencyOutputObject(v, { currency: fundShareClass?.currency, minimumDecimals: 2, maximumDecimals: 10 })}
                                               error={!roundingErrorField.valid}
                                               onBlur={roundingErrorField.onBlur}
                                               helperText={roundingErrorField.errorMessage}
                                               data-cy={'roundingErrorInput'}
                                           />

                                           <NumberInput
                                               label={'Overført beløp'}
                                               value={amountField.value}
                                               onChange={amountField.setValue}
                                               formatFn={v => CurrencyOutputObject(v, { currency: fundShareClass?.currency, minimumDecimals: 2, maximumDecimals: 10 })}
                                               error={!amountField.valid}
                                               onBlur={amountField.onBlur}
                                               helperText={amountField.errorMessage}
                                               data-cy={'amountInput'}
                                           />
                                        </Box>
                                    )}
                                </div>
                            ))}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
            )}
            <DialogActions>
                <Button disabled={statusSubmitting} onClick={handleUpdateStatusFromFA}>Hent inn status fra FA</Button>
                <Button data-cy="cancelButton" onClick={onClose}>Avbryt</Button>
                <LoadingButton
                    data-cy="updateStatusButton"
                    variant={'contained'}
                    disabled={submitting || selectedState === fundBuyOrderRow?.state}
                    loading={submitting}
                    onClick={handleSubmit}
                >
                    Oppdater status
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}


