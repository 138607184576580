import restEndpoint from '#app/services/rest/rest-endpoint';

import {setSession} from '#state/reducers/session';
import {Button, CircularProgress, ListItemText, Menu, MenuItem, useMediaQuery, useTheme} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {listItemTextStyle, selectButtonStyles} from '../MenuComponents';
import {sendExceptionToSentry} from '#app/services/sentry';
import usePortfolio from '#services/usePortfolio';
import {Portfolio} from "@fixrate/fixrate-query";
import {useSelector} from "#state/useSelector";
import {PortfolioIdentity} from "@fixrate/fixrate-security";

export const PortfolioSelection = ({onChange} : { onChange: (portfolio: Portfolio) => void}) => {
    const location = useLocation()
    const dispatch = useDispatch()

    const [portfolioAnchorEl, setPortfolioAnchorEl] = useState<null | HTMLElement>(null)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const portfolios = useSelector(state => state.depositor?.portfolios)
    const currentPortfolio = usePortfolio()

    const hasMultiplePortfolios = portfolios?.length > 1

    const updateSession = () => {
        setLoading(true)
        restEndpoint(dispatch).getSession().then((session) => {
            dispatch(setSession(session))
        }).catch((err) => {
            sendExceptionToSentry(err, {message: "Failed to update the session"})
        }).finally(() => {
            // avoid flickering between empty currency text and loading spinner
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
    }

    const handlePortfolioMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPortfolioAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const handlePortfolioMenuClose = () => {
        setPortfolioAnchorEl(null)
        setOpen(false)
    }

    const selectPortfolio = async (portfolio: Portfolio) => {
        setLoading(true)
        await onChange(portfolio)
        setOpen(false)
        await updateSession()
    }

    useEffect(() => {
        setPortfolioAnchorEl(null)
    }, [location])

    function labelGenerator(portfolio: Portfolio|PortfolioIdentity) {
        return portfolio.name === portfolio.currency ? portfolio.currency : `${portfolio.name} (${portfolio.currency})`
    }

    return hasMultiplePortfolios ? (
        <>
            <Button
                id="currency-menu-button"
                aria-controls={open ? 'currency-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handlePortfolioMenuClick}
                color="secondary"
                data-cy="currency-menu-button"
                sx={{...selectButtonStyles, minWidth: { xs: "8rem", md: "12rem"}}}
                startIcon={
                    !loading && <i className="ri-coin-line purple"/>
                }
                endIcon={
                    !isMobile && <i style={{fontSize: "1.6rem", fontWeight: "normal"}}
                    className={open ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"} />
                }
            >
                {loading ? <CircularProgress size={20}/> : labelGenerator(currentPortfolio)}
            </Button>
            <Menu
                id="currency-menu"
                data-cy="currency-menu"
                anchorEl={portfolioAnchorEl}
                open={open}
                onClose={handlePortfolioMenuClose}
                sx={{width: "100%"}}
                MenuListProps={{
                    'aria-labelledby': 'currency-menu-button',
                }}
            >
                {portfolios.filter(p => p.id !== currentPortfolio.id).map(p => (

                    <MenuItem data-cy="currency-menu-item" sx={{minWidth: { xs: "8rem", md: "12rem"}}} color="primary" key={p.id} onClick={() => selectPortfolio(p)}>
                        <ListItemText sx={{...listItemTextStyle, textAlign: "center"}}>
                            {labelGenerator(p)}
                        </ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    ) : null
}

