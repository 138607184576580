import { FOREST_GREEN, SILVER_GRAY } from '#app/colors/colors'
import { Chart, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export function initializeChartJs() {
    // chart.js
    Chart.register(...registerables, ChartDataLabels)
    Chart.defaults.plugins.tooltip = {
        ...Chart.defaults.plugins.tooltip,
        backgroundColor: '#ffffff',
        borderColor: SILVER_GRAY[500],
        cornerRadius: 0,
        borderWidth: 2,
        titleColor: FOREST_GREEN[500],
        bodyColor: FOREST_GREEN[500],
        footerColor: FOREST_GREEN[500],
    }
    Chart.defaults.plugins.datalabels = {
        ...Chart.defaults.plugins.datalabels,
        display: false,
        font: {
            size: 14,
            family: '\'Montserrat\'',
            weight: 'bold',
        },
    }
}
