import { getCurrentVolumeByBankForDepositor } from '#app/services/thunks/statistics'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { InterestOutput, Table, TableCell, TableHeader, TableHeaderCell, TableRow } from '#components'
import ProductSelector from '#pages/Analytics/ExposureTable/ProductSelector'
import ShowInterestSelector from '#pages/Analytics/ExposureTable/ShowInterestSelector'
import { StaggData, StaggSeriesWithSingleTag } from '#state/stagg'
import Tooltip from '@mui/material/Tooltip'
import classNames from 'classnames'
import zipWith from 'lodash/zipWith'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styles from './ExposureTable.module.scss'

type Bank = {
    name: string,
    deposited?: number,
    ordered?: number,
    remaining?: number,
    total?: number,
    partOfTotalAssets?: number,
    volume?: number,
    available?: number,

}

function convertStaggSeriesToBanks(series: StaggSeriesWithSingleTag[]) {
    const banks: Bank[] = series[0].values.map(v => ({name: v[0]}))
    series
        .reduce((acc, s) => {
            const tags = s.tags as string
            zipWith(acc, s.values.map(val => val[1]), (bank: Bank, value) => bank[tags] = value)
            return acc
        }, banks)
        .forEach(b => {
            b.remaining = Math.max(b.available - b.deposited - b.ordered, 0)
            b.total = b.deposited + b.ordered + b.remaining
            b.partOfTotalAssets = ((b.deposited + b.ordered) / b.volume) * 100 * 10
        })
    return banks
}

const ExposureTable = () => {
    const {t} = useTranslation()
    const depositor = useCurrentDepositor()
    const dispatch = useDispatch<(arg0: (any) => void) => Promise<StaggData>>()
    const [data, setData] = useState([])
    const [max, setMax] = useState(0)
    const [showFilterPane, setShowFilterPane] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState('')
    const [showInterestMargin, setShowInterestMargin] = useState(false)
    const [sort, setSort] = useState(['deposited', 'ordered', 'remaining'])

    const enableShowInterestMargin = (() => {
        switch (selectedProduct) {
            case '5': // 31 d
            case '7': // 90 d
            case '8': // FRN 24
            case '9': // FRN 36
            case '10': // 31d USD
                return true
            default:
                return false
        }
    })()

    useEffect(() => {
        dispatch(getCurrentVolumeByBankForDepositor(depositor.id, selectedProduct, undefined, undefined))
            .then((data) => data[0].series)
            .then((series: any) => {
                const banks = convertStaggSeriesToBanks(series)
                setData(banks)
                const banksWithDepositsAndOrderes = banks.filter(b => b.deposited + b.ordered > 0)
                setMax(Math.max(...(banksWithDepositsAndOrderes.length > 0 ? banksWithDepositsAndOrderes : banks).map(b => b.total)))
            })
            .catch(error => console.error(error))
    }, [selectedProduct, depositor.id, dispatch])

    const sortFn = (a, b) => b[sort[0]] - a[sort[0]] || b[sort[1]] - a[sort[1]] || b[sort[2]] - a[sort[2]]
    const updateSort = (label) => {
        if (sort[0] === label) return

        const newSort = [...sort]
        newSort.pop()
        newSort.unshift(label)
        setSort(newSort)
    }
    const activeSort = (label) => sort[0] === label ? styles.activeSort : null

    type BarSegmentProps = {
        size: number,
        type: string,
        tooltip: string
    }
    const BarSegment = ({size, type, tooltip, ...props}: BarSegmentProps) => {
        if (!size) return null

        const width = `${(Math.min(size, max) / max) * 100}%`

        return (
            <Tooltip title={tooltip}>
                <div {...props} style={{width: width}} className={classNames(styles.bar, styles[type], size > max ? styles.maxedWidth : undefined)}/>
            </Tooltip>
        )
    }

    type BarLegendProps = {
        type: string,
        children: React.ReactNode
    }
    const BarLegend = ({type, children}: BarLegendProps) => {
        return (
            <span className={styles.legend}><span className={classNames(styles.legendMarker, styles[type])}/>{children}</span>
        )
    }

    const shouldShowInterestMargins = enableShowInterestMargin && showInterestMargin

    type FilterPaneProps = {
        hidden: boolean
    }
    const FilterPane = ({hidden}: FilterPaneProps) => {
        return (
            <div className={classNames(styles.filterPane, hidden && styles.hidden)}>
                <ProductSelector selectedProductId={selectedProduct} onSelectProduct={setSelectedProduct}/>
                <ShowInterestSelector showInterest={showInterestMargin} onSetShowInterest={setShowInterestMargin} enabled={enableShowInterestMargin}/>
            </div>
        )
    }

    const volumeText = (volume, decimals = 2) => (
        '' + (!volume ? 0 : (volume % 1 === 0 ? volume : volume?.toFixed(decimals))) + 'M'
    )

    return (
        <div>
            <div className={styles.toolbar}>
                <button className={styles.filterButton} onClick={() => setShowFilterPane(!showFilterPane)}>{t('pages-analytics.exposureFilterButtonText')}</button>
                <div className={styles.legends}>
                    <BarLegend type={'deposits'}>{t('pages-analytics.exposureDepositsLegend')}</BarLegend>
                    <BarLegend type={'orders'}>{t('pages-analytics.exposureOrdersLegend')}</BarLegend>
                    <BarLegend type={'remaining'}>{t('pages-analytics.exposureRemainingLegend')}</BarLegend>
                </div>
                <FilterPane hidden={!showFilterPane}/>
            </div>
            <Table sort={updateSort} className={styles.table}>
                <TableHeader>
                    <TableHeaderCell>
                        <Tooltip title={t('pages-analytics.exposureTableBankTooltip')}><span>{t('pages-analytics.exposureTableBank')}</span></Tooltip>
                    </TableHeaderCell>
                    <TableHeaderCell label="deposited" className={classNames(styles.tableCell, styles.deposits, activeSort('deposited'))}>
                        <Tooltip title={t('pages-analytics.exposureTableVolumeTooltip')}><span>{t('pages-analytics.exposureTableVolume')}</span></Tooltip>
                    </TableHeaderCell>
                    <TableHeaderCell label="ordered" className={classNames(styles.tableCell, styles.deposits, activeSort('ordered'))}>
                        <Tooltip title={t('pages-analytics.exposureTableOrderedVolumeTooltip')}><span>{t('pages-analytics.exposureTableOrderedVolume')}</span></Tooltip>
                    </TableHeaderCell>
                    {shouldShowInterestMargins ?
                        <TableHeaderCell label="weightedMargin" className={classNames(styles.tableCell, styles.deposits, activeSort('weightedMargin'))}>
                            <Tooltip title={t('pages-analytics.exposureTableMarginTooltip')}><span>{t('pages-analytics.exposureTableMargin')}</span></Tooltip>
                        </TableHeaderCell>
                        :
                        <TableHeaderCell label="weightedInterest" className={classNames(styles.tableCell, styles.deposits, activeSort('weightedInterest'))}>
                            <Tooltip title={t('pages-analytics.exposureTableInterestRateTooltip')}><span>{t('pages-analytics.exposureTableInterestRate')}</span></Tooltip>
                        </TableHeaderCell>
                    }
                    <TableHeaderCell label="partOfTotalAssets" className={classNames(styles.tableCell, styles.deposits, activeSort('partOfTotalAssets'))}>
                        <Tooltip title={t('pages-analytics.exposureTableTotalAssetsShareTooltip')}><span>{t('pages-analytics.exposureTableTotalAssetsShare')}</span></Tooltip>
                    </TableHeaderCell>
                    <TableHeaderCell label="remaining" className={classNames(styles.tableCell, styles.remaining, activeSort('remaining'))}>
                        <Tooltip title={t('pages-analytics.exposureTableAvailableTooltip')}><span>{t('pages-analytics.exposureTableAvailable')}</span></Tooltip>
                    </TableHeaderCell>
                    {shouldShowInterestMargins ?
                        <TableHeaderCell label="margin" className={classNames(styles.tableCell, styles.remaining, activeSort('margin'))}>
                            <Tooltip title={t('pages-analytics.exposureTableBestMarginTooltip')}><span>{t('pages-analytics.exposureTableBestMargin')}</span></Tooltip>
                        </TableHeaderCell>
                        :
                        <TableHeaderCell label="interest" className={classNames(styles.tableCell, styles.remaining, activeSort('interest'))}>
                            <Tooltip title={t('pages-analytics.exposureTableBestInterestRateTooltip')}><span>{t('pages-analytics.exposureTableBestInterestRate')}</span></Tooltip>
                        </TableHeaderCell>
                    }
                    <TableHeaderCell className={styles.exposure}>
                        <Tooltip title={t('pages-analytics.exposureTableExposureTooltip')}><span>{t('pages-analytics.exposureTableExposure')}</span></Tooltip>
                    </TableHeaderCell>
                </TableHeader>
                {data.sort(sortFn).map(bank => (
                    <TableRow key={bank.name}>
                        <TableCell>{bank.name}</TableCell>
                        <TableCell align={'right'} className={classNames(styles.tableCell, styles.deposits)}>
                            {volumeText(bank.deposited)}
                        </TableCell>
                        <TableCell align={'right'} className={classNames(styles.tableCell, styles.deposits)}>
                            {volumeText(bank.ordered)}
                        </TableCell>
                        {shouldShowInterestMargins ?
                            <TableCell align={'right'} className={classNames(styles.tableCell, styles.deposits)}>
                                {InterestOutput.format(bank.weightedMargin) || '-'}
                            </TableCell>
                            :
                            <TableCell align={'right'} className={classNames(styles.tableCell, styles.deposits)}>
                                {InterestOutput.format(bank.weightedInterest) || '-'}
                            </TableCell>
                        }
                        <TableCell align={'right'} className={classNames(styles.tableCell, styles.deposits)}>
                            {InterestOutput.format(bank.partOfTotalAssets).replace('%', '‰') || '-'}
                        </TableCell>
                        <TableCell align={'right'} className={classNames(styles.tableCell, styles.remaining)}>
                            {volumeText(bank.remaining, 0)}</TableCell>
                        {shouldShowInterestMargins ?
                            <TableCell align={'right'} className={classNames(styles.tableCell, styles.remaining)}>
                                {InterestOutput.format(bank.margin) || '-'}
                            </TableCell>
                            :
                            <TableCell align={'right'} className={classNames(styles.tableCell, styles.remaining)}>
                                {InterestOutput.format(bank.interest) || '-'}
                            </TableCell>
                        }
                        <TableCell>
                            <div className={classNames(styles.bars)}>
                                <BarSegment size={bank.deposited} type="deposits" tooltip={t('pages-analytics.exposureTableVolume') + ': ' + volumeText(bank.deposited)}/>
                                <BarSegment size={bank.ordered} type="orders" tooltip={t('pages-analytics.exposureTableOrderedVolume') + ': ' + volumeText(bank.ordered)}/>
                                <BarSegment size={bank.remaining} type="remaining" tooltip={t('pages-analytics.exposureTableAvailable') + ': ' + volumeText(bank.remaining)}/>
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
        </div>
    )
}

export default ExposureTable
