import BankKeyFigureChart from '#app/components/Charts/BankKeyFigureChart/BankKeyFigureChart'
import Paper from '#app/components/Paper/FxPaper'
import { BankDto } from '@fixrate/fixrate-query'
import { Box, Divider, Stack } from '@mui/material'
import classNames from 'classnames'
import addQuarters from 'date-fns/addQuarters'
import addYears from 'date-fns/addYears'
import format from 'date-fns/format'
import isBefore from 'date-fns/isBefore'
import parse from 'date-fns/parse'
import startOfQuarter from 'date-fns/startOfQuarter'
import startOfYear from 'date-fns/startOfYear'
import subQuarters from 'date-fns/subQuarters'
import subYears from 'date-fns/subYears'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChevronCircledLeft from '../../common/icons/ChevronCircledLeft'
import ChevronCircledRight from '../../common/icons/ChevronCircledRight'
import styles from './BankInfo.module.css'
import BankInfoRegistrationForm from './BankInfoRegistrationForm'

const formatString = 'yyyyqqq'
const yearFormatString = 'yyyy'

type Props = {
    bank: BankDto
}

export default function BankInfo({bank}: Props) {
    const {t} = useTranslation()
    const [selectedQuarter, setSelectedQuarter] = useState<string>(format(subQuarters(startOfQuarter(new Date()), 1), formatString)) // Last finished quarter

    const getPrevAndNextQuarters = (selectedDate: Date) => {
        const quarters = []
        let currentQuarter = subQuarters(selectedDate, 1)

        for (let i = -1; i <= 1; i++) {
            const quarter = format(currentQuarter, formatString)

            const disabled = !isBefore(currentQuarter, startOfQuarter(new Date()))

            const info = bank.bankInfoList.find(info => info.quarter === quarter)
            const completed = !!(info && info.totalAssets && info.totalAssetsIncludingMortgageCredit && info.depositCoverage && info.coreCapitalCoverage)

            quarters.push({
                quarter,
                selected: i === 0,
                disabled,
                completed,
            })
            currentQuarter = addQuarters(currentQuarter, 1)
        }

        return quarters // [prevQuarter, currentQuarter, nextQuarter]
    }

    type YearInfo = {
        year: string;
        selected: boolean;
        disabled: boolean;
        completed: boolean;
    }

    const getYears = (selectedDate: Date): YearInfo[] => {
        const years = []
        let currentYear = subYears(selectedDate, 3)

        for (let i = -3; i <= 3; i++) {
            const year = format(currentYear, yearFormatString)

            const disabled = !isBefore(startOfYear(currentYear), startOfQuarter(new Date()))

            const infoList = bank.bankInfoList.filter(info => info.quarter.substring(0, 4) === year)
            const completed = infoList.length === 4 && infoList.every(info => !!(info && info.totalAssets && info.totalAssetsIncludingMortgageCredit && info.depositCoverage && info.coreCapitalCoverage))

            years.push({
                year,
                selected: i === 0,
                disabled,
                completed,
            })
            currentYear = addYears(currentYear, 1)
        }

        return years
    }

    const getQuartersForYear = (selectedDate: Date) => {
        const quarters = []
        const yearString = format(selectedDate, yearFormatString)

        for (let i = 1; i <= 4; i++) {
            const quarterString = yearString + 'Q' + i
            const currentQuarter = parse(quarterString, formatString, new Date())

            const label = format(currentQuarter, 'qqq')

            const disabled = !isBefore(currentQuarter, startOfQuarter(new Date()))

            const info = bank.bankInfoList.find(info => info.quarter === quarterString)
            const completed = !!(info && info.totalAssets && info.totalAssetsIncludingMortgageCredit && info.depositCoverage && info.coreCapitalCoverage)

            quarters.push({
                quarter: quarterString,
                label,
                selected: selectedQuarter === quarterString,
                disabled,
                completed,
            })
        }

        return quarters
    }

    const selectQuarter = (quarter: string) => {
        setSelectedQuarter(quarter)
    }

    const selectYear = (year: string) => {
        const quarter = year + 'Q1'
        selectQuarter(quarter)
    }

    const selectedDate = parse(selectedQuarter, formatString, new Date())
    const prevAndNextQuarters = getPrevAndNextQuarters(selectedDate)
    const years = getYears(selectedDate)

    const prevQuarter = prevAndNextQuarters[0]
    const nextQuarter = prevAndNextQuarters[2]

    const quartersForYear = getQuartersForYear(selectedDate)

    return (
        <Fragment>
            <Paper id="key-info" title={t('pages-mybank.keyInfoTitle')}>
                <p>{t('pages-mybank.keyInfoInfo')}</p>
                <div className={styles.quarterSelect}>
                    <div className={styles.arrowContainer}
                         onClick={() => !prevQuarter.disabled && selectQuarter(prevQuarter.quarter)}>
                        <ChevronCircledLeft
                            className={classNames(styles.quarterArrowIcon, prevQuarter.disabled && styles.disabledQuarterArrowIcon)}/>
                    </div>
                    <div className={styles.quarterButtonTable}>
                        <div className={styles.yearButtonRow}>
                            {years.map(year => (
                                <div key={year.year}
                                     className={classNames(
                                         styles.quarterButton,
                                         year.selected && styles.selectedQuarter,
                                         year.disabled && styles.disabledQuarter,
                                         !(year.selected || year.disabled) && styles.activeQuarter,
                                         year.completed && styles.completedQuarter
                                     )}
                                     onClick={() => !(year.selected || year.disabled) && selectYear(year.year)}
                                >
                                    {year.year}
                                </div>
                            ))}
                        </div>
                        <div className={styles.quarterButtonRow}>
                            {quartersForYear.map(quarter => (
                                <div key={quarter.quarter}
                                     className={classNames(
                                         styles.quarterButton,
                                         quarter.selected && styles.selectedQuarter,
                                         quarter.disabled && styles.disabledQuarter,
                                         !(quarter.selected || quarter.disabled) && styles.activeQuarter,
                                         quarter.completed && styles.completedQuarter
                                     )}
                                     onClick={() => !(quarter.selected || quarter.disabled) && selectQuarter(quarter.quarter)}
                                >
                                    {quarter.label}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.arrowContainer}
                         onClick={() => !nextQuarter.disabled && selectQuarter(nextQuarter.quarter)}>
                        <ChevronCircledRight
                            className={classNames(styles.quarterArrowIcon, nextQuarter.disabled && styles.disabledQuarterArrowIcon)}/>
                    </div>
                </div>
                <Stack spacing={3}>
                    <BankInfoRegistrationForm selectedQuarter={selectedQuarter} bank={bank}/>
                    <Divider/>
                    <Box>
                        <BankKeyFigureChart 
                            bankId={bank.id}
                            valueType={'currency'}
                            formatFn={'millionFormat'}
                            keyFigureNames={['totalAssets', 'totalAssetsIncludingMortgageCredit']} />
                        <BankKeyFigureChart 
                            bankId={bank.id} 
                            valueType={'interest'}
                            keyFigureNames={['depositCoverage']} />
                        <BankKeyFigureChart 
                            bankId={bank.id}
                            valueType={'interest'}
                            keyFigureNames={['coreCapitalCoverage']} />
                    </Box>
                </Stack>
            </Paper>
        </Fragment>
    )

}

