import {Fragment, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useSelector} from '#state/useSelector'
import SimpleExposureChart from '#pages/Analytics/SimpleExposureChart'
import ExposureTable from '../ExposureTable/ExposureTable'
import {
    getCurrentVolumeByBankForDepositor,
    getDepositorPortfolioByBank,
    getDepositorPortfolioByBankType
} from '#services/thunks/statistics'
import {useTranslation} from 'react-i18next'
import {StaggDataItem} from '#state/stagg'
import { NorwayExposureMap } from '@fixrate/fixrate-analytics-widgets'
import FxPaper from '#app/components/Paper/FxPaper'
import { AnalyticsPaperSx } from '../Analytics'
import { Stack, Typography } from '@mui/material'

const AnalyticsExposure = () => {
    const {t} = useTranslation()
    const authenticated = useSelector(state => state.session.authenticated)
    const dispatch = useDispatch<(arg0: unknown) => Promise<StaggDataItem[]>>()
    const [portfolioByBank, setPortfolioByBank] = useState<StaggDataItem[] | null>(null)
    const [portfolioByBankType, setPortfolioByBankType] = useState<StaggDataItem[] | null>(null)
    const [exposureData, setExposureData] = useState<StaggDataItem[] | null>(null)

    const depositor = useSelector(state => state.depositor)
    const deposits = useSelector(state => state.deposits)
    const depositBanks = useSelector(
        state => state.deposits
            .map(deposit => deposit.bankId)
    )

    useEffect(() => {
        dispatch(getDepositorPortfolioByBank(depositor.id)).then((data) => {
            setPortfolioByBank(data)
        })
        dispatch(getDepositorPortfolioByBankType(depositor.id)).then((data) => {
            setPortfolioByBankType(data)
        })
    }, [depositor.id, authenticated, dispatch])

    const numberOfBanksExposed = [...new Set(depositBanks)].length
    const historicalDepositVolume = deposits.reduce((acc, deposit) => acc + deposit.volume, 0)

    return (
        <Fragment key={depositor.id}>
            <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.exposureSimpleHeader')} key={'E2'}>
                <p>{t('pages-analytics.exposureSimpleInfo1')}</p>
                <Stack spacing={4} mt={4}>
                    <Stack spacing={2}>
                        <Typography variant="h3">Fordeling per bank</Typography>
                        <SimpleExposureChart
                            depositorId={depositor.id}
                            data={portfolioByBank}
                        />
                    </Stack>
                    <Stack spacing={2}>
                        <Typography variant="h3">Fordeling per banksegment</Typography>
                        <SimpleExposureChart
                            depositorId={depositor.id}
                            data={portfolioByBankType}
                        />
                    </Stack>
                </Stack>
            </FxPaper>
            {numberOfBanksExposed > 2 && historicalDepositVolume > 50 && (
                <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.exposureAdvancedHeader')} key={'E1'}>
                    <p>{t('pages-analytics.exposureAdvancedInfo1')}</p>
                    <p>{t('pages-analytics.exposureAdvancedInfo2')}</p>
                    <ExposureTable />
                </FxPaper>
            )}
            <FxPaper sx={{...AnalyticsPaperSx, overflow: "hidden"}} title={t('pages-analytics.exposureGeographicalHeader')} key={'E4'}>
                <NorwayExposureMap depositorId={depositor.id}/>
            </FxPaper>
        </Fragment>
    )
}

export default AnalyticsExposure

