import {BURNT_ORANGE} from '#app/colors/colors';
import restEndpoint from '#app/services/rest/rest-endpoint';
import useUnresolvedTasks from '#app/services/useUnresolvedTasks';
import {messageTasksActionRequiredByUserCountSelector} from '#state/selectors'
import {useSelector} from '#app/state/useSelector';
import {setSession} from '#state/reducers/session';
import {Avatar, Box, CircularProgress, Stack, Tab, Tabs, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Portfolio} from "@fixrate/fixrate-query";
import usePortfolio from "#services/usePortfolio";
import {useEndpoint} from "#command";
import {sendExceptionToSentry} from "#services/sentry";

export const PortfolioTabber = () => {
    const {t} = useTranslation()
    const {setPortfolioDepositorSession} = useEndpoint()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState<Portfolio | null>(null)
    const messageTasksActionRequiredByUserCount = useSelector(messageTasksActionRequiredByUserCountSelector)
    const unresolvedTasks = useUnresolvedTasks()
    const portfolios = useSelector(state => state.depositor?.portfolios)
    const currentPortfolio = usePortfolio()
    // Portfolio is now only relevant for multiple currencies – if it is being used for other grouping in the future this will need to be updated
    const hasMultiplePortfolios = portfolios?.length > 1

    const updateSession = () => {
        restEndpoint(dispatch).getSession().then((session) => {
            dispatch(setSession(session))
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            // avoid flickering between empty currency text and loading spinner
            setTimeout(() => {
                setLoading(null)
            }, 1000)
        })
    }

    const selectPortfolio = async (portfolio : Portfolio) => {
        if (portfolio.id !== currentPortfolio.id) {
            setLoading(portfolio)

            setPortfolioDepositorSession(portfolio.id).catch((err) => {
                sendExceptionToSentry(err, {currency: portfolio.currency, reason: 'Failed to switch depositor currency / portfolio in session'})
            })

            await updateSession()
        }
    }

    return hasMultiplePortfolios ? (
        <Box>
            <Typography variant='filterLabel'>{t('common.portfolio')}</Typography>
            <Tabs sx={{boxShadow: "0px 1px 3px rgba(0,0,0,0.2)", backgroundColor: "white", borderRadius: "0.6rem"}} value={currentPortfolio.id}>
                { portfolios.map((portfolio, index) => (
                    <Tab
                        label={
                            loading?.id === portfolio.id ? (
                                <CircularProgress size={20} />
                            ) : (
                                <Stack direction="row" alignItems={"center"} spacing={0.4}>
                                    <span>{portfolio.name}</span>
                                    { portfolios && unresolvedTasks.portfolios[portfolio.id] > 0 && (
                                        <Avatar sx={{width: "1.6rem", height: "1.6rem", backgroundColor: BURNT_ORANGE[500], color: "white", fontSize: "1.2rem"}}>{unresolvedTasks.portfolios[portfolio.id]}</Avatar>
                                    )}
                                    { !portfolios && messageTasksActionRequiredByUserCount > 0 && portfolio.id === currentPortfolio.id && (
                                        <Avatar sx={{width: "1.6rem", height: "1.6rem", backgroundColor: BURNT_ORANGE[500], color: "white", fontSize: "1.2rem"}}>{messageTasksActionRequiredByUserCount}</Avatar>
                                    )}
                                </Stack>
                            )
                        }
                        key={portfolio.id}
                        value={portfolio.id}
                        sx={{height: "5.3rem", borderRight: index !== portfolios.length ? "0.1rem solid rgba(0,0,0,0.1)" : "0"}}
                        onClick={() => selectPortfolio(portfolio)} />
                ))}
            </Tabs>
        </Box>
    ) : null
}
