import { PageHeader, PageLayout } from '#app/components'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { isLoggedIn } from '#app/state/selectors'
import { useSelector } from '#app/state/useSelector'
import { useOnboardingWizardStatus } from '#app/utilities/useOnboardingWizardStatus'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { OrganizationWizard } from './AddNewOrganization/OrganizationWizard/OrganizationWizard'
import { SelfServiceWizard } from './AddNewOrganization/OrganizationWizard/SelfServiceWizard'
import Authorization from './Authorization/Authorization'
import { CustomerDeclaration } from './CustomerDeclaration/CustomerDeclaration'
import OrganisationDetail from './OrganizationDetail/OrganisationDetail'
import UserProfile from './OrganizationDetail/UsersAndRoles/views/UserProfile'
import './Organizations.scss'

export default function Organizations() {
    const {t} = useTranslation()
    const loggedIn = useSelector(isLoggedIn)
    const navigate = useNavigate()
    const depositor = useCurrentDepositor()
    const hasMadeOrders = useSelector(state => state.orders.length > 0)
    const hasMadeDeposits = useSelector(state => state.deposits.length > 0)
    const hasSingleDepositor = useSelector(state => state.session.associations.filter(o => o.organisationType === 'DEPOSITOR').length === 1)
    const numberOfAssociations = useSelector(state => state.session.associations?.length)
    const organisationType = useSelector(state => state.session.organisationType)
    const session = useSelector(state => state.session)
    const onboardingWizardStatus = useOnboardingWizardStatus(depositor)

    const canAcceptPartner = session.associations
        .find((a) => a.organisation?.id === depositor?.id)
        ?.permissions.includes('DEPOSITOR__PARTNER__ACCEPT')
    const hasPendingPartnerRequests = depositor?.partnerRelations?.some(pr => !pr.accepted)

    // When new wizard is ready to go out of beta, we only need the wizardActive condition
    const shouldShowWizard = () => {
        if (canAcceptPartner && hasPendingPartnerRequests) {
            return false
        }
        if (onboardingWizardStatus?.wizardActive) {
            return true
        }
        return loggedIn && hasSingleDepositor && !hasMadeDeposits && !hasMadeOrders && depositor?.depositorSupportCategory !== "MANAGED";
    }

    // TODO: Remove this condition when the wizard is ready to go out of beta. Then everybody get the wizard and the self service wizard is removed.
    const isPartnerAccount = useSelector(state => state.session.association.roles.includes("DEPOSITOR_PARTNER_ACCOUNTANT"))

    if (organisationType !== 'DEPOSITOR' && numberOfAssociations > 0) {
        navigate('/marketplace')
        return null
    }

    const onboardingWizardBasePath = '/organizations/onboarding/' + depositor?.id
    const getWizardPath = (path: string) => {
        return depositor?.depositorSupportCategory === "UNKNOWN" ? onboardingWizardBasePath : onboardingWizardBasePath + path
    }

    // A new customer which is not managed that has not completed any orders or have multiple organizations
    // If customer has fund access, we need to give them full access to the organization menu to be able to sign the fund agreement.
    if (shouldShowWizard()) {
        return (
            <Routes>
                <Route path="onboarding" element={<Navigate to={onboardingWizardStatus.currentSlug} />} />
                <Route path="onboarding/:id" element={isPartnerAccount ? <OrganizationWizard/> : <SelfServiceWizard/>} />
                <Route path="onboarding/:id/:step" element={isPartnerAccount ? <OrganizationWizard/> : <SelfServiceWizard/>} />
                <Route path=":organisationId/users/authorization/:userId/:showForm" element={<Authorization/>} />
                <Route path=":organisationId/users/authorization/:userId" element={<Authorization/>} />
                <Route path=":organisationId/users/authorizationforinvite/:inviteId/:showForm" element={<Authorization/>} />
                <Route path=":organisationId/users/authorizationforinvite/:inviteId" element={<Authorization/>} />
                <Route path=":organisationId/users/authorizationforinvite" element={<Authorization/>} />
                <Route path=":id/customerdeclaration" element={<CustomerDeclaration regulatoryRegion={depositor?.regulatoryRegion}/>} />
                <Route path=":id/customerdeclaration/:fieldId" element={<CustomerDeclaration regulatoryRegion={depositor?.regulatoryRegion}/>} />
                <Route path=":id/users/authorization" element={<Navigate to={{ pathname: getWizardPath('/powerOfAttorney') }} />} />
                <Route path=":id/users/power-of-attorney" element={<Navigate to={{ pathname: getWizardPath('/powerOfAttorney') }} />} />
                <Route path=":id/users/board" element={<Navigate to={{ pathname: getWizardPath('/board') }} />} />
                <Route path=":id/accounts" element={<Navigate to={{ pathname: getWizardPath('/accounts') }} />} />
                <Route path=":id/customerdecl" element={<Navigate to={{ pathname: getWizardPath('/customerDeclaration') }} />} />
                <Route path=":id/terms" element={<Navigate to={{ pathname: getWizardPath('/terms') }} />} />
                <Route path={'' + depositor?.id + '/users/profile/:personId'} element={
                    <>
                        <PageHeader backToLink={onboardingWizardBasePath} title={t('pages-organizations.profileInformation')}/>
                        <PageLayout>
                            <UserProfile depositorId={depositor?.id} basePath={onboardingWizardBasePath}/>
                        </PageLayout>
                    </>
                } />
                <Route path="*" element={<Navigate to={{ pathname: onboardingWizardBasePath }} />} />
            </Routes>
        )
    }

    return (
        <Routes>
            <Route
                path=""
                element={
                    <Navigate to={{
                        pathname: depositor ? `/organizations/${depositor?.id}/users/all` : '/marketplace',
                        search: depositor ? "" : "registerCompany"
                    }} />
                }
            />
            <Route path="onboarding/*" element={<Navigate to={"/organizations/" + depositor?.id + "/"} />} />
            <Route path=":id/customerdeclaration/owner/:ownerKey" element={<CustomerDeclaration regulatoryRegion={depositor?.regulatoryRegion}/>} />
            <Route path=":id/customerdeclaration" element={<CustomerDeclaration regulatoryRegion={depositor?.regulatoryRegion}/>} />
            <Route path=":id/customerdeclaration/:fieldId" element={<CustomerDeclaration regulatoryRegion={depositor?.regulatoryRegion}/>} />
            <Route path=":organisationId/users/authorization/:userId/:showForm" element={<Authorization/>} />
            <Route path=":organisationId/users/authorization/:userId" element={<Authorization/>} />
            <Route path=":organisationId/users/authorization" element={<Authorization/>} />
            <Route path=":organisationId/users/authorizationforinvite/:inviteId/:showForm" element={<Authorization/>} />
            <Route path=":organisationId/users/authorizationforinvite/:inviteId" element={<Authorization/>} />
            <Route path=":organisationId/users/authorizationforinvite" element={<Authorization/>} />
            <Route path=":id/shortname/:showForm" element={<OrganisationDetail/>} />
            <Route path=":id/shortname" element={<OrganisationDetail/>} />
            <Route path=":id/*" element={<OrganisationDetail/>} />
        </Routes>
    )
}
