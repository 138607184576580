import {InterestOutput, LoadingSpinner, PageHeader} from '#app/components'
import {useCurrencyOutput} from '#app/components/CurrencyOutput/useCurrencyOutput'
import {
    getDepositInterestForDepositorWithSsb,
    getEarningsForDepositor,
    getLastOrders
} from '#app/services/thunks/statistics'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import PageLayout from '#components/PageLayout/PageLayout'
import {isLoggedIn} from '#state/selectors'
import {StaggData} from '#state/stagg'
import {useSelector} from '#state/useSelector'
import classNames from 'classnames'
import html2canvas from 'html2canvas'
import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {ChartComp} from '../../BankDashboard/components/ChartComp'
import styles from './AnalyticsDashboard.module.scss'
import {LogoDataUri} from '../FixrateLogo'

interface LastOrdersData {
    business: string,
    created: string,
    interest: number,
    nibor: number,
    productId: string,
    totalAssets: number,
    volume: number,
}

export interface EarningsData {
    totalEarnings: number,
    earnings: number
}

type RangePeriod = "ALL" | "YTD" | "1Y" | "6M" | "3M" | "1M"

type LoadingState = {
    earnings?: boolean,
    portfolioGraph?: boolean,
    earningsError?: boolean,
    portfolioGraphError?: boolean
}

const AnalyticsDashboard = () => {
    const {t} = useTranslation()
    const loggedIn = useSelector(isLoggedIn)
    const dispatch = useDispatch<(arg0: unknown) => Promise<unknown>>()
    const Currency = useCurrencyOutput()

    const depositor = useCurrentDepositor()
    const dashboard = useSelector(state => state.dashboard)
    const deposits = useSelector(state => state.deposits)

    const [ssbData, setSsbData] = useState<StaggData>([])
    const [orders, setOrders] = useState<LastOrdersData[]>([])
    const [wantedVolume, setWantedVolume] = useState(0)
    const [earnings, setEarnings] = useState(0)
    const [earningsInterval, setEarningsInterval] = useState<RangePeriod>("ALL")

    const selectedDepositorHasDeposits: boolean = deposits.length > 0;
    const depositDates = deposits.map(deposit => deposit.startDate ?? deposit.created)
    const firstDeposit = depositDates.length > 0 ? depositDates.reduce(function (pre, cur) {
        return Date.parse(pre) > Date.parse(cur) ? cur : pre;
    }) : null;
    const firstDepositDate: string = depositDates.length > 0 && new Date(firstDeposit).toISOString().split("T")[0]

    const [earningsSinceStart, setEarningsSinceStart] = useState(0);
    const [interest, setInterest] = useState(null)
    const [loadingState, setLoadingState] = useState<LoadingState>({})
    const loading = loadingState.earnings || loadingState.portfolioGraph
    const error = loadingState.earningsError || loadingState.portfolioGraphError

    const selectedDepositorBalance = deposits?.reduce(
        (sum, deposit) => sum + deposit.calculatedTotalBalance
    , 0)

    function saveAs(uri, filename) {
        const link = document.createElement('a');
        if (typeof link.download === 'string') {
          link.href = uri;
          link.download = filename;

          //Firefox requires the link to be in the body
          document.body.appendChild(link);

          //simulate click
          link.click();

          //remove the link when done
          document.body.removeChild(link);
        } else {
          window.location.href = uri;
        }
    }

    const downloadDashboardImage = () => {
        const containerWidth = document.querySelector("#dashboard-container").clientWidth > 1200 ? 1200 : document.querySelector("#dashboard-container").clientWidth;
        html2canvas(
            document.querySelector("#dashboard-container"),
            {
                width: containerWidth,
                ignoreElements: function(element) {
                    if (element.getAttribute("id") === "top-bar" || element.getAttribute("data-ignore")) {
                        return true
                    }
                }
            }
        ).then(canvas => {
            saveAs(canvas.toDataURL(), 'fixrate-key-data.jpg');
        });
    }

    const legend = (tags) => {
        if (tags.name === "SSB") {
            return t('pages-analytics.meanBusinessAccount')
        }

        if (tags.name === "Fixrate") {
            return t('pages-analytics.meanBusinessFixrate')
        }

        return tags.name;
    }

    const earningsIntervalText = (val: RangePeriod) => {
        if (val === 'ALL') {
            return t('pages-analytics.sinceFirstDeposit')
        }
        if (val === 'YTD') {
            return t('pages-analytics.yearToDate')
        }
        if (val === '1Y') {
            return t('pages-analytics.last12Months')
        }
        if (val === '6M') {
            return t('pages-analytics.last6Months')
        }

        return '';
    }

    const getPortfolioGraphData = useCallback((rangeVal: RangePeriod) => {
        let range: RangePeriod | string
        if (firstDepositDate && rangeVal === 'ALL') {
            range = "?startDate=" + firstDepositDate + "&endDate=" + new Date().toISOString().split("T")[0]
        } else {
            range = rangeVal
        }
        setLoadingState(current => ({...current, portfolioGraph: true, portfolioGraphError: false}))
        dispatch(getDepositInterestForDepositorWithSsb(depositor?.id, range))
            .then((chartData: StaggData) => {
                chartData[0].series = selectedDepositorHasDeposits ? chartData[0].series.filter(item => item.tags.name !== 'Fixrate') : chartData[0].series;
                if (selectedDepositorHasDeposits) {
                    const customerInterestData = chartData[0].series.find(item => item.tags.name === depositor.name)
                    setInterest(customerInterestData.values[customerInterestData.values.length - 1][1])
                }
                setSsbData(chartData)
                setLoadingState(current => ({...current, portfolioGraph: false}))
            })
            .catch((err) => {
                setLoadingState(current => ({...current, portfolioGraph: false, portfolioGraphError: true}))
                console.error(err)
            })
    }, [depositor, firstDepositDate, dispatch, selectedDepositorHasDeposits])

    const getEarningsData = useCallback((range: RangePeriod) => {
        setLoadingState(current => ({...current, earnings: true, earningsError: false}))
        dispatch(getEarningsForDepositor(depositor?.id, range))
            .then((data: EarningsData) => {
                if (range === 'ALL') {
                    setEarningsSinceStart(data.earnings)
                }
                setEarnings(data.earnings)
                setLoadingState(current => ({...current, earnings: false}))
            })
            .catch(err => {
                setLoadingState(current => ({...current, earnings: false, earningsError: true}))
                console.error(err)
            })
    }, [depositor?.id, dispatch])

    // Gets the initial data
    useEffect(() => {
        if (loggedIn) {
            getPortfolioGraphData('ALL')
            getEarningsData('ALL')

            dispatch(getLastOrders())
                .then((data: LastOrdersData[]) => setOrders(data))
                .catch(() => console.error)

            fetch('/api/pubstat/remaining')
                .then(resp => resp.json())
                .then(body => setWantedVolume(body))
        }
    }, [depositor, dispatch, getEarningsData, getPortfolioGraphData, loggedIn])

    const handleChangeEarningsInterval = (range: RangePeriod) => {
        setEarningsInterval(range)
        getEarningsData(range)
        getPortfolioGraphData(range)
    }

    return (
        <>
            <PageHeader icon="ri-bar-chart-line" title={t('menu.analytics')}/>
            <PageLayout className={styles.dashboardLayout}>
            {loggedIn && (
                <div id="dashboard-container" className={styles.dashboard}>
                    <div id="top-bar" className={styles.topBar}>
                        <div className={styles.marketplaceData}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.9999 22H1.99994V27.5H10.9999V22Z" fill="#95E1BF"/>
                                <path d="M7.38121 19.5H1.99994V14.2075H13.1193L20.0303 10.5H27.4999V15.7925H17.1032C17.1032 15.7925 15.6148 15.7802 14.1031 16.6243L10.4156 18.7417C10.4156 18.7417 9.17226 19.5 7.38121 19.5Z" fill="#95E1BF"/>
                                <path d="M7.38121 12H1.99994V6.70753H13.1193L20.0303 3H27.4999V8.29247H17.1032C17.1032 8.29247 15.6662 8.24256 14.1031 9.12434L10.4156 11.2417C10.4156 11.2417 9.17226 12 7.38121 12Z" fill="#95E1BF"/>
                            </svg>
                            <ul className={styles.keyFigures}>
                                <li>
                                    <h4>{t('pages-analytics.requestedVolume')}</h4>
                                    <p>{Currency(wantedVolume, { decimals: 0 })} mill.</p>
                                </li>
                                <li>
                                    <h4>{t('pages-analytics.interestOnLastOrder')}</h4>
                                    <p>{orders.length > 0 && InterestOutput.format(orders[0].interest)}</p>
                                </li>
                                <li>
                                    <h4>{t('common.interestRateBenchmark.' + dashboard.currentInterestRateBenchmark)}</h4>
                                    <p>{InterestOutput.format(dashboard.currentBenchmarkInterestRate)}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {loading && (
                        <div className={styles.loaderWrapper}>
                            <LoadingSpinner className={styles.dashboardLoader}/>
                        </div>
                    )}
                    {error && (
                        <div className={styles.loaderWrapper}>
                            <p className={styles.errorMessage}>
                                <i className="ri-error-warning-line"/>
                                <span>{t('pages-analytics.errorMessage')}</span>
                            </p>
                        </div>
                    )}
                    {!loading && !error && (
                    <>
                        <div className={styles.overlayContainer}>
                            <div data-ignore="true" className={styles.overlay}></div>
                            <img className={classNames(styles.hiddenLogo, styles.visible)} src={LogoDataUri} alt=""/>
                        </div>
                        <ul className={styles.portfolioMetrics}>
                            { earningsSinceStart > 0 && (
                            <li>
                                <div className={styles.addedValueContainer}>
                                    <h4>{t('pages-analytics.addedValue')}</h4>
                                    <div className={styles.earningsIntervalContainer}>
                                        <select data-ignore="true" value={earningsInterval} className={classNames(styles.addedValueSelector)} onChange={(e) => handleChangeEarningsInterval(e.target.value as RangePeriod)}>
                                            <option value="ALL">{t('pages-analytics.sinceFirstDeposit')}</option>
                                            <option value="YTD">{t('pages-analytics.yearToDate')}</option>
                                            <option value="1Y">{t('pages-analytics.last12Months')}</option>
                                            <option value="6M">{t('pages-analytics.last6Months')}</option>
                                        </select>
                                        <p>{earningsIntervalText(earningsInterval)}</p>
                                    </div>
                                </div>
                                <p>{Currency(earnings, {decimals: 0})}</p>
                                <p className={styles.description}>{t('pages-analytics.addedValueDescription')}</p>
                            </li>
                            )}
                            <li>
                                <h4>{t('pages-analytics.deposits')}</h4>
                                <p>{Currency(selectedDepositorBalance, {decimals: 0})}</p>
                            </li>
                            {interest !== null && (
                            <li>
                                <h4>{t('pages-analytics.interestOnDeposits')}</h4>
                                {selectedDepositorHasDeposits ?
                                    <p>{InterestOutput.format(interest)}</p> :
                                    <p className={styles.empty}>{t('pages-analytics.noDeposits')}</p>
                                }
                            </li>)}
                        </ul>
                        <div className={styles.chartContainer}>
                            { ssbData.length > 0 && <ChartComp
                                title={{text: t('pages-analytics.interestRate')}}
                                dashedLine={"SSB"}
                                gradient={true}
                                width={800}
                                height={400}
                                smallSize={false}
                                labels={legend}
                                error={false}
                                data={ssbData}
                                dataType="time"
                                tickValue={"%"}
                                type="line"/> }
                        </div>
                        <p className={styles.graphExplanation}>{t('pages-analytics.graphExplanation')}</p>
                        <button data-ignore="true" className={styles.downloadImage} onClick={() => downloadDashboardImage()}>
                            <i className="ri-image-2-line"/>
                            <span>{t('pages-analytics.saveAsImage')}</span>
                        </button>
                    </>
                    )}
                </div>
            )}
            </PageLayout>
        </>
    )
}

export default AnalyticsDashboard

