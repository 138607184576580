import MandateChanger from "#app/blocks/MandateChanger/MandateChanger"
import { Paper } from "#app/components"
import useCurrentDepositor from "#app/services/useCurrentDepositor"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { Fragment } from "react/jsx-runtime"
import { PlacementProfile } from "./PlacementProfile"

const Mandate = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const depositor = useCurrentDepositor()

    const menuOptions = [
        {
            label: t('pages-organizations.investmentAlternatives'),
            value: `/organizations/${depositor?.id}/placement-profile/investment-alternatives`,
            dataCy: 'mandateLink',
            visible: true,
        },
        {
            label: t('pages-organizations.bankSelection'),
            value: `/organizations/${depositor?.id}/placement-profile/bank-selection`,
            dataCy: 'bankSelectionLink',
            visible: true,
        },
        {
            label: t('pages-organizations.terms'),
            value: `/organizations/${depositor?.id}/placement-profile/requirements`,
            dataCy: 'placementRequirementsLink',
            visible: true,
        },
        {
            label: t('pages-organizations.directOfferFromBank'),
            value: `/organizations/${depositor?.id}/placement-profile/direct-offer`,
            dataCy: 'directOfferLink',
            visible: true,
        },
    ]
    return (
        <Fragment>
            <ToggleButtonGroup value={location.pathname} color="primary" sx={{mb: 4}}>
                { menuOptions.filter(option => option.visible).map(option => (
                    <ToggleButton key={option.value} value={option.value} onClick={() => navigate(option.value)}>
                        {option.label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            <Routes>
                <Route path="" element={<Navigate to={'investment-alternatives'} replace/>}/>
                <Route path={'investment-alternatives'} element={
                    <Paper title={t('pages-organizations.selectPlacementAlternatives')}>
                        <MandateChanger />
                    </Paper>
                } />
                <Route path={'bank-selection'} element={<PlacementProfile inline={false} mandate/>} />
                <Route path={'requirements'} element={<PlacementProfile inline={false} placementDemands />} />
                <Route path={'direct-offer'} element={<PlacementProfile inline={false} directOffer />} />
            </Routes>
        </Fragment>
    )
}

export { Mandate }
