import {useEndpoint} from '#command';
import {sendExceptionToSentry} from '#services/sentry';
import {FundDto, Portfolio} from '@fixrate/fixrate-query';
import useShoppingCart from "#services/useShoppingCart";
import {PortfolioSelection} from "#components/PageHeader/components/PortfolioSelection/PortfolioSelection";

export const DepositorPortfolioSelection = () => {
    const {setPortfolioDepositorSession} = useEndpoint()
    const fundShoppingCart = useShoppingCart<FundDto>('FUNDS')
    const switchPortfolioCallback = (selectedPortfolio: Portfolio) => {
        fundShoppingCart.clearCart()
        setPortfolioDepositorSession(selectedPortfolio.id).catch((err) => {
            sendExceptionToSentry(err, {currency: selectedPortfolio.currency, portfolio: selectedPortfolio})
        })
    }

    return (<PortfolioSelection onChange={switchPortfolioCallback}/>)

}
