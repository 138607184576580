import {Fragment} from 'react'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import en from 'date-fns/locale/en-US'
import nb from 'date-fns/locale/nb'
import {sv} from 'date-fns/locale'
import {i18n} from '#components/i18n'

const locales = {
    'en': en,
    'nb': nb,
    'sv': sv
}

const testDate = new Date(2000, 0, 1)

function doFormat(date: Date | string, pattern: string) {
    const testMode = document.cookie.includes('_fixrate_stop_time')
    const isString = (typeof date === 'string' || date instanceof String)
    const dateToFormat = ((isString && new Date(date)) || date) as Date
    return dateToFormat && isValid(dateToFormat) ? format(dateToFormat, pattern, {locale: locales[i18n.language]}) : ''
}

const DateOutput = {

    format: (date: Date | string, pattern: string) => doFormat(date, pattern),

    timeFormat: 'HH:mm',
    formatTime: (date: Date | string) => doFormat(date, DateOutput.timeFormat),
    Time: ({date} : {date: Date | string}) => <Fragment>{DateOutput.formatTime(date)}</Fragment>,

    dateTimeFormat: 'dd.MM.yyyy HH:mm',
    formatDateTime: (date: Date | string) => doFormat(date, DateOutput.dateTimeFormat),
    DateTime: ({date} : {date: Date | string}) => <Fragment>{DateOutput.formatDateTime(date)}</Fragment>,

    dateFormat: 'dd.MM.yyyy',
    formatDate: (date: Date | string) => doFormat(date, DateOutput.dateFormat),
    Date: ({date} : {date: Date | string}) => <Fragment>{DateOutput.formatDate(date)}</Fragment>,

    formatVerboseMonth: (date: Date | string) => doFormat(date, 'd. MMM yyyy'),
    VerboseMonth: ({date} : {date: Date | string}) => <Fragment>{DateOutput.formatVerboseMonth(date)}</Fragment>,

    formatMonthYear: (date: Date | string) => doFormat(date, 'MMMM, yyyy'),
    MonthYear: ({date} : {date: Date | string}) => <Fragment>{DateOutput.formatMonthYear(date)}</Fragment>,

    formatDayMonthTime: (date: Date | string) => doFormat(date, 'dd. MMMM HH:mm'),
    DayMonthTime: ({date} : {date: Date | string}) => <Fragment>{DateOutput.formatDayMonthTime(date)}</Fragment>,

    formatMonthName: (date: Date | string) => doFormat(date, 'MMMM'),

}

export default DateOutput
